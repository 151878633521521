<div class="exception-body notfound">
    <div class="exception-panel">
        <h1>404</h1>
        <h3>não encontrada</h3>
        <p>A página que procura não existe.</p>
        <button type="button" pButton label="Home" [routerLink]="['/']" (click)="marcao()"></button>
    </div>
    <div class="exception-footer">
        <img [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="exception-logo"/>
        <img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="exception-appname"/>
    </div>
</div>
