<p-toast></p-toast>
<div class="login-body">
    <div class="login-image">
        <img [src]="'assets/layout/images/pages/login-ondark.png'" alt="atlantis">
    </div>
    <div class="login-panel p-fluid">
        <div class="p-d-flex p-flex-column">
            <div class="p-d-flex p-ai-center p-mb-6 logo-container">
                <img [src]="'assets/layout/images/logo-dark.png'" class="login-logo" />
                <!--<img [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'"
                     class="login-appname"/> -->
                <h2 style="margin-left: 5px;">Parceiro Poty</h2>
            </div>
            <div class="form-container">
                <span class="p-input-icon-left">
                    <i class="pi pi-envelope"></i>
                    <input [(ngModel)]="usuario.usuario" type="text" autocomplete="off" pInputText placeholder="Email"
                        (keydown)="onInputKeyDown($event)">
                </span>
                <span class="p-input-icon-left">
                    <i class="pi pi-key"></i>
                    <input [(ngModel)]="usuario.senha" type="password" autocomplete="off" pInputText placeholder="Senha"
                        (keydown)="onInputKeyDown($event)">
                </span>
                <!-- <a href="#" class="p-d-flex">Esqueceu sua senha?</a> -->
            </div>
            <div class="button-container">
                <button type="button" pButton label="Login" (click)="onClickLogin()" class="p-button-success"
                    [disabled]="!usuario.usuario || !usuario.senha"></button>
                <p-progressBar mode="indeterminate" [style]="{'height': '6px', 'background-color':'#2e8c47'}"
                    *ngIf="loading"></p-progressBar>
                <!-- <span>Não tem uma conta?<a>Cadastre-se aqui</a></span> -->
            </div>
        </div>

        <div class="login-footer p-d-flex p-ai-center">
            <div class="p-d-flex p-ai-center login-footer-logo-container">
                <label>Poty</label>
                <!-- <img src="assets/layout/images/logo-gray.png" class="login-footer-logo"/>
                <img src="assets/layout/images/appname-gray.png" class="login-footer-appname"/> -->
            </div>
            <span>Versão : {{version}}</span>
        </div>
    </div>
</div>