import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { LoginComponent } from './pages/app.login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './helps/auth.guards';
import { UsuarioComponent } from './pages/usuario/usuario.component';
import { CategoriaComponent } from './pages/categoria/categoria.component';
import { ProdutoComponent } from './pages/produto/produto.component';
import { ParceiroComponent } from './pages/parceiro/parceiro.component';
import { PromocaoComponent } from './pages/promocao/promocao.component';
import { NotificacaoComponent } from './pages/notificacao/notificacao.component';
import { CupomComponent } from './pages/cupom/cupom.component';
import { ConfigComponent } from './pages/configuracao/configuracao.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: 'login', component: LoginComponent },
        {
          path: '',
          component: AppMainComponent,
          canActivate: [AuthGuard],
          children: [
            {
              path: '',
              component: DashboardComponent,
              canActivate: [AuthGuard],
            },
            { path: 'cadastro/usuario', component: UsuarioComponent },
            { path: 'cadastro/categoria', component: CategoriaComponent },
            { path: 'cadastro/produto', component: ProdutoComponent },
            { path: 'cadastro/parceiro', component: ParceiroComponent },
            { path: 'cadastro/promocao', component: PromocaoComponent },
            { path: 'cadastro/cupom', component: CupomComponent },
            { path: 'app/configuracao', component: ConfigComponent },
            { path: 'cadastro/notificacao', component: NotificacaoComponent },
          ],
        },
        { path: 'notfound', component: AppNotfoundComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      { scrollPositionRestoration: 'enabled' }
    ),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
