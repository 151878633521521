import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ParceiroConfigModel } from './configuracao.model';

@Injectable()
export class ParceiroConfigService {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  private basePath = '/configuracao';

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}
  /* Cria um novo registro na coleção especificada usando o método add */

  updateParceiroConfiguracao(recordID: string, record: ParceiroConfigModel) {
    console.log({ recordID, record, teste: 'Config/' + recordID });

    this.firestore.doc('Config/' + recordID).update(record);
  }
  //LISTAS
  readConfiguracao() {
    return this.firestore
      .collection<ParceiroConfigModel>('Config')
      .snapshotChanges();
  }
}
