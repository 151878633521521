<p-toast></p-toast>
<p-card>
    <div class="card">
        <br>
        <div class="p-d-flex p-ai-center p-jc-between">
            <button pButton pRipple label="Novo" icon="pi pi-plus" class="p-button-sm p-button-success p-mr-2"
                (click)="openNew()"></button>
        </div>
        <br>

        <!-- **************************** LISTA **************************** -->
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>
        <p-table #dt [value]="datas" [rows]="50" [paginator]="true" [globalFilterFields]="['Descricao','CodigoCupom']"
            [(selection)]="selectedDatas" [rowHover]="true" dataKey="CupomID"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registro(s)"
            [showCurrentPageReport]="true">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h5 class="p-m-0">Gerenciamento de Cupom</h5>
                </div>
                <br>
                <div class="p-fluid p-grid p-formgrid">
                    <div class="p-field p-col-12 p-md-4">
                        <label for="icon">Descrição</label>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                placeholder="Pesquisar..." />
                        </span>
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col style="width:400px">
                    <col>
                    <col style="width:130px">
                    <col style="width:130px">
                    <col style="width:100px">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="CodigoCupom">Código<p-sortIcon field="CodigoCupom"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Descricao">Descrição<p-sortIcon field="CodigoCupom"></p-sortIcon></th>
                    <th pSortableColumn="DataInicio">Data Início<p-sortIcon field="DataInicio"></p-sortIcon>
                    </th>
                    <th pSortableColumn="DataFinal">Data Final<p-sortIcon field="DataFinal"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td>{{data.CodigoCupom}}</td>
                    <td>{{data.Descricao}}</td>
                    <td>{{data.DataInicio ? (data.DataInicio.toDate() | date: 'dd/MM/yyyy') : data.DataInicio | date:
                        'dd/MM/yyyy'}}</td>
                    <td>{{data.DataFinal? (data.DataFinal.toDate() | date: 'dd/MM/yyyy') : data.DataFinal | date:
                        'dd/MM/yyyy'}}</td>

                    <td style="text-align: right;">
                        <button pButton pRipple icon="pi pi-pencil" pTooltip="Editar"
                            class="p-button-sm p-button-rounded p-button-warning p-mr-2"
                            (click)="editData(data)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            pTooltip="Deletar" (click)="deleteData(data)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    No total, existem {{datas ? datas.length : 0 }} registro(s).
                </div>
            </ng-template>
        </p-table>
    </div>
</p-card>

<!-- **************************** CADASTRO ****************************-->
<p-dialog [(visible)]="dataDialog" [style]="{'width':'95%','max-height':'100%', 'max-width':'1320px' }"
    header="Detalhe Cupom" [modal]="true" styleClass="p-fluid" [closeOnEscape]="false" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
        <p-card [style]="{'width': '100%', 'margin-top': '20px', 'margin-bottom': '20px'}">
            <div [style]="{'width': '100%', 'max-width':'600px'}">
                <div class="p-field">
                    <div>
                        <label>ID</label>
                        <input type="text" pInputText [(ngModel)]="data.CupomID" required [disabled]="true" />
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label>Tipo do cupom</label>
                            <p-dropdown [options]="listaTipo" [(ngModel)]="data.Tipo" placeholder="Tipo"></p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-field">
                            <label>Desconto</label>
                            <p-inputNumber type="number" [(ngModel)]="data.Desconto" mode="decimal"
                                [minFractionDigits]="2" [maxFractionDigits]="5">
                            </p-inputNumber>
                        </div>
                        <div class="p-field">
                            <label>Tipo do Desconto</label>
                            <p-dropdown [options]="listaTipoDesconto" [(ngModel)]="data.TipoDesconto"
                                placeholder="Tipo de Desconto"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label>Tipo de Limite</label>
                            <p-dropdown [options]="listaTipoLimite" [(ngModel)]="data.TipoLimite"
                                placeholder="Tipo de Limite"></p-dropdown>
                        </div>
                    </div>
                    <div class="p-col-6" *ngIf="data.TipoLimite !== 'Nenhum'">
                        <div class="p-field">
                            <label>Quantidade do limite</label>
                            <p-inputNumber type="number" [(ngModel)]="data.QuantidadeLimite" mode="decimal"
                                [minFractionDigits]="2" [maxFractionDigits]="5">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label>Limite de Cupom</label>
                            <p-inputNumber type="number" [(ngModel)]="data.LimiteCupom" mode="decimal"
                                [minFractionDigits]="0" [maxFractionDigits]="0" [min]="0">
                            </p-inputNumber>
                        </div>
                    </div>
                </div>
                <div class="p-field">
                    <div>
                        <label>Código Promocional</label>
                        <input type="text" pInputText [(ngModel)]="data.CodigoCupom" required autofocus
                            oninput="this.value = this.value.replace(/\s+/g, '');">
                    </div>
                </div>
                <div class="p-field">
                    <div>
                        <label>Nome do Cupom</label>
                        <input type="text" pInputText [(ngModel)]="data.NomeCupom" required />
                    </div>
                </div>
                <div class="p-field">
                    <div>
                        <label>Descrição</label>
                        <input type="text" pInputText [(ngModel)]="data.Descricao" required />
                    </div>
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <div class="p-field">
                            <label>Data Início</label>
                            <p-calendar [(ngModel)]="data.DataInicio" dateFormat="dd/mm/yy"
                                [appendTo]="'body'"></p-calendar>
                        </div>
                    </div>
                    <div class="p-col-6">
                        <div class="p-field">
                            <label>Data Fim</label>
                            <p-calendar [(ngModel)]="data.DataFinal" dateFormat="dd/mm/yy" [appendTo]="'body'">
                            </p-calendar>
                        </div>
                    </div>
                </div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-2">
                        <label>Status</label><br />
                        <p-checkbox [(ngModel)]="data.Status" binary="true" inputId="binary">
                        </p-checkbox>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-2">
                        <label>APP</label><br />
                        <p-checkbox [(ngModel)]="data.APP" binary="true" inputId="binary">
                        </p-checkbox>
                    </div>
                    <!-- <div class="p-col-12 p-md-12 p-lg-2">
                        <label>WEB</label><br />
                        <p-checkbox [(ngModel)]="data.WEB" binary="true" inputId="binary">
                        </p-checkbox>
                    </div> -->
                    <div class="p-col-12 p-md-12 p-lg-3">
                        <label>Primeira Compra</label><br />
                        <p-checkbox [(ngModel)]="data.PrimeiraCompra" binary="true" inputId="binary">
                        </p-checkbox>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-3">
                        <label>Cupom Visível</label><br />
                        <p-checkbox [(ngModel)]="data.CupomVisivel" binary="true" inputId="binary">
                        </p-checkbox>
                    </div>
                </div>

            </div>
            <br>

            <p-accordion [multiple]="true" [style]="{'width':'100%'}" *ngIf="data.Tipo =='Produto'">
                <!-- PRODUTOS -->
                <p-accordionTab header="Produtos">
                    <div class="card" style="background-color: rgb(247, 247, 247);width: 100%">
                        <p-pickList [source]="Produtos" [target]="ListaCupomProdutos"
                            (onMoveToTarget)="onMoveToTargetProdutos($event)"
                            (onMoveAllToTarget)="onMoveAllToTargetProdutos($event)"
                            (onMoveToSource)="onMoveToSourceProdutos($event)"
                            (onMoveAllToSource)="onMoveAllToSourceProdutos($event)" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}"
                            filterBy="ProdutoID,Descricao" filterMatchMode="contains"
                            sourceFilterPlaceholder="Pesquisar" targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">{{product.ProdutoID}} - {{product.Descricao}}</h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

            </p-accordion>
        </p-card>
    </ng-template>

    <ng-template pTemplate="footer">
        <br />
        <button pButton pRipple type="button" label="GRAVAR"
            [disabled]="!data.Descricao || !data.Desconto|| !data.CodigoCupom || !data.DataInicio || !data.DataFinal || data.Tipo==='Produto'? !ListaCupomProdutos.length:!data.Tipo"
            style="width: 100px;float: left;" class="p-button-raised p-button-success" (click)="saveData()">
        </button>


        <button pButton pRipple type="button" label="RETORNAR" style="width: 100px;"
            class="p-button-raised p-button-danger" (click)="hideDialog()">
        </button>

        <p-progressBar mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"
            *ngIf="loadingSave"></p-progressBar>
    </ng-template>

</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>