export const environment = {
  production: false,
  apiUrl: 'your-api-url.com',
  version: '1.0.1',
  firebase: {
    apiKey: 'AIzaSyASxqJWK4pjMsojWB3XyrLmWJJXDPGve1c',
    authDomain: 'parceiro-poty.firebaseapp.com',
    databaseURL: 'https://parceiro-poty.firebaseio.com',
    projectId: 'parceiro-poty',
    storageBucket: 'parceiro-poty.appspot.com',
    messagingSenderId: '714160100667',
    appId: '1:714160100667:web:068fcaf2938840506fd31e',
    measurementId: 'G-BWEH3QZ99S',
  },
};
