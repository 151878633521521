import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TipoModel } from 'src/app/models/tipomodel';
import { FirebaseService } from '../app.login.firebase.service';
import { CategoriaModel } from '../categoria/categoria.model';
import { CategoriaService } from '../categoria/categoria.service';
import { SubCategoriaModel } from '../categoria/subcategoria.model';
import { ProdutoComboModel } from './produto.combo.model';
import { ProdutoModel } from './produto.model';
import { ProdutoService } from './produto.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.scss'],
})
export class ProdutoComponent implements OnInit {
  show = false;
  teste: string = '';

  ativosInativos = '';
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  Produtos: ProdutoModel[] = JSON.parse(localStorage.getItem('Produtos'));

  dataDialog: boolean;

  listaSN: any;
  listaFT: any;
  lista: ProdutoModel[];
  loading: boolean = true;
  data: ProdutoModel;
  selectedDatas: ProdutoModel[];
  submitted: boolean;

  //Tipos
  tipoSelected: boolean = false;
  tipos: TipoModel[];

  categorias: CategoriaModel[];
  selectedCategoria: CategoriaModel;
  subcategorias: SubCategoriaModel[];

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  produtoCombo: ProdutoComboModel;
  listaProdutoCombo: ProdutoComboModel[];

  private basePath = 'produtos/';

  profileUrl: any;

  mudarAtivosInativos(args: string) {
    this.ativosInativos = args;
  }

  constructor(
    private service: ProdutoService,
    private categoriaService: CategoriaService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    try {
      this.listaSN = [
        { nome: 'S', valor: 'Ativo' },
        { nome: 'N', valor: 'Inativo' },
      ];
      this.listaFT = [
        { id: false, valor: 'Não' },
        { id: true, valor: 'Sim' },
      ];
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.service.readProduto().subscribe((data) => {
            this.Produtos = data.map((e) => {
              const dataValues = e.payload.doc.data();

              let findErrors = {
                produtosComboVazio: false,
                produtosComboInativo: false,
                produtosComboInexistente: false,
              };

              if (
                dataValues.ProdutoID.includes('CB') &&
                dataValues.Ativo == 'S'
              ) {
                console.log({ dataValues });
                if (dataValues.ProdutosCombos.length == 0) {
                  findErrors.produtosComboVazio = true;
                }
                // const findProdutosComboInativo = dataValues.ProdutosCombos.filter((produto)=>{
                //   return produto.Ativo === 'N'
                // })
                const produtosSemID = dataValues.ProdutosCombos.filter(
                  (produto) => !produto.ProdutoID
                );
                console.log({ produtosSemID });
                if (produtosSemID.length) {
                }
              }

              return {
                id: e.payload.doc.id,
                isEdit: false,
                Ativo: dataValues['Ativo'],
                Categoria: dataValues['Categoria'],
                CategoriaID: dataValues['CategoriaID'],
                CodigoAlternativo: dataValues['CodigoAlternativo'],
                Descricao: dataValues['Descricao'],
                Preco: dataValues['Preco'],
                ProdutoID: dataValues['ProdutoID'],
                QuantidadeObjetivo: dataValues['QuantidadeObjetivo'],
                QuantidadeTroca: dataValues['QuantidadeTroca'],
                QuantidadeVenda: dataValues['QuantidadeVenda'],
                SubCategoria: dataValues['SubCategoria'],
                SubCategoriaID: dataValues['SubCategoriaID'],
                UnidadeObjetivo: dataValues['UnidadeObjetivo'],
                UnidadeTroca: dataValues['UnidadeTroca'],
                UnidadeVenda: dataValues['UnidadeVenda'],
                urlImage: dataValues['urlImage'],
                ProdutosCombos: dataValues['ProdutosCombos'],
              };
            });

            this.loading = false;
          });
        } else {
          this.Produtos = [];
        }

        //BUSCANDO CATEGORIA
        try {
          this.categoriaService.read_Categoria().subscribe(
            (data) => {
              this.categorias = data.map((e) => {
                const dataValues = e.payload.doc.data();
                return {
                  id: e.payload.doc.id,
                  CategoriaID: dataValues['CategoriaID'],
                  Descricao: dataValues['Descricao'],
                  SubCategorias: dataValues['SubCategorias'],
                  Ativo: dataValues['Ativo'],
                };
              });
            },
            (err) => console.error(err)
          );
        } catch (e) {
          alert(e);
        }
        //BUSCANDO CATEGORIA
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  ProdutosFiltred() {
    if (this.Produtos)
      return this.Produtos.filter((produto) => {
        return this.ativosInativos === ''
          ? true
          : produto.Ativo === this.ativosInativos;
      });
  }

  ngOnDestroy() {}

  async ngOnInit() {
    this.data = {};
    this.produtoCombo = {};
    this.listaProdutoCombo = [];
  }

  uploadedFiles: File = null;
  fb: any;

  uploadProduto(event) {
    this.uploadedFiles = <File>event.target.files[0];

    try {
      if (this.uploadedFiles != null) {
        const filePath = `${this.basePath}/${this.data.ProdutoID}.png`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, this.uploadedFiles);

        uploadTask
          .snapshotChanges()
          .pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe((downloadURL) => {
                this.fb = downloadURL;
                this.data.urlImage = downloadURL;

                if (this.data.ProdutoID != undefined) {
                  if (this.data.urlImage == undefined) {
                    this.data.urlImage = '';
                  }
                }

                this.messageService.add({
                  severity: 'success',
                  summary: 'Informação',
                  detail: 'Foto alterada com sucesso!',
                });
              });
            })
          )
          .subscribe();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro em alterar foto!',
      });
      alert(error);
    }
  }

  async getImage(codigo: string): Promise<string> {
    let resultado: Promise<string>;
    try {
      await this.storage
        .ref(this.basePath + codigo + '.png')
        .getDownloadURL()
        .toPromise()
        .then((response: any) => {
          resultado = response;
          return resultado;
        })
        .catch((error) => error);

      return resultado;
    } catch (error) {}
  }

  openNew() {
    this.data = {};
    this.data.Preco = 0;
    this.data.QuantidadeObjetivo = 0;
    this.data.QuantidadeTroca = 0;
    this.data.QuantidadeVenda = 0;

    this.produtoCombo = {};
    this.produtoCombo.Quantidade = 0;
    this.produtoCombo.ValorTotal = 0;
    this.produtoCombo.ValorUnitario = 0;

    this.listaProdutoCombo = [];
    this.submitted = false;
    this.dataDialog = true;
  }

  async editData(data: ProdutoModel) {
    data.Categoria = data.Categoria || 'Outros';
    data.SubCategoria = data.SubCategoria || 'Outros';
    data.CategoriaID = data.CategoriaID || 64;
    data.SubCategoriaID = data.SubCategoriaID || 68;

    this.listaProdutoCombo = [];
    data.isEdit = true;
    data.urlImage = await this.getImage(data.ProdutoID);

    this.subcategorias = this.categorias.find(
      (x) => x.CategoriaID == data.CategoriaID
    ).SubCategorias;

    this.data = { ...data };
    this.listaProdutoCombo = [...data.ProdutosCombos];

    //PRODUTOS
    if (this.listaProdutoCombo.length > 0) {
      for (let index = 0; index < this.listaProdutoCombo.length; index++) {
        const element = this.listaProdutoCombo[index];
        if (element != null) {
          const p = this.Produtos.find((x) => x.ProdutoID == element.ProdutoID);
          if (p != null) {
            this.listaProdutoCombo[index].Descricao = p.Descricao;
          }
        }
      }
    }
    // FIM PRODUTOS
    this.dataDialog = true;
  }

  deleteData(data: ProdutoModel) {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja excluir ' + data.Descricao + '?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.service.deleteProduto(data.id);
        this.data = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Produto excluído com sucesso!',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.dataDialog = false;
    this.data = {};
    this.produtoCombo = {};
    this.submitted = false;
  }

  FormataString(data) {
    if (
      data == null ||
      data == undefined ||
      data == 'null' ||
      data == 'undefined'
    ) {
      return '';
    }

    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  loadingSave: boolean = false;
  async saveData() {
    try {
      this.submitted = true;
      this.loadingSave = true;

      let data = {};
      let arrayProdutoCombo: ProdutoComboModel[];
      arrayProdutoCombo = [];

      if (this.data.ProdutoID != undefined) {
        if (this.listaProdutoCombo.length > 0) {
          for (let index = 0; index < this.listaProdutoCombo.length; index++) {
            const element = this.listaProdutoCombo[index];
            if (element != null) {
              let dt: ProdutoComboModel = {
                ProdutoComboID: this.data.ProdutoID,
                ProdutoID: element.ProdutoID,
                Quantidade:
                  element.Quantidade != undefined ? element.Quantidade : 0,
                ValorUnitario:
                  element.ValorUnitario != undefined
                    ? element.ValorUnitario
                    : 0,
                ValorTotal:
                  element.ValorTotal != undefined ? element.ValorTotal : 0,
                Fixo: element.Fixo,
              };

              arrayProdutoCombo.push(dt);
            }
          }
        }

        data['Ativo'] = this.data.Ativo;
        data['Categoria'] = this.data.Categoria;
        data['CategoriaID'] = this.data.CategoriaID;
        data['CodigoAlternativo'] = this.data.CodigoAlternativo;
        data['Descricao'] = this.data.Descricao;
        data['Preco'] = this.data.Preco;
        data['ProdutoID'] = this.data.ProdutoID;
        data['QuantidadeObjetivo'] = this.data.QuantidadeObjetivo;
        data['QuantidadeTroca'] = this.data.QuantidadeTroca;
        data['QuantidadeVenda'] = this.data.QuantidadeVenda;
        data['SubCategoria'] = this.data.SubCategoria;
        data['SubCategoriaID'] = this.data.SubCategoriaID;
        data['UnidadeObjetivo'] = this.data.UnidadeObjetivo;
        data['UnidadeTroca'] = this.data.UnidadeTroca;
        data['UnidadeVenda'] = this.data.UnidadeVenda;
        data['urlImage'] = this.FormataString(this.data.urlImage);
        data['ProdutosCombos'] = this.data.ProdutosCombos = arrayProdutoCombo;

        this.service.updateProduto(this.data.ProdutoID, data);

        if (this.data.ProdutoID.indexOf('CB')) {
          let result = await this.service.PostProdutoAlterar(this.data);

          if (result == true) {
            this.data.isEdit = false;
            this.dataDialog = false;
            this.loadingSave = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Informação',
              detail: 'Registro alterado com sucesso!',
              life: 3000,
            });
          } else {
            this.loadingSave = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Informação',
              detail: 'Erro ao atualizar!\n' + result,
            });
          }
        } else {
          let result = await this.service.PostProdutoComboAlterar(this.data);

          if (result == true) {
            this.data.isEdit = false;
            this.dataDialog = false;
            this.loadingSave = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Informação',
              detail: 'Registro alterado com sucesso!',
              life: 3000,
            });
          } else {
            this.loadingSave = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Informação',
              detail: 'Erro ao atualizar!\n' + result,
            });
          }
        }
      } else {
        if (this.listaProdutoCombo.length > 0) {
          for (let index = 0; index < this.listaProdutoCombo.length; index++) {
            const element = this.listaProdutoCombo[index];
            if (element != null) {
              let dt: ProdutoComboModel = {
                ProdutoComboID: null,
                ProdutoID: this.FormataString(element.ProdutoID),
                Quantidade:
                  element.Quantidade != undefined ? element.Quantidade : 0,
                ValorUnitario:
                  element.ValorUnitario != undefined
                    ? element.ValorUnitario
                    : 0,
                ValorTotal:
                  element.ValorTotal != undefined ? element.ValorTotal : 0,
                Fixo: element.Fixo,
              };

              arrayProdutoCombo.push(dt);
            }
          }
        }
        data['ProdutoID'] = null;
        data['Ativo'] =
          this.data.Ativo == undefined
            ? 'N'
            : this.FormataString(this.data.Ativo);
        data['Categoria'] = this.FormataString(this.data.Categoria);
        data['CategoriaID'] = this.data.CategoriaID;
        data['CodigoAlternativo'] = this.FormataString(
          this.data.CodigoAlternativo
        );
        data['Descricao'] = this.data.Descricao;
        data['Preco'] = this.data.Preco;
        data['QuantidadeObjetivo'] = this.data.QuantidadeObjetivo;
        data['QuantidadeTroca'] = this.data.QuantidadeTroca;
        data['QuantidadeVenda'] = this.data.QuantidadeVenda;
        data['SubCategoria'] = this.data.SubCategoria;
        data['SubCategoriaID'] = this.data.SubCategoriaID;
        data['UnidadeObjetivo'] = this.FormataString(this.data.UnidadeObjetivo);
        data['UnidadeTroca'] = this.FormataString(this.data.UnidadeTroca);
        data['UnidadeVenda'] = this.FormataString(this.data.UnidadeVenda);
        data['urlImage'] = this.FormataString(this.data.urlImage);
        data['ProdutosCombos'] = this.data.ProdutosCombos = arrayProdutoCombo;

        let result = await this.service.PostProdutoComboInserir(this.data);

        if (result == true) {
          this.data.isEdit = false;
          this.dataDialog = false;
          this.loadingSave = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Registro criado com sucesso!',
            life: 3000,
          });
        } else {
          this.loadingSave = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Erro ao criar!\n' + result,
          });
        }
      }
    } catch (error) {
      this.loadingSave = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro ao atualizar!\n' + error,
      });
    }
  }

  onChangeCategoria(event) {
    this.data.SubCategoria = '';
    this.data.SubCategoriaID = 0;
    this.subcategorias = this.categorias.find(
      (x) => x.Descricao == event
    ).SubCategorias;
    this.data.CategoriaID = this.categorias.find(
      (x) => x.Descricao == event
    ).CategoriaID;
    this.data.Categoria = this.categorias.find(
      (x) => x.Descricao == event
    ).Descricao;
  }

  onChangeSubCategoria(event) {
    if (event != null && event != undefined) {
      this.data.SubCategoriaID = this.subcategorias.find(
        (x) => x.Descricao == event
      ).CategoriaID;
      this.data.SubCategoria = this.subcategorias.find(
        (x) => x.Descricao == event
      ).Descricao;
    }
  }

  atualizarProdutos() {
    this.loading = true;

    this.service.atualizaProduto().subscribe(
      (data: any) => {
        this.loading = false;
        if (data.sucesso == true) {
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Lista atualizada!',
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Erro ao atualizar!\n' + data.mensagem,
          });
          this.loading = false;
        }
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Erro ao atualizar!\n' + err,
        });
        this.loading = false;
      }
    );
  }

  selecionadoProdutoCombo: boolean = false;
  indexProdutoCombo: number = -1;

  AdicionarProdutoCombo() {
    if (this.produtoCombo.Fixo == undefined) {
      this.produtoCombo.Fixo = false;
    }

    if (
      this.produtoCombo != null &&
      !this.produtoCombo.ProdutoID.indexOf('CB')
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não é possível inserir um Combo!',
      });
      return;
    }

    if (this.selecionadoProdutoCombo == true && this.indexProdutoCombo > -1) {
      this.listaProdutoCombo[this.indexProdutoCombo] = this.produtoCombo;
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Registro alterado!',
        life: 3000,
      });

      this.selecionadoProdutoCombo = false;
      this.indexProdutoCombo = -1;
    } else {
      const existe = this.listaProdutoCombo.find(
        (x) => x.ProdutoID == this.produtoCombo.ProdutoID
      );

      if (existe != null) {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Já existe produto com o mesmo código!',
        });
        return;
      }

      //this.product.id = this.createId();
      this.listaProdutoCombo.push(this.produtoCombo);
      this.selecionadoProdutoCombo = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Registro criado!',
        life: 3000,
      });
    }
    this.calcularValores();
    //this.promocoesDescontos = [...this.promocoesDescontos];
    this.produtoCombo = {};
  }

  calcularValores() {
    let totalPreco: number = 0;
    if (this.listaProdutoCombo != null) {
      if (this.listaProdutoCombo.length > 0) {
        for (let index = 0; index < this.listaProdutoCombo.length; index++) {
          const element = this.listaProdutoCombo[index];
          totalPreco += element.ValorTotal;
        }
        this.data.Preco = totalPreco;
      }
    }
  }

  findIndexById(data: ProdutoComboModel): number {
    return this.listaProdutoCombo.indexOf(data);
  }

  EditarProdutoCombo(edit: ProdutoComboModel) {
    this.selecionadoProdutoCombo = true;
    this.indexProdutoCombo = this.findIndexById(edit);
    this.produtoCombo = { ...edit };
  }

  RemoverProdutoCombo(edit: ProdutoComboModel) {
    this.confirmationService.confirm({
      message: 'Deseja remover esse item ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const p = this.listaProdutoCombo.find((x) => x == edit);
        if (p != null) {
          const x = this.listaProdutoCombo.indexOf(p);
          this.listaProdutoCombo.splice(x, 1);
        }
        this.calcularValores();
        this.produtoCombo = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Deletado com sucesso!',
          life: 3000,
        });
      },
    });
  }

  onChangeProdutoCombo(event: string, type: string) {
    if (event != null || event != undefined) {
      this.produtoCombo.ProdutoID = '';
      this.produtoCombo.Descricao = '';
      this.produtoCombo.ValorUnitario = 0;
      this.produtoCombo.Preco = 0;
      this.produtoCombo.Ativo = '';
      this.produtoCombo.Quantidade = 0;
      this.produtoCombo.ValorTotal = 0;

      const infos = this.Produtos.find((prod) => prod[type] == event);

      this.produtoCombo.ProdutoID = infos.ProdutoID;
      this.produtoCombo.Descricao = infos.Descricao;
      this.produtoCombo.Preco = infos.Preco;
      this.produtoCombo.ValorUnitario = infos.Preco;
      this.produtoCombo.Ativo =
        this.data.Ativo == undefined ? 'N' : this.data.Ativo;
    } else {
      this.produtoCombo.ProdutoID = '';
      this.produtoCombo.Descricao = '';
      this.produtoCombo.ValorUnitario = 0;
      this.produtoCombo.Preco = 0;
      this.produtoCombo.Ativo = '';
      this.produtoCombo.Quantidade = 0;
      this.produtoCombo.ValorTotal = 0;
    }
  }

  calcularDesconto(event: number) {
    if (event > 0) {
      this.produtoCombo.ValorUnitario =
        this.produtoCombo.Preco -
        (Number(this.produtoCombo.Preco) * event) / 100;
    } else {
      this.produtoCombo.ValorUnitario = this.produtoCombo.Preco;
    }
  }

  permiteAlterar(event: boolean) {
    if (event != undefined) {
      this.produtoCombo.Fixo = event;
    } else {
      event = false;
      this.produtoCombo.Fixo = event;
    }
  }

  calcularQuantidade(event: number) {
    if (event > 0) {
      this.produtoCombo.ValorTotal =
        Number(this.produtoCombo.ValorUnitario) * event;
    } else {
      this.produtoCombo.ValorTotal = 0;
    }
  }

  displayImage: boolean;

  showImageDialog() {
    this.displayImage = true;
  }

  mostrarProdutosCombos(data: ProdutoModel) {
    if (data.ProdutoID != null && data.ProdutoID != '') {
      if (data.ProdutoID.indexOf('CB')) {
        return false;
      }

      return true;
    } else {
      return true;
    }
  }
}
