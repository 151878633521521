import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { UsuarioModel } from './usuario.model';

@Injectable()
export class UsuarioService {
    currentUser = JSON.parse(localStorage.getItem('usuarioLogado'));
    nomeTabela = 'usuarios';

    constructor(private http: HttpClient,
        private router: Router,
        public firestore: AngularFirestore,
        public fireAuth: AngularFireAuth) {

    }
    /* Cria um novo registro na coleção especificada usando o método add */
    create_NewUsuario(record) {
        return this.firestore.collection('usuarios').add(record);
    }
    read_Usuarios() {
        return this.firestore.collection<UsuarioModel>('usuarios').snapshotChanges();
    }
    /*update_Icecream : atualiza o registro pegando o ID e chamando o método de atualização */
    update_Usuario(recordID, record) {
        this.firestore.doc('usuarios/' + recordID).update(record);
    }
    /*delete_Icecream : chama o método de exclusão  ao registrar o ID*/
    delete_Usuario(record_id) {
        this.firestore.doc('usuarios/' + record_id).delete();
    }
}