import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { NotificacaoModel } from './notificacao.model';
import { UnidadeModel } from 'src/app/models/unidade.model';
import { CidadeModel } from 'src/app/models/cidade.model';
import { SupervisorModel } from 'src/app/models/supervisor.model';
import { CarteiraModel } from 'src/app/models/carteira.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { PapeisClienteModel } from 'src/app/models/papeiscliente.model';
import { ParceiroModel } from '../parceiro/parceiro.model';
import { TipoNotificacaoModel } from './tipo.notificacao.model';
import { PostNotificacaoEnviar } from 'src/app/services/api.service';

@Injectable()
export class NotificacaoService {
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}

  private updateToken = () => {
    this.TOKEN = JSON.parse(localStorage.getItem('tokenUser'));
  };

  create_Notificacao(record) {
    return this.firestore.collection('Notificacoes').add(record);
  }
  read_Notificacao() {
    return this.firestore
      .collection<NotificacaoModel>('Notificacoes')
      .snapshotChanges();
  }
  read_TipoNotificacao() {
    return this.firestore
      .collection<TipoNotificacaoModel>('TipoNotificacao')
      .snapshotChanges();
  }
  /*Atualiza o registro pegando o ID e chamando o método de atualização */
  update_Notificacao(recordID, record) {
    this.firestore.doc('Notificacoes/' + recordID).update(record);
  }
  /*Chama o método de exclusão  ao registrar o ID*/
  delete_Notificacao(record_id) {
    this.firestore.doc('Notificacoes/' + record_id).delete();
  }

  delete_all_Notificacao() {
    this.firestore.doc('Notificacoes').delete();
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  async PostNotificacaoEnviar(dados: NotificacaoModel) {
    let resultado: any = 'erro';
    let response: any;
    //Exemplo:
    //         JSON:
    // {
    //   "Titulo": "titulo da notificacao",
    //   "Mensagem": "Mensagem da notificacao",
    //   "Dados": "PROMOCAO|promocaoID",
    //   "CarteirasVendas": ["1","2","3"],
    //   "Cidades": ["1","2","3"],
    //   "Segmentos": ["1","2","3"],
    //   "Supervisores": ["1","2","3"],
    //   "Unidades": ["1","2","3"],
    //   "PapeisClientes": ["1","2","3"],
    //   "ParceirosNegocios": [
    //      "975AE600-7544-49EE-A562-3923E57D9447",
    //      "11D0EB53-C99A-42C6-AD29-B8F67E5A93A6",
    //      "C682E12F-C364-44F0-82CF-5EC0C8E7E514"
    //   ]
    // }
    try {
      let data: any = {
        Titulo: dados.Titulo,
        Mensagem: dados.Mensagem,
        Dados: dados.Dados,
        Icone: dados.Icone,
        CarteirasVendas: dados.CarteirasVendasString,
        Cidades: dados.CidadesString,
        Segmentos: dados.SegmentosString,
        Supervisores: dados.SupervisoresString,
        Unidades: dados.UnidadesString,
        PapeisClientes: dados.PapeisClientesString,
        ParceirosNegocios: dados.ParceirosNegociosString,
      };
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          console.log(data);
          console.log('token', this.TOKEN);

          response = await this.http
            .post(
              `${PostNotificacaoEnviar}/${this.TOKEN}`,
              JSON.stringify(data)
            )
            .toPromise();
          // essa endpoint ainda não está funcionando, ela está com problema e isso ainda será solucionado

          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      console.log(error);

      resultado = 'erro' + error;
    }
  }

  //LISTAS
  readParceiroNegocios() {
    return this.firestore
      .collection<ParceiroModel>('ParceirosNegocios')
      .snapshotChanges();
  }
  readUnidadesPromocao() {
    return this.firestore
      .collection<UnidadeModel>('Unidades')
      .snapshotChanges();
  }
  readCidadesPromocao() {
    return this.firestore.collection<CidadeModel>('Cidades').snapshotChanges();
  }
  readSupervisorPromocao() {
    return this.firestore
      .collection<SupervisorModel>('Supervisores')
      .snapshotChanges();
  }
  readCarteiraPromocao() {
    return this.firestore
      .collection<CarteiraModel>('CarteirasVendas')
      .snapshotChanges();
  }
  readSegmentosPromocao() {
    return this.firestore
      .collection<SegmentoModel>('Segmentos')
      .snapshotChanges();
  }
  readPapeisClientesPromocao() {
    return this.firestore
      .collection<PapeisClienteModel>('PapeisClientes')
      .snapshotChanges();
  }
}
