export const PostProdutoAlterar =
  'https://parceiropoty.azurewebsites.net/api/adm/produtos/alterar';
export const PostProdutoComboAlterar =
  'https://parceiropoty.azurewebsites.net/api/adm/produtoscombos';

export const PostCategoriaAlterar =
  'https://parceiropoty.azurewebsites.net/api/adm/categoria/alterar';
export const PostParceiroCadastrar =
  'https://parceiropoty.azurewebsites.net/api/parceiroPoty/novoParceiro/cadastrar';
export const PostParceiroAlterar =
  'https://parceiropoty.azurewebsites.net/api/adm/parceiroPoty/alterar';
export const PostParceiroDeletar =
  'https://parceiropoty.azurewebsites.net/api/parceiroPoty/excluirComplemento';

export const PostPromocaoInserir =
  'https://parceiropoty.azurewebsites.net/api/adm/Promocoes/salvar';

export const PostNotificacaoEnviar =
  'https://ppantigoapi.bebidaspoty.com.br/notificacoes/enviar';

export const GetVerificarNovoParceiro =
  'https://parceiropoty.azurewebsites.net/api/parceiroPoty/novoParceiro/{token}/{cpfCnpj}';

export const GetParceiro =
  'https://parceiropoty.azurewebsites.net/api/parceiroNegocio/atualizacao';

export const PostParceiroTrocarEmail =
  'https://parceiropoty.azurewebsites.net/api/parceiroPoty/MudarEmailSenha';

export const gerarToken =
  'https://parceiropoty.azurewebsites.net/api/autenticacao/gerarToken';

export const GetCategorias =
  'https://parceiropoty.azurewebsites.net/api/adm/atualizarCategoriasDoMktPlaceAdmin';
export const GetProdutos =
  'https://parceiropoty.azurewebsites.net/api/cargas/produtos';
export const GetPromocao =
  'https://parceiropoty.azurewebsites.net/api/cargas/promocoes';
