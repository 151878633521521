import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { FirebaseService } from '../app.login.firebase.service';
import { UsuarioModel } from './usuario.model';
import { UsuarioService } from './usuario.service';

@Component({
  selector: 'app-usuario',
  templateUrl: 'usuario.component.html',
  styleUrls: ['usuario.component.scss'],
})
export class UsuarioComponent implements OnInit {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  usuarioDialog: boolean;

  usuarios: any;

  usuario: UsuarioModel;

  selectedUsuarios: UsuarioModel[];

  submitted: boolean;

  statuses: any[];

  constructor(
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore
  ) {}

  ngOnDestroy() {
    //this.usuarios.unsubscribe();
  }

  async ngOnInit() {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        user.displayName;
        this.firestore
          .collection('usuarios', (ref) => ref.where('ativo', '==', 'S'))
          .snapshotChanges()
          .subscribe((data) => {
            this.usuarios = data.map((e) => {
              const dataValues = e.payload.doc.data();
              return {
                id: e.payload.doc.id,
                isEdit: false,
                cod_usuario: e.payload.doc.id,
                login: dataValues['login'],
                usuario: dataValues['usuario'],
                senha: dataValues['senha'],
                uid: dataValues['uid'],
                ativo: dataValues['ativo'],
              };
            });
          });
      } else {
        this.usuarios = new Observable<any[]>();
      }
    });
  }

  openNew() {
    this.usuario = {};
    this.submitted = false;
    this.usuarioDialog = true;
  }

  deleteSelectedData() {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja todos os registros?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.usuarios = this.usuarios.filter(
          (val) => !this.selectedUsuarios.includes(val)
        );
        this.selectedUsuarios = null;
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Usuários excluídos com sucesso!',
          life: 3000,
        });
      },
    });
  }

  editData(usuario: UsuarioModel) {
    usuario.isEdit = true;
    this.usuario = { ...usuario };
    console.log(this.usuario);
    this.usuarioDialog = true;
  }

  deleteData(usuario: UsuarioModel) {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja excluir ' + usuario.login + '?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        let data = {};
        data['login'] = usuario.login;
        data['usuario'] = usuario.usuario;
        data['senha'] = usuario.senha;
        data['uid'] = usuario.uid;
        data['ativo'] = 'E';
        this.usuarioService.update_Usuario(usuario.cod_usuario, data);
        this.usuario = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Usuário excluído com sucesso!',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.usuarioDialog = false;
    this.submitted = false;
    this.loadingSave = false;
  }

  loadingSave: boolean = false;
  async saveData() {
    this.submitted = true;
    this.loadingSave = true;

    let data = {};

    console.log(this.usuario);
    if (this.usuario.cod_usuario != undefined) {
      data['cod_usuario'] = this.usuario.cod_usuario;
      data['login'] = this.usuario.login;
      data['usuario'] = this.usuario.usuario;
      data['senha'] = this.usuario.senha;
      data['uid'] = this.usuario.uid;
      data['ativo'] = this.usuario.ativo;
      console.log(this.usuario.cod_usuario);
      this.usuarioService.update_Usuario(this.usuario.cod_usuario, data);
      this.usuarioDialog = false;
      this.usuario.isEdit = false;
      this.loadingSave = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Registro alterado com sucesso!',
        life: 3000,
      });
    } else {
      data['login'] = this.usuario.login;
      data['usuario'] = this.usuario.usuario;
      data['senha'] = this.usuario.senha;
      data['ativo'] = 'S';
      console.log(this.usuario);
      this.loadingSave = false;

      var errorCode;
      var errorMessage;

      await this.firebaseService
        .createSignup(this.usuario.login, this.usuario.senha)
        .catch(function (error) {
          // Handle Errors here.
          errorCode = error.code;
          errorMessage = error.message;

          console.log(errorCode);
          console.log(errorMessage);
          // ...
        });

      if (errorCode != null) {
        if (errorCode.toString() == 'auth/invalid-email') {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Email não está correto!',
            life: 3000,
          });
          return;
        }

        if (errorCode.toString() == 'auth/user-not-found') {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Usuário não encontrado na base de dados!',
            life: 3000,
          });
          return;
        }

        if (errorCode.toString() == 'auth/weak-password') {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'A senha deve ter pelo menos 6 caracteres!',
            life: 3000,
          });
          return;
        }

        if (errorCode.toString() == 'auth/email-already-in-use') {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'O endereço de e-mail já está sendo usado por outra conta!',
            life: 3000,
          });
          return;
        }
      }
      let newUser = JSON.parse(localStorage.getItem('newUser'));

      data['uid'] = newUser.uid;
      console.log(newUser);

      this.usuarioService
        .create_NewUsuario(data)
        .then((resp) => {
          this.usuario.login = '';
          this.usuario.usuario = '';
          this.usuario.senha = '';
          this.usuario.uid = '';
          this.usuario.ativo = '';
          console.log(resp);
          this.usuarioDialog = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Registro cadastrado com sucesso!',
            life: 3000,
          });
        })
        .catch((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: error,
            life: 3000,
          });
          console.log(error);
        });
    }
  }
}
