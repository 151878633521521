import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { LoginService } from './app.login.service';
@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  isLoggedIn = false;
  constructor(
    public firebaseAuth: AngularFireAuth,
    private authService: LoginService,
    private router: Router
  ) {}
  async signin(email: string, password: string) {
    return await this.firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        this.isLoggedIn = true;
        localStorage.setItem('currentUser', JSON.stringify(res.user));

        const { token } = await this.authService.gerarToken(res.user.uid);

        localStorage.setItem('tokenUser', JSON.stringify(token));
        return { token, currentUser: JSON.stringify(res.user) };
      });
  }
  async signup(email: string, password: string) {
    await this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        this.isLoggedIn = true;
        localStorage.setItem('currentUser', JSON.stringify(res.user));
      });
  }

  async createSignup(email: string, password: string) {
    await this.firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        localStorage.setItem('newUser', JSON.stringify(res.user));
      });
  }

  logout() {
    this.firebaseAuth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
