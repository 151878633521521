<p-toast></p-toast>
<p-card>
    <div class="card">
        <!-- NOVO CADASTRO -->
        <br />
        <button pButton pRipple label="Novo (Desabilitado)" icon="pi pi-plus"
            class="p-button-sm p-button-success p-mr-2" disabled="true" (click)="openNew()"></button>
        <br />
        <br />
        <!-- *******************************LISTA *************************************-->
        <p-progressBar mode="indeterminate" [style]="{ height: '6px' }" *ngIf="loading"></p-progressBar>
        <p-table #dt [value]="datas" [rows]="50" [paginator]="true" [globalFilterFields]="['Titulo']"
            [(selection)]="selectedDatas" [rowHover]="true" dataKey="NotificacaoID"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registro(s)"
            [showCurrentPageReport]="true">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h5 class="p-m-0">Gerenciamento de Notificações</h5>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Pesquisar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="NotificacaoID">
                        Código <p-sortIcon field="NotificacaoID"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Titulo">
                        Título <p-sortIcon field="Titulo"></p-sortIcon>
                    </th>
                    <th></th>
                    <!-- <th style="width: 50px;"></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td>{{ data.NotificacaoID }}</td>
                    <td>{{ data.Titulo }}</td>
                    <td style="text-align: right">
                        <button pButton pRipple icon="pi pi-pencil" pTooltip="Editar"
                            class="p-button-sm p-button-rounded p-button-warning p-mr-2"
                            (click)="editData(data)"></button>
                        <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"></button> -->
                    </td>
                    <!-- <td>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        (click)="deleteData(data)"></button>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    No total, existem {{ datas ? datas.length : 0 }} registro(s).
                </div>
            </ng-template>
        </p-table>
    </div>
</p-card>

<!-- CADASTRO -->
<p-dialog [(visible)]="dataDialog" [style]="{'width':'95%','max-height':'100%', 'max-width':'1320px' }"
    header="Detalhe Notificação" [modal]="true" styleClass="p-fluid" [closeOnEscape]="false" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
        <p-card [style]="{ width: '100%', 'margin-top': '20px', 'margin-bottom': '30px' }">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center">
                    <div *ngIf="data.Icone; else elseBlock">
                        <img class="imagem-cad" [src]="data.Icone" style="width: 20%" />
                    </div>
                    <ng-template #elseBlock>
                        <div>
                            <img src="assets/image.png" style="width: 150px" />
                        </div>
                    </ng-template>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center; text-align: -webkit-center">
                    <label class="upload-arquivo" for="arquivo">Incluir/Alterar Imagem</label>
                    <input type="file" name="arquivo" id="arquivo" (change)="uploadFoto($event)" pInputText />
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-lg-3">
                    <label for="NotificacaoID">Código</label>
                    <input type="text" pInputText id="NotificacaoID" [(ngModel)]="data.NotificacaoID" required
                        [disabled]="true" />
                </div>
                <div class="p-col-12 p-md-12 p-lg-9">
                    <label for="Titulo">Titulo</label>
                    <input type="text" pInputText id="Titulo" [(ngModel)]="data.Titulo" required autofocus />
                    <small class="p-error" *ngIf="submitted && !data.Titulo">Descrição é Título.</small>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6">
                    <label>Tipo</label>
                    <p-dropdown [options]="tipoNotificacao" [(ngModel)]="data.Tipo" placeholder="Tipo"
                        optionLabel="Nome" optionValue="Nome" [showClear]="true"
                        (ngModelChange)="onChangeTipoNotificacao($event)"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-lg-6">
                    <label>Promoção</label>
                    <p-dropdown [options]="Promocoes" [(ngModel)]="data.Promocao" placeholder="Promoção"
                        optionLabel="Descricao" optionValue="PromocaoID" [showClear]="true"
                        [disabled]="disabledPromocao" (ngModelChange)="onChangePromocao($event)"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <label>Mensagem</label>
                    <textarea [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"
                        [(ngModel)]="data.Mensagem"></textarea>
                    <small class="p-error" *ngIf="submitted && !data.Mensagem">Descrição é obrigatório.</small>
                </div>
            </div>
            <p-accordion [multiple]="true" [style]="{ width: '100%' }">
                <!-- Parceiros -->

                <p-accordionTab header="Parceiros">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <button (click)="prevPage()">Anterior</button>
                        <button (click)="nextPage()">Próxima</button>
                        <p-pickList [source]="paginatedParceiros" [target]="ListaParceirosNegocios"
                            sourceHeader="Disponível" targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="RazaoSocial" sourceFilterPlaceholder="Pesquisar"
                            targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">{{ product.RazaoSocial }}</h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

                <!-- <p-pickList [source]="paginatedParceiros" ...>
                    <ng-template let-product pTemplate="item">
                      <div *ngIf="paginatedParceiros.indexOf(product) >= (pageNumber - 1) * pageSize && paginatedParceiros.indexOf(product) < pageNumber * pageSize">
                        <h5 class="p-mb-2">{{product.RazaoSocial}}</h5>
                      </div>
                    </ng-template>
                  </p-pickList> -->

                <!-- Carteiras -->

                <p-accordionTab header="Carteiras">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <p-pickList [source]="Carteiras" [target]="ListaCarteirasVendas" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="CarteiraVendaID,Descricao"
                            sourceFilterPlaceholder="Pesquisar" targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">
                                            {{ product.CarteiraVendaID }} - {{ product.Descricao }}
                                        </h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

                <!-- Cidades -->

                <p-accordionTab header="Cidades">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <p-pickList [source]="Cidades" [target]="ListaCidades" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="true" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="Descricao,Estado" sourceFilterPlaceholder="Pesquisar"
                            targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">
                                            {{ product.Descricao }}/{{ product.Estado }}
                                        </h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

                <!-- Segmentos de Clientes -->

                <p-accordionTab header="Segmentos de Clientes">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <p-pickList [source]="Segmentos" [target]="ListaSegmentos" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="SegmentoID,Nome" sourceFilterPlaceholder="Pesquisar"
                            targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">
                                            {{ product.SegmentoID }} - {{ product.Nome }}
                                        </h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

                <!-- Supervisores -->

                <p-accordionTab header="Supervisores">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <p-pickList [source]="Supervisores" [target]="ListaSupervisores" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="SupervisorID,Nome" sourceFilterPlaceholder="Pesquisar"
                            targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">
                                            {{ product.SupervisorID }} - {{ product.Nome }}
                                        </h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

                <!-- Centro de Distribuição -->

                <p-accordionTab header="Centro de Distribuição">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <p-pickList [source]="Unidades" [target]="ListaUnidades" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="UnidadeID,Descricao"
                            sourceFilterPlaceholder="Pesquisar" targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">
                                            {{ product.UnidadeID }} - {{ product.Descricao }}
                                        </h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>

                <!-- Papeis de Clientes-->

                <p-accordionTab header="Papeis de Clientes">
                    <div class="card" style="background-color: rgb(247, 247, 247); width: 100%">
                        <p-pickList [source]="PapeisClientes" [target]="ListaPapeisClientes" sourceHeader="Disponível"
                            targetHeader="Selecionado" [dragdrop]="false" [responsive]="true"
                            [sourceStyle]="{ height: '30rem' }" [targetStyle]="{ height: '30rem' }"
                            filterMatchMode="contains" filterBy="PapelClienteID,Descricao"
                            sourceFilterPlaceholder="Pesquisar" targetFilterPlaceholder="Pesquisar">
                            <ng-template let-product pTemplate="item">
                                <div class="product-item">
                                    <div class="product-list-detail">
                                        <h5 class="p-mb-2">
                                            {{ product.PapelClienteID }} - {{ product.Descricao }}
                                        </h5>
                                    </div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </p-card>
    </ng-template>
    <ng-template pTemplate="footer">
        <br />
        <button pButton pRipple type="button" label="GRAVAR" [disabled]="!data.Mensagem || !data.Titulo"
            style="width: 100px; float: left" class="p-button-raised p-button-success" (click)="saveData()"></button>

        <!--p-progressSpinner [style]="{'width':'20px','height':'20px','float':'left','margin-top':'5px'}"
            *ngIf="loadingSave" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner-->

        <button pButton pRipple type="button" label="RETORNAR" style="width: 100px"
            class="p-button-raised p-button-danger" (click)="hideDialog()"></button>

        <p-progressBar mode="indeterminate" [style]="{ height: '6px', 'margin-top': '5px' }"
            *ngIf="loadingSave"></p-progressBar>
    </ng-template>
    <!-- 
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-sm p-button-danger"
            (click)="hideDialog()"></button>
        <button pButton pRipple label="Gravar" icon="pi pi-check" class="p-button-sm p-button-success"
            (click)="saveData()" [disabled]="!data.Mensagem || !data.Titulo"></button>
    </ng-template> -->
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>