import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { CategoriaModel } from '../categoria/categoria.model';
import { ParceiroCupomModel } from './cupom.model';
import { PapeisClienteModel } from 'src/app/models/papeiscliente.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { CarteiraModel } from 'src/app/models/carteira.model';
import { SupervisorModel } from 'src/app/models/supervisor.model';
import { CidadeModel } from 'src/app/models/cidade.model';
import { UnidadeModel } from 'src/app/models/unidade.model';

@Injectable()
export class ParceiroCupomService {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  private basePath = '/cupom';

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}
  /* Cria um novo registro na coleção especificada usando o método add */
  createParceiroCupom(record, guide: string) {
    console.log(guide);
    return this.firestore.collection('Cupom').doc(guide).set(record);
  }

  updateParceiroCupom(recordID, record) {
    this.firestore.doc('Cupom/' + recordID).update(record);
  }

  readParceiroCupom() {
    return this.firestore
      .collection<ParceiroCupomModel>('Cupom')
      .snapshotChanges();
  }
  deleteCupom(recordID) {
    this.firestore.doc('Cupom/' + recordID).delete();
  }
  //LISTAS
  readCategoria() {
    return this.firestore
      .collection<CategoriaModel>('Categorias')
      .snapshotChanges();
  }
  readUnidadesCupom() {
    return this.firestore
      .collection<UnidadeModel>('Unidades')
      .snapshotChanges();
  }
  readCidadesCupom() {
    return this.firestore.collection<CidadeModel>('Cidades').snapshotChanges();
  }
  readSupervisorCupom() {
    return this.firestore
      .collection<SupervisorModel>('Supervisores')
      .snapshotChanges();
  }
  readCarteiraCupom() {
    return this.firestore
      .collection<CarteiraModel>('CarteirasVendas')
      .snapshotChanges();
  }
  readSegmentosCupom() {
    return this.firestore
      .collection<SegmentoModel>('Segmentos')
      .snapshotChanges();
  }
  readPapeisClientesCupom() {
    return this.firestore
      .collection<PapeisClienteModel>('PapeisClientes')
      .snapshotChanges();
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
