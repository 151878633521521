import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FirebaseService } from './pages/app.login.firebase.service';

@Component({
    selector: 'app-inlinemenu',
    templateUrl: './app.inlinemenu.component.html',
    animations: [
        trigger('inline', [
            state('hidden', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visible', style({
                height: '*',
            })),
            state('hiddenAnimated', style({
                height: '0px',
                overflow: 'hidden'
            })),
            state('visibleAnimated', style({
                height: '*',
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineMenuComponent {

    user = JSON.parse(localStorage.getItem('currentUser'));
    
    logout(){
        this.firebase.logout();
    }
    constructor(public appMain: AppMainComponent,
                public firebase: FirebaseService) {}
}
