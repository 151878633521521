import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let user = localStorage.getItem('currentUser');
    if (user) {
      // logado para retornar true
      return true;
    }
    console.log('não está logado');
    localStorage.clear();
    //testando de colocar esse clear no localstorage para evitar problema de atualizar token quando reconectaar conta no site
    // não está logado, então redirecione para a página de login com o URL de retorno
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
