import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { gerarToken } from '../services/api.service';

@Injectable()
export class LoginService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private firestore: AngularFirestore
  ) {}

  /* create_NewIcecream : Cria um novo registro na coleção especificada usando o método add */
  create_NewUsuario(record) {
    return this.firestore.collection('usuarios').add(record);
  }
  /*read_Icecream: Chama o método snapshotChanges , que obterá registros e também será registrado para receber atualizações */
  read_Usuarios() {
    return this.firestore.collection('usuarios').snapshotChanges();
  }
  /*update_Icecream : atualiza o registro pegando o ID e chamando o método de atualização */
  update_Usuario(recordID, record) {
    this.firestore.doc('usuarios/' + recordID).update(record);
  }
  /*delete_Icecream : chama o método de exclusão  ao registrar o ID*/
  delete_Usuario(record_id) {
    this.firestore.doc('usuarios/' + record_id).delete();
  }
  async gerarToken(uid: string) {
    console.log('token', uid);
    const response = this.http.get(`${gerarToken}/${uid}`);

    const token = (await response.toPromise()) as { token: string };

    return token;
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
