import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { CategoriaModel } from './categoria.model';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { FileUpload } from 'primeng/fileupload';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  GetCategorias,
  PostCategoriaAlterar,
} from 'src/app/services/api.service';
import { SubCategoriaModel } from './subcategoria.model';

@Injectable()
export class CategoriaService {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  private basePath = '/categorias';

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}

  private updateToken = () => {
    this.TOKEN = JSON.parse(localStorage.getItem('tokenUser'));
  };

  /* Cria um novo registro na coleção especificada usando o método add */
  create_NewCategoria(record) {
    return this.firestore.collection('Categorias').add(record);
  }
  read_Categoria() {
    return this.firestore
      .collection<CategoriaModel>('Categorias')
      .snapshotChanges();
  }
  /*Atualiza o registro pegando o ID e chamando o método de atualização */
  update_Categoria(recordID, record) {
    this.firestore.doc('Categorias/' + recordID).update(record);
  }
  /*Chama o método de exclusão  ao registrar o ID*/
  delete_Categoria(record_id) {
    this.firestore.doc('Categorias/' + record_id).delete();
  }

  delete_all_Categoria() {
    this.firestore.doc('Categorias').delete();
  }

  getFiles(): AngularFireList<FileUpload> {
    console.log(this.db.list(this.basePath));
    return this.db.list(this.basePath);
  }

  async PostCategoriaAlterar(dados: CategoriaModel): Promise<any> {
    let resultado: any = 'erro';
    let response: any;
    let arraySubCategoria: any[];
    arraySubCategoria = [];

    try {
      console.log(dados);

      //SUB CATEGORIA
      if (dados.SubCategorias.length > 0) {
        for (let index = 0; index < dados.SubCategorias.length; index++) {
          const element = dados.SubCategorias[index];
          if (element != null) {
            let dt: SubCategoriaModel = {
              CategoriaID: Number(element.CategoriaID),
              Descricao: element.Descricao,
              Status: element.Status,
              Sequencia: element.Sequencia,
            };

            arraySubCategoria.push(dt);
          }
        }
      }
      //FIM SUB CATEGORIA

      let data: any = {
        CategoriaID: Number(dados.CategoriaID),
        Descricao: dados.Descricao,
        Status: dados.Status,
        Sequencia: dados.Sequencia,
        SubCategorias: arraySubCategoria,
      };

      console.log(data);
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          console.log('token', this.TOKEN);
          response = await this.http
            .post(`${PostCategoriaAlterar}/${this.TOKEN}`, JSON.stringify(data))
            .toPromise();
          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      resultado = 'erro' + error;
    }
  }

  atualizaCategoria() {
    console.log('token', this.TOKEN);
    this.updateToken();
    return this.http.get(`${GetCategorias}/${this.TOKEN}`);
  }
  // SUB CATEGORIAS
  update_SubCategoria(recordID, record) {
    this.firestore.doc('Categorias/' + recordID).update(record);
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
