import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  AngularFirestore,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { FileUpload } from 'primeng/fileupload';
import { AngularFireStorage } from '@angular/fire/storage';
import { ParceiroModel } from './parceiro.model';
import {
  GetParceiro,
  PostParceiroAlterar,
  PostParceiroDeletar,
  PostParceiroTrocarEmail,
} from 'src/app/services/api.service';

@Injectable()
export class ParceiroService {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  private basePath = '/parceiros';

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}

  private updateToken = () => {
    this.TOKEN = JSON.parse(localStorage.getItem('tokenUser'));
  };

  /* Cria um novo registro na coleção especificada usando o método add */
  createParceiro(record) {
    return this.firestore.collection('ParceirosNegocios').add(record);
  }
  // readParceiro() {
  //   return this.firestore
  //     .collection<ParceiroModel>('ParceirosNegocios')
  //     .snapshotChanges();
  // }

  readParceiro(
    limit: number,
    dataReference: string,
    searchValue: string,
    startAfter?: QueryDocumentSnapshot<ParceiroModel>
  ) {
    return this.firestore
      .collection<ParceiroModel>('ParceirosNegocios', (ref) => {
        let queryRef = ref
          .where(dataReference, '>=', searchValue)
          .where(dataReference, '<=', searchValue + '\uf8ff')
          // .orderBy(dataReference)
          .limit(limit);

        if (startAfter) {
          queryRef = queryRef.startAfter(startAfter);
        }
        return queryRef;
      })
      .get();
  }

  deleteParceiro(recordId) {
    console.log({ deletarFirebase: recordId });

    this.firestore.doc('ParceirosNegocios/' + recordId).delete();
  }

  readParceirosNegociosComplementos(id: string) {
    return this.firestore
      .collection<ParceiroModel>('ParceirosNegociosComplementos', (ref) =>
        ref.where('FirebaseID', '==', id)
      )
      .snapshotChanges();
  }
  /*Atualiza o registro pegando o ID e chamando o método de atualização */
  updateParceiro(recordID, record) {
    this.firestore.doc('ParceirosNegocios/' + recordID).update(record);
  }
  /*Chama o método de exclusão  ao registrar o ID*/

  deleteAllParceiro() {
    // this.firestore.doc('ParceirosNegocios').delete();
  }

  getFiles(): AngularFireList<FileUpload> {
    console.log(this.db.list(this.basePath));
    return this.db.list(this.basePath);
  }

  async PostDeletarParceiro(parceiroId: string): Promise<any> {
    let resultado: any = 'erro';
    let response: any;
    console.log(this.TOKEN);

    console.log(`${PostParceiroDeletar}/${this.TOKEN}/${parceiroId}`);

    try {
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          console.log('entrei');

          response = await this.http
            .post(`${PostParceiroDeletar}/${this.TOKEN}/${parceiroId}`, null)
            .toPromise();
          console.log({ deletar: response });

          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      console.log({ erro: error });

      resultado = 'erro' + error;
    }
  }

  async PostParceiroAlterar(dados: ParceiroModel): Promise<any> {
    let resultado: any = 'erro';
    let response: any;
    //Exemplo:
    // {
    //   "firebaseID": "LKJJlkjdakjlkj...",
    //   "telefone": "17438423423",
    //   "celular": "17438423423",
    //   "email":"teste@teste.com.br",
    //   "segmento":"10"
    // }
    try {
      let data: any = {
        FirebaseID: dados.FirebaseID,
        Email: dados.Email,
        Telefone: dados.Telefone,
        Celular: dados.Celular,
        Segmento: Number(dados.SegmentoID),
      };
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          response = await this.http
            .post(`${PostParceiroAlterar}/${this.TOKEN}`, JSON.stringify(data))
            .toPromise();
          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      resultado = 'erro' + error;
    }
  }

  atualizaParceiro(id: string) {
    this.updateToken();
    return this.http.get(`${GetParceiro}/${this.TOKEN}/${id}`);
  }

  logout() {
    localStorage.removeItem('currentUser');
  }

  async postAtualizaDadosParceiro(
    email: string,
    firebaseId: string,
    password: string
  ) {
    let resultado;
    let response;
    try {
      let dados: any = {
        Email: email,
        FirebaseID: firebaseId,
        Senha: password,
      };
      this.updateToken();
      response = await this.http
        .post(`${PostParceiroTrocarEmail}`, dados)
        .toPromise();
      if (response.sucesso == true) {
        resultado = response.sucesso;
      }
      return resultado;
    } catch (error) {
      resultado = 'erro' + error;
    }
  }
}
