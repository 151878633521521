import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { Observable } from 'rxjs';
import { FirebaseService } from '../app.login.firebase.service';
import { ParceiroConfigService } from './configuracao.service';
import { ParceiroConfigModel } from './configuracao.model';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.scss'],
})
export class ConfigComponent implements OnInit {
  private basePath = 'configuracao/';
  show = false;
  loadingSave: boolean = false;
  dataDialog: boolean;
  Guide: string;
  loading: boolean = true;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  datas: any;
  data: any;
  submitted: boolean;

  constructor(
    private service: ParceiroConfigService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private config: PrimeNGConfig
  ) {
    this.pesquisar();
  }

  private converterHexParaRGB(hex: string): string {
    // Lógica para converter o valor hexadecimal para RGB
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    return `rgb(${r}, ${g}, ${b})`;
  }

  pesquisar() {
    try {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.service.readConfiguracao().subscribe((data) => {
            this.datas = data.map((e) => {
              const {
                CorDoApp,
                CorPadraoDoApp,
                manutencao,
                whatsapp,
                CorDoBotaoDoApp,
                UsarCorSelecionada,
              } = e.payload.doc.data();

              return {
                id: e.payload.doc.id,
                CorDoApp,
                CorPadraoDoApp,
                manutencao,
                whatsapp,
                CorDoBotaoDoApp,
                UsarCorSelecionada,
              };
            });
          });
        } else {
          this.datas = new Observable<any[]>();
        }
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  ngOnDestroy() {}

  async ngOnInit() {}

  editData(data: any) {
    this.loadingSave = false;
    this.data = { ...data };

    this.dataDialog = true;
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
  }

  async saveData() {
    try {
      this.submitted = true;
      this.loadingSave = true;

      let data: ParceiroConfigModel = { ...this.data };

      data.CorDoApp = {
        ...this.data.CorDoApp,
        rgb: this.converterHexParaRGB(this.data.CorDoApp.hex),
      };
      data.CorDoBotaoDoApp = {
        ...this.data.CorDoBotaoDoApp,
        rgb: this.converterHexParaRGB(this.data.CorDoBotaoDoApp.hex),
      };
      data.CorPadraoDoApp = this.data.CorPadraoDoApp;
      data.manutencao = this.data.manutencao;
      data.UsarCorSelecionada = this.data.UsarCorSelecionada;
      data.whatsapp = this.data.whatsapp;

      this.service.updateParceiroConfiguracao(this.data.id, data);

      this.data.isEdit = false;
      this.dataDialog = false;
      this.loadingSave = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Registro alterado com sucesso!',
        life: 3000,
      });
    } catch (error) {
      console.log(error);

      this.loadingSave = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não foi possível fazer a requisição! \n' + error,
      });
    }
  }

  escolherCorPadrao(data) {
    this.data = { ...data, CorDoApp: { ...this.datas[0].CorPadraoDoApp } };
  }
}
