import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LoginService } from './pages/app.login.service';
import { ProdutoService } from './pages/produto/produto.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menuMode = 'sidebar';

  layout = 'blue';

  theme = 'blue';

  ripple: boolean;

  colorScheme = 'dark';

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  constructor(
    private primengConfig: PrimeNGConfig,
    private authService: LoginService,
    private produtoService: ProdutoService
  ) {}

  async gerarNovoToken() {
    try {
      if (!this.currentUser.uid) {
        localStorage.clear();
        window.location.reload();
        return;
      }
      const { token } = await this.authService.gerarToken(this.currentUser.uid);
      console.log('token reloaded', token);
      localStorage.setItem('tokenUser', JSON.stringify(token));
      return { token, currentUser: JSON.stringify(this.currentUser) };
    } catch (error) {
      localStorage.clear();
      window.location.reload();
    }
  }

  async ngOnInit() {
    this.primengConfig.ripple = true;
    this.ripple = true;
    console.log(this.TOKEN);
    console.log(this.currentUser);
    if (this.TOKEN && this.currentUser) {
      const res = await this.gerarNovoToken();

      this.produtoService.updateLocalstorage(res);
    }

    this.produtoService.readProduto().subscribe((data) => {
      localStorage.removeItem('Produtos');

      let prods = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          isEdit: false,
          Ativo: dataValues.Ativo,
          Categoria: dataValues.Categoria,
          CategoriaID: dataValues.CategoriaID,
          CodigoAlternativo: dataValues.CodigoAlternativo,
          Descricao: dataValues.Descricao,
          Preco: dataValues.Preco,
          ProdutoID: dataValues.ProdutoID,
          QuantidadeObjetivo: dataValues.QuantidadeObjetivo,
          QuantidadeTroca: dataValues.QuantidadeTroca,
          QuantidadeVenda: dataValues.QuantidadeVenda,
          SubCategoria: dataValues.SubCategoria,
          SubCategoriaID: dataValues.SubCategoriaID,
          UnidadeObjetivo: dataValues.UnidadeObjetivo,
          UnidadeTroca: dataValues.UnidadeTroca,
          UnidadeVenda: dataValues.UnidadeVenda,
          urlImage: dataValues.urlImage,
        };
      });

      localStorage.setItem('Produtos', JSON.stringify(prods));
    });
  }
}
