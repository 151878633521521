import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FirebaseService } from '../app.login.firebase.service';
import { CategoriaModel } from './categoria.model';
import { CategoriaService } from './categoria.service';
import { SubCategoriaModel } from './subcategoria.model';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss'],
})
export class CategoriaComponent implements OnInit {
  show = false;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  dataDialog: boolean;
  dataDialogSubCategoria: boolean;

  datas: any;
  lista: CategoriaModel[];
  loading: boolean = true;
  data: CategoriaModel;
  dataSubCategoria: SubCategoriaModel;
  selectedDatas: CategoriaModel[];
  submitted: boolean;
  sequencias: any[];
  tinhaSequencia: boolean = false;
  SequenciaAnterior: number = 0;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  displayImage: boolean;
  displaySubImage: boolean;

  private basePath = 'categorias/';
  private baseSubCategoriaPath = 'subcategorias/';

  profileUrl: any;

  constructor(
    private categoriaService: CategoriaService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.sequencias = [];

    for (let index = 1; index < 1000; index++) {
      let data = { nome: index };
      this.sequencias.push(data);
    }

    try {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.categoriaService.read_Categoria().subscribe((data) => {
            this.datas = data.map((e) => {
              const dataValues = e.payload.doc.data();
              this.loading = false;
              return {
                id: e.payload.doc.id,
                isEdit: false,
                CategoriaID: dataValues['CategoriaID'],
                Descricao: dataValues['Descricao'],
                Sequencia: dataValues['Sequencia'],
                SubCategorias: dataValues['SubCategorias'],
                urlImage: dataValues['urlImage'],
                Status: dataValues['Status'],
              };
            });

            //this.listaSequencia();
          });
        } else {
          this.datas = new Observable<any[]>();
        }
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  listaSequencia() {
    for (let index = 1; index < 1000; index++) {
      const p = this.datas.find((x) => x.Sequencia == index);
      if (p == null) {
        let data = { nome: index };
        this.sequencias.push(data);
      }
    }
  }

  ngOnDestroy() {}

  async ngOnInit() {
    this.data = {};
    this.dataSubCategoria = {};
  }

  showImageDialog() {
    this.displayImage = true;
  }

  showSubImageDialog() {
    this.displaySubImage = true;
  }

  uploadedFiles: File = null;
  fb: any;

  uploadCategoria(event) {
    this.uploadedFiles = <File>event.target.files[0];

    try {
      if (this.uploadedFiles != null) {
        const filePath = `${this.basePath}/${this.data.CategoriaID}.png`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, this.uploadedFiles);

        uploadTask
          .snapshotChanges()
          .pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe((downloadURL) => {
                this.fb = downloadURL;
                this.data.urlImage = downloadURL;
                this.messageService.add({
                  severity: 'success',
                  summary: 'Informação',
                  detail: 'Foto alterada com sucesso!',
                });
                //this.saveFileData(this.fb);
              });
            })
          )
          .subscribe();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro em alterar foto!',
      });
      alert(error);
    }
  }

  SubuploadedFiles: File = null;
  Subfb: any;

  editarSubCategoria(subCategoria: SubCategoriaModel, index) {
    this.dataDialogSubCategoria = true;
    subCategoria.index = index;
    this.dataSubCategoria = { ...subCategoria };
  }
  uploadSubCategoria(event) {
    this.SubuploadedFiles = <File>event.target.files[0];

    try {
      if (this.dataSubCategoria != null) {
        if (this.SubuploadedFiles != null) {
          const filePath = `${this.baseSubCategoriaPath}/${this.dataSubCategoria.CategoriaID}.png`;
          const storageRef = this.storage.ref(filePath);
          const uploadTask = this.storage.upload(
            filePath,
            this.SubuploadedFiles
          );

          uploadTask
            .snapshotChanges()
            .pipe(
              finalize(() => {
                storageRef.getDownloadURL().subscribe((downloadURL) => {
                  this.Subfb = downloadURL;
                  this.data.SubCategorias[
                    this.dataSubCategoria.index
                  ].urlImage = downloadURL;
                  this.dataSubCategoria.urlImage = downloadURL;

                  // if (this.data.CategoriaID != undefined) {
                  //   if (this.data.imagem == undefined) {
                  //     this.data.imagem = "";
                  //   }
                  //   this.categoriaService.update_Categoria(this.data.CategoriaID, this.data);
                  // }

                  this.messageService.add({
                    severity: 'success',
                    summary: 'Informação',
                    detail: 'Foto alterada com sucesso!',
                  });
                });
              })
            )
            .subscribe();
        }
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro em alterar foto!',
      });
      alert(error);
    }
  }

  async getImage(codigo: number): Promise<string> {
    let resultado: Promise<string>;
    try {
      await this.storage
        .ref(this.basePath + codigo + '.png')
        .getDownloadURL()
        .toPromise()
        .then((response: any) => {
          resultado = response;
          return resultado;
        })
        .catch((error) => error);

      return resultado;
    } catch (error) {}
  }

  openNew() {
    this.data = {};
    this.submitted = false;
    this.dataDialog = true;
  }

  deleteSelectedData() {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja todos os registros?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.datas = this.datas.filter(
          (val) => !this.selectedDatas.includes(val)
        );
        this.selectedDatas = null;
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Usuários excluídos com sucesso!',
          life: 3000,
        });
      },
    });
  }

  async editData(data: CategoriaModel) {
    data.isEdit = true;
    this.loadingSave = false;
    this.tinhaSequencia = data.Sequencia ? true : false;
    this.SequenciaAnterior = data.Sequencia;

    data.urlImage = await this.getImage(data.CategoriaID);
    this.data = { ...data };
    this.dataDialog = true;
  }

  deleteData(data: CategoriaModel) {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja excluir ' + data.Descricao + '?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.categoriaService.delete_Categoria(data.CategoriaID);
        this.data = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Usuário excluído com sucesso!',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
  }

  loadingSave: boolean = false;
  async saveData() {
    try {
      if (this.tinhaSequencia == false) {
        if (this.SequenciaAnterior != this.data.Sequencia) {
          const p = this.datas.find((x) => x.Sequencia == this.data.Sequencia);
          if (p != null) {
            this.messageService.add({
              severity: 'error',
              summary: 'Informação',
              detail:
                'Número da sequência ' + this.data.Sequencia + ' já existe.',
            });
            return;
          }
        }
      } else {
        if (this.SequenciaAnterior != this.data.Sequencia) {
          const p = this.datas.find((x) => x.Sequencia == this.data.Sequencia);
          if (p != null) {
            this.messageService.add({
              severity: 'error',
              summary: 'Informação',
              detail:
                'Número da sequência ' + this.data.Sequencia + ' já existe.',
            });
            return;
          }
        }
      }

      this.submitted = true;
      this.loadingSave = true;

      let data = {};

      if (this.data.CategoriaID != undefined) {
        data['CategoriaID'] = this.data.CategoriaID;
        data['Descricao'] = this.FormataString(this.data.Descricao);
        data['urlImage'] = this.FormataString(this.data.urlImage);
        data['Status'] = this.data.Status;
        data['Sequencia'] = this.data.Sequencia;
        data['SubCategorias'] = this.data.SubCategorias;

        this.categoriaService.update_Categoria(this.data.CategoriaID, data);

        let result = await this.categoriaService.PostCategoriaAlterar(
          this.data
        );

        if (result == true) {
          this.data.isEdit = false;
          this.dataDialog = false;
          this.loadingSave = false;
          //this.listaSequencia();
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Registro alterado com sucesso!',
            life: 3000,
          });
        } else {
          this.loadingSave = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Erro ao atualizar!\n' + result,
          });
        }
      }
    } catch (error) {
      this.loadingSave = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro ao atualizar!\n' + error,
      });
    }
  }

  saveDataSubCategoria(descri: string, status: boolean) {
    this.dataDialogSubCategoria = false;
    this.data.SubCategorias[this.dataSubCategoria.index].Descricao = descri;
    this.data.SubCategorias[this.dataSubCategoria.index].Status = status;
  }

  atualizarCategorias() {
    this.loading = true;

    this.categoriaService.atualizaCategoria().subscribe(
      (data: any) => {
        this.loading = false;
        if (data.sucesso == true) {
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Lista atualizada!',
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Erro ao atualizar!\n' + data.mensagem,
          });
          this.loading = false;
        }
      },
      (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Erro ao atualizar!\n' + err,
        });
        this.loading = false;
      }
    );
  }

  FormataString(data) {
    if (
      data == null ||
      data == undefined ||
      data == 'null' ||
      data == 'undefined'
    ) {
      return '';
    }

    return data;
  }
}
