import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { MessageService } from 'primeng/api';
import { LoginModel } from './app.login.model';
import { LoginService } from './app.login.service';
import { FirebaseService } from './app.login.firebase.service';
import { ProdutoService } from './produto/produto.service';

@Component({
  selector: 'app-login',
  templateUrl: 'app.login.component.html',
})
export class LoginComponent implements OnInit {
  public usuario: LoginModel = new LoginModel();
  returnUrl: string;
  version: string;
  isSignedIn = false;
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: LoginService,
    public fireAuth: AngularFireAuth,
    public firebaseService: FirebaseService,
    public messageService: MessageService,
    private produtoService: ProdutoService
  ) {}

  async ngOnInit() {
    this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.version = environment.version;

    if (localStorage.getItem('user') !== null) this.isSignedIn = true;
    else this.isSignedIn = false;

    //  this.usuario.usuario = "marcos_rbr94@hotmail.com";
    //  this.usuario.senha = "38681938";
  }

  onInputKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.usuario.usuario && this.usuario.senha) {
      this.onClickLogin();
    }
  }

  async onClickLogin() {
    this.loading = true;

    var errorCode;
    var errorMessage;

    if (String(this.usuario.usuario) == undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Informe o E-mail!',
        life: 3000,
      });
      this.loading = false;
      return;
    }

    if (String(this.usuario.senha) == undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Informe a Senha!',
        life: 3000,
      });
      this.loading = false;
      return;
    }

    await this.firebaseService
      .signin(this.usuario.usuario, this.usuario.senha)
      .then((res) => {
        this.produtoService.updateLocalstorage(res);
      })
      .catch(function (error) {
        // Handle Errors here.
        errorCode = error.code;
        errorMessage = error.message;

        console.log(errorCode);
        console.log(errorMessage);
        // ...
      });
    if (errorCode != null) {
      if (errorCode.toString() == 'auth/invalid-email') {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Email não está correto!',
          life: 3000,
        });
        this.loading = false;
        return;
      }

      if (errorCode.toString() == 'auth/user-not-found') {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Usuário não encontrado na base de dados!',
          life: 3000,
        });
        this.loading = false;
        return;
      }

      if (errorCode.toString() == 'auth/wrong-password') {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'A senha é inválida ou o usuário não possui uma senha!',
          life: 3000,
        });
        this.loading = false;
        return;
      }
    }

    this.carregarProdutos();
    this.carregarSegmentos();

    this.isSignedIn = true;
    this.router.navigate(['/']);
  }

  carregarProdutos() {
    this.produtoService.readProduto().subscribe((data) => {
      localStorage.removeItem('Produtos');

      let prods = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          isEdit: false,
          Ativo: dataValues.Ativo,
          Categoria: dataValues.Categoria,
          CategoriaID: dataValues.CategoriaID,
          CodigoAlternativo: dataValues.CodigoAlternativo,
          Descricao: dataValues.Descricao,
          Preco: dataValues.Preco,
          ProdutoID: dataValues.ProdutoID,
          QuantidadeObjetivo: dataValues.QuantidadeObjetivo,
          QuantidadeTroca: dataValues.QuantidadeTroca,
          QuantidadeVenda: dataValues.QuantidadeVenda,
          SubCategoria: dataValues.SubCategoria,
          SubCategoriaID: dataValues.SubCategoriaID,
          UnidadeObjetivo: dataValues.UnidadeObjetivo,
          UnidadeTroca: dataValues.UnidadeTroca,
          UnidadeVenda: dataValues.UnidadeVenda,
          urlImage: dataValues.urlImage,
        };
      });

      localStorage.setItem('Produtos', JSON.stringify(prods));
    });
  }

  carregarSegmentos() {
    this.produtoService.readSegmento().subscribe((data) => {
      localStorage.removeItem('Segmentos');

      let prods = data.map((e) => {
        return {
          id: e.payload.doc.id,
          SegmentoID: e.payload.doc.data()['SegmentoID'],
          Nome: e.payload.doc.data()['Nome'],
        };
      });

      localStorage.setItem('Segmentos', JSON.stringify(prods));
    });
  }
}
