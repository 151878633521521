import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  public model: any[];

  constructor(public app: AppComponent, public appMain: AppMainComponent) {}

  async ngOnInit() {
    this.model = [
      {
        label: 'Dashboard',
        icon: 'pi pi-fw pi-chart-bar',
        routerLink: ['/'],
      },
      {
        label: 'Cadastro',
        icon: 'pi pi-fw pi-id-card',
        routerLink: ['/cadastro'],
        items: [
          {
            label: 'Usuário',
            icon: 'pi pi-fw pi-user',
            routerLink: ['/cadastro/usuario'],
          },
          {
            label: 'Categoria',
            icon: 'pi pi-fw pi-sitemap',
            routerLink: ['/cadastro/categoria'],
          },
          {
            label: 'Produto',
            icon: 'pi pi-fw pi-tag',
            routerLink: ['/cadastro/produto'],
          },
          {
            label: 'Parceiro',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/cadastro/parceiro'],
          },
          {
            label: 'Promoção',
            icon: 'pi pi-fw pi-tags',
            routerLink: ['/cadastro/promocao'],
          },
          {
            label: 'Cupom',
            icon: 'pi pi-fw pi-tags',
            routerLink: ['/cadastro/cupom'],
          },
          {
            label: 'Notificação',
            icon: 'pi pi-fw pi-bell',
            routerLink: ['/cadastro/notificacao'],
          },
        ],
      },
      {
        label: 'Configurações do APP',
        icon: 'pi pi-fw pi-cog',
        routerLink: ['/app/configuracao'],
      },
    ];
  }
}
