import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { ParceiroPromocaoModel } from './promocao.model';
import { UnidadeModel } from 'src/app/models/unidade.model';
import { CidadeModel } from 'src/app/models/cidade.model';
import { SupervisorModel } from 'src/app/models/supervisor.model';
import { CarteiraModel } from 'src/app/models/carteira.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { PapeisClienteModel } from 'src/app/models/papeiscliente.model';
import { GetPromocao, PostPromocaoInserir } from 'src/app/services/api.service';
import { timeout } from 'rxjs/operators';

@Injectable()
export class ParceiroPromocaoService {
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  TOKEN = JSON.parse(localStorage.getItem('tokenUser'));

  private basePath = '/parceiro';

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}

  private updateToken = () => {
    this.TOKEN = JSON.parse(localStorage.getItem('tokenUser'));
  };

  /* Cria um novo registro na coleção especificada usando o método add */
  createParceiroPromocao(record, guide: string) {
    console.log(guide);
    return this.firestore.collection('Promocoes').doc(guide).set(record);
  }
  readParceiroPromocao() {
    return this.firestore
      .collection<ParceiroPromocaoModel>('Promocoes')
      .snapshotChanges();
  }
  /*Atualiza o registro pegando o ID e chamando o método de atualização */
  updateParceiroPromocao(recordID, record) {
    this.firestore.doc('Promocoes/' + recordID).update(record);
  }
  /*Chama o método de exclusão  ao registrar o ID*/
  deleteParceiroPromocao(record_id) {
    this.firestore.doc('Promocoes/' + record_id).delete();
  }

  deleteAllParceiroPromocao() {
    // this.firestore.doc('Promocoes').delete();
  }

  async PostPromocaoInserir(dados: ParceiroPromocaoModel): Promise<any> {
    let resultado: any = 'erro';
    let response: any;
    //Exemplo:
    // {
    //     "PromocaoID":"63d3f00e-64e1-48c1-b16b-0006877b77e6",
    //     "Descricao":"Promo\u00E7\u00E3o semana 26 e 30/7 Roller 3L",
    //     "DescricaoAplicativo":"Promo\u00E7\u00E3o semana 26 e 30/7 Roller 3L",
    //     "DataInicio":"2021-07-26T00:00:00",
    //     "DataFinal":"2021-07-30T00:00:00",
    //     "Ativo":false,
    //     "PromocaoMktPlace":1009,
    //     "Destacar":true,
    //     "PromocoesCarteirasVendas":[],
    //     "PromocoesCidades":[],
    //      "PromocoesDescontos":
    //      [{
    //       "PromocaoDescontoID":"989e81d6-9de0-44ae-914c-59f35d81c053",
    //       "PromocaoID":"63d3f00e-64e1-48c1-b16b-0006877b77e6",
    //       "Quantidade":0,"Percentual":15.99
    //       }],
    //     "PromocoesProdutos":
    //      [{
    //      "PromocaoProdutoID":"cc40b8cc-a2e7-4a2e-9d38-874eefcd8026",
    //      "PromocaoID":"63d3f00e-64e1-48c1-b16b-0006877b77e6",
    //      "ProdutoID":"PA000036",
    //      "QuantidadeMinima":0,
    //      "QuantidadeMaxima":0
    //      }],
    //     "PromocoesSegmentos":[],
    //     "PromocoesSupervisores":[],
    //     "PromocoesUnidades":[],
    //      "PromocoesPapeisClientes":
    //      [{
    //       "PromocaoPapelClienteID":"2aab59a8-993a-4faf-8363-d01a50669366",
    //       "PromocaoID":"63d3f00e-64e1-48c1-b16b-0006877b77e6",
    //       "PapelClienteID":74
    //       }]
    //     }
    try {
      let data: any = {
        PromocaoID: dados.PromocaoID,
        Descricao: dados.Descricao,
        DescricaoAplicativo: dados.DescricaoAplicativo,
        DataInicio: dados.DataInicio,
        DataFinal: dados.DataFinal,
        Ativo: dados.Ativo,
        PromocaoMktPlace: dados.PromocaoMktPlace,
        Destacar: dados.Destacar,
        DestacarNoProduto: dados.DestacarNoProduto,
        PromocoesCarteirasVendas: dados.PromocoesCarteirasVendas,
        PromocoesCidades: dados.PromocoesCidades,
        PromocoesDescontos: dados.PromocoesDescontos,
        PromocoesProdutos: dados.PromocoesProdutos,
        PromocoesSegmentos: dados.PromocoesSegmentos,
        PromocoesSupervisores: dados.PromocoesSupervisores,
        PromocoesUnidades: dados.PromocoesUnidades,
        PromocoesPapeisClientes: dados.PromocoesPapeisClientes,
      };
      this.updateToken();

      if (this.TOKEN != null || this.TOKEN != undefined) {
        const response = (await this.http
          .post(`${PostPromocaoInserir}/${this.TOKEN}`, JSON.stringify(data))
          // .pipe(timeout(26000000)) // removido para testar enquanto não temos tantos valores para demorar no uso da api de promoções
          .toPromise()) as { sucesso: boolean; mensagem: string };

        console.log(response);
        if (response.sucesso == true) {
          resultado = response.sucesso;
        }
      }

      return resultado;
    } catch (error) {
      console.log({ error });

      resultado = 'erro' + error;
    }
  }

  //LISTAS
  readUnidadesPromocao() {
    return this.firestore
      .collection<UnidadeModel>('Unidades')
      .snapshotChanges();
  }
  readCidadesPromocao() {
    return this.firestore.collection<CidadeModel>('Cidades').snapshotChanges();
  }
  readSupervisorPromocao() {
    return this.firestore
      .collection<SupervisorModel>('Supervisores')
      .snapshotChanges();
  }
  readCarteiraPromocao() {
    return this.firestore
      .collection<CarteiraModel>('CarteirasVendas')
      .snapshotChanges();
  }
  readSegmentosPromocao() {
    return this.firestore
      .collection<SegmentoModel>('Segmentos')
      .snapshotChanges();
  }
  readPapeisClientesPromocao() {
    return this.firestore
      .collection<PapeisClienteModel>('PapeisClientes')
      .snapshotChanges();
  }
  //
  atualizarPromocoes() {
    this.updateToken();

    const response = this.http.get(`${GetPromocao}/${this.TOKEN}`);
    // .pipe(timeout(26000000)); // removido para testar enquanto não temos tantos valores para demorar no uso da api de promoções

    return response;
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
