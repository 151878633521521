import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { UUID } from 'angular2-uuid';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CarteiraModel } from 'src/app/models/carteira.model';
import { CidadeModel } from 'src/app/models/cidade.model';
import { PapeisClienteModel } from 'src/app/models/papeiscliente.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { SupervisorModel } from 'src/app/models/supervisor.model';
import { UnidadeModel } from 'src/app/models/unidade.model';
import { FirebaseService } from '../app.login.firebase.service';
import { ProdutoModel } from '../produto/produto.model';
import { PromocaoCarteiraVendasModel } from './promocao.carteiravendas.model';
import { PromocaoCidadesModel } from './promocao.cidades.model';
import { PromocaoDescontoModel } from './promocao.desconto.model';
import { ParceiroPromocaoModel } from './promocao.model';
import { PromocaoPapeisClienteModel } from './promocao.papeiscliente.model';
import { PromocaoProdutosModel } from './promocao.produtos.model';
import { PromocaoSegmentoModel } from './promocao.segmento.model';
import { ParceiroPromocaoService } from './promocao.service';
import { PromocaoSupervisorModel } from './promocao.supervisor.model';
import { PromocaoUnidadeModel } from './promocao.unidade.model';

@Component({
  selector: 'app-promocao',
  templateUrl: './promocao.component.html',
  styleUrls: ['./promocao.component.scss'],
})
export class PromocaoComponent implements OnInit {
  show = false;
  ativosInativos = '';

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  Produtos: ProdutoModel[] = JSON.parse(localStorage.getItem('Produtos'));
  Unidades: UnidadeModel[];
  Cidades: CidadeModel[];
  Supervisores: SupervisorModel[];
  Carteiras: CarteiraModel[];
  Segmentos: SegmentoModel[];
  PapeisClientes: PapeisClienteModel[];

  PeriodoDataInicial: Date = new Date(2000, 2, 1);
  PeriodoDataFinal: Date = new Date();
  FiltroDescricao: string;

  dataDialog: boolean;

  Minimo: number;
  Maximo: number;

  datas: any;
  lista: ParceiroPromocaoModel[];
  loading: boolean = true;
  data: ParceiroPromocaoModel;
  dataDesconto: PromocaoDescontoModel;
  selectedDatas: ParceiroPromocaoModel[];
  submitted: boolean;
  Guide: string;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  selectedPromocaoDescontoModels: PromocaoDescontoModel;

  ListaPromocoesProdutos: PromocaoProdutosModel[];
  ListaPromocoesUnidades: PromocaoUnidadeModel[];
  ListaPromocoesCidades: PromocaoCidadesModel[];
  ListaPromocoesSupervisores: PromocaoSupervisorModel[];
  ListaPromocoesCarteirasVendas: PromocaoCarteiraVendasModel[];
  ListaPromocoesSegmentos: PromocaoSegmentoModel[];
  ListaPromocoesPapeisClientes: PromocaoPapeisClienteModel[];

  promocoesDescontos: PromocaoDescontoModel[];
  private basePath = 'promocoes/';

  profileUrl: any;

  constructor(
    private service: ParceiroPromocaoService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private config: PrimeNGConfig
  ) {
    this.ListaPromocoesProdutos = [];
    this.ListaPromocoesCidades = [];
    this.ListaPromocoesUnidades = [];
    this.ListaPromocoesSupervisores = [];
    this.ListaPromocoesCarteirasVendas = [];
    this.ListaPromocoesSegmentos = [];
    this.ListaPromocoesPapeisClientes = [];
    this.promocoesDescontos = [];

    this.config.setTranslation({
      accept: 'Aceitar',
      reject: 'Cancelar',
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      //translations
    });

    this.carregarListas();
    this.pesquisar();
  }

  mudarAtivosInativos(args: string) {
    this.ativosInativos = args;
  }

  datasFiltred() {
    if (this.datas)
      return this.datas.filter((data) =>
        this.ativosInativos === '' ? true : data.Ativo === this.ativosInativos
      );
  }

  pesquisar() {
    try {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.service.readParceiroPromocao().subscribe((data) => {
            this.datas = data.map((e) => {
              const dataValues = e.payload.doc.data();

              return {
                id: e.payload.doc.id,
                isEdit: false,
                Banner: dataValues['Banner'],
                DataFinal: dataValues['DataFinal'],
                DataInicio: dataValues['DataInicio'],
                Descricao: dataValues['Descricao'],
                DescricaoAplicativo: dataValues['DescricaoAplicativo'],
                PromocaoID: dataValues['PromocaoID'],
                PromocaoMktPlace: dataValues['PromocaoMktPlace'],
                Ativo: dataValues['Ativo'],
                Destacar: dataValues['Destacar'],
                DestacarNoProduto: dataValues['DestacarNoProduto'],

                PromocoesCarteirasVendas:
                  dataValues['PromocoesCarteirasVendas'],
                PromocoesCidades: dataValues['PromocoesCidades'],
                PromocoesDescontos: dataValues['PromocoesDescontos'],
                PromocoesProdutos: dataValues['PromocoesProdutos'],
                PromocoesSegmentos: dataValues['PromocoesSegmentos'],
                PromocoesSupervisores: dataValues['PromocoesSupervisores'],
                PromocoesUnidades: dataValues['PromocoesUnidades'],
                PromocoesPapeisClientes: dataValues['PromocoesPapeisClientes'],
              };
            });
          });
        } else {
          this.datas = new Observable<any[]>();
        }
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  carregarListas() {
    this.ListaPromocoesProdutos = [];
    this.ListaPromocoesUnidades = [];
    this.ListaPromocoesCidades = [];
    this.ListaPromocoesSupervisores = [];
    this.ListaPromocoesCarteirasVendas = [];
    this.ListaPromocoesSegmentos = [];
    this.ListaPromocoesPapeisClientes = [];
    this.promocoesDescontos = [];

    //#region LISTA
    this.Produtos = JSON.parse(localStorage.getItem('Produtos'));
    this.Unidades = [];
    this.Cidades = [];
    this.Supervisores = [];
    this.Carteiras = [];
    this.Segmentos = [];
    this.PapeisClientes = [];

    //UNIDADES
    this.service.readUnidadesPromocao().subscribe((data) => {
      this.Unidades = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          UnidadeID: dataValues['UnidadeID'],
          Descricao: dataValues['Descricao'],
        };
      });
    });
    // FIM UNIDADES

    //CIDADES
    this.service.readCidadesPromocao().subscribe((data) => {
      this.Cidades = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          CidadeID: dataValues['CidadeID'],
          Descricao: dataValues['Descricao'],
          Estado: dataValues['Estado'],
        };
      });
    });
    // FIM CIDADES

    //SUPERVISORES
    this.service.readSupervisorPromocao().subscribe((data) => {
      this.Supervisores = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          SupervisorID: dataValues['SupervisorID'],
          Nome: dataValues['Nome'],
        };
      });
    });
    // FIM SUPERVISORES

    //CARTEIRA
    this.service.readCarteiraPromocao().subscribe((data) => {
      this.Carteiras = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          CarteiraVendaID: dataValues['CarteiraVendaID'],
          Descricao: dataValues['Descricao'],
          Status: dataValues['Status'],
          SupervisorID: dataValues['SupervisorID'],
          SupervisorNome: dataValues['SupervisorNome'],
          UnidadeDescricao: dataValues['UnidadeDescricao'],
          UnidadeID: dataValues['UnidadeID'],
        };
      });
    });
    // FIM CARTEIRA

    //SEGMENTOS
    this.service.readSegmentosPromocao().subscribe((data) => {
      this.Segmentos = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          SegmentoID: dataValues['SegmentoID'],
          Nome: dataValues['Nome'],
        };
      });
    });
    // FIM SEGMENTOS

    //PAPEIS CLIENTES
    this.service.readPapeisClientesPromocao().subscribe((data) => {
      this.PapeisClientes = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          PapelClienteID: dataValues['PapelClienteID'],
          Descricao: dataValues['Descricao'],
          Ativo: dataValues['Ativo'],
        };
      });
    });
    // FIM PAPEIS CLIENTES
    //#endregion
  }

  ngOnDestroy() {}

  async ngOnInit() {
    this.data = {};
    this.dataDesconto = {};
  }

  displayImage: boolean;

  showImageDialog() {
    this.displayImage = true;
  }
  openNew() {
    this.data = {};
    this.dataDesconto = {};
    this.Guide = UUID.UUID();

    this.selecionadoDesconto = false;
    this.indexDesconto = -1;

    this.submitted = false;
    this.dataDialog = true;
  }

  uploadedFiles: File = null;
  fb: any;
  uploadPromocao(event) {
    this.uploadedFiles = <File>event.target.files[0];

    try {
      if (this.uploadedFiles != null) {
        let filePath: any;
        if (this.data.PromocaoID != null && this.data.PromocaoID != undefined) {
          filePath = `${this.basePath}/${this.data.PromocaoID}.png`;
        } else {
          filePath = `${this.basePath}/${this.Guide}.png`;
        }

        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, this.uploadedFiles);

        uploadTask
          .snapshotChanges()
          .pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe((downloadURL) => {
                this.fb = downloadURL;
                this.data.Banner = downloadURL;

                if (this.data.PromocaoID != undefined) {
                  if (this.data.Banner == undefined) {
                    this.data.Banner = '';
                  }
                  // this.service.updateParceiro(this.data.id, this.data);
                }

                this.messageService.add({
                  severity: 'success',
                  summary: 'Informação',
                  detail: 'Foto alterada com sucesso!',
                });
                //this.saveFileData(this.fb);
              });
            })
          )
          .subscribe();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro em alterar foto!',
      });
      alert(error);
    }
  }

  FormataStringData(data) {
    var dia = data.split('/')[0];
    var mes = data.split('/')[1];
    var ano = data.split('/')[2];

    return (
      ano +
      '-' +
      ('0' + mes).slice(-2) +
      '-' +
      ('0' + dia).slice(-2) +
      ' 00:00:00'
    );
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  FormataString(data) {
    if (
      data == null ||
      data == undefined ||
      data == 'null' ||
      data == 'undefined'
    ) {
      return '';
    }

    return data;
  }

  FormataNumber(data) {
    if (data == null || data == undefined) {
      return 0;
    }
    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  FormataBolean(data) {
    if (data == null || data == undefined) {
      return false;
    }
    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  convert(str) {
    var date = new Date(str);
    var mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join('/');
  }

  formatarDataExtenso(data) {
    // Meses possíveis
    var meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    // Dias possíveis
    var diasSemana = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];
    // Partes da data informada
    var dia = data.getDate();
    var dias = data.getDay();
    var mes = data.getMonth();
    var ano = data.getYear();

    var hora = new Date().getHours();
    var minu = new Date().getMinutes();
    var segu = new Date().getSeconds();
    // Resultado
    var extenso = '';
    //DataFinal
    //5 de agosto de 2021 21:00:00 UTC-3
    //DataInicio
    //1 de agosto de 2021 21:00:00 UTC-3

    // Para o navegador Netscape o ano começa em 1900
    if (navigator.appName == 'Netscape') {
      ano = ano + 1900;
    }

    //return extenso = diasSemana[dias] + ', ' + dia + ' de ' + meses[mes] + ' de ' + ano;

    return (extenso =
      dia +
      ' de ' +
      meses[mes] +
      ' de ' +
      ano +
      ' ' +
      ('0' + hora).slice(-2) +
      ':' +
      ('0' + minu).slice(-2) +
      ':' +
      ('0' + segu).slice(-2) +
      ' UTC-3');
  }

  editData(data: ParceiroPromocaoModel) {
    this.selecionadoDesconto = false;
    this.loadingSave = false;

    this.indexDesconto = -1;
    this.dataDesconto = {};

    this.data = { ...data };

    if (data.DataInicio) {
      let DI: any = data.DataInicio;
      this.data.DataInicio = new Date(this.convert(new Date(DI.toDate())));
    } else {
      this.data.DataInicio = null;
    }

    if (data.DataFinal) {
      let DF: any = data.DataFinal;
      this.data.DataFinal = new Date(this.convert(new Date(DF.toDate())));
    } else {
      this.data.DataFinal = null;
    }

    //#region  LISTAS

    this.promocoesDescontos = [];
    this.ListaPromocoesProdutos = [];
    this.ListaPromocoesUnidades = [];
    this.ListaPromocoesCidades = [];
    this.ListaPromocoesSupervisores = [];
    this.ListaPromocoesCarteirasVendas = [];
    this.ListaPromocoesSegmentos = [];
    this.ListaPromocoesPapeisClientes = [];

    this.promocoesDescontos = [...data.PromocoesDescontos];
    this.ListaPromocoesProdutos = [...data.PromocoesProdutos];
    this.ListaPromocoesUnidades = [...data.PromocoesUnidades];
    this.ListaPromocoesCidades = [...data.PromocoesCidades];
    this.ListaPromocoesSupervisores = [...data.PromocoesSupervisores];
    this.ListaPromocoesCarteirasVendas = [...data.PromocoesCarteirasVendas];
    this.ListaPromocoesSegmentos = [...data.PromocoesSegmentos];
    this.ListaPromocoesPapeisClientes = [...data.PromocoesPapeisClientes];

    data.isEdit = true;
    // data.urlImage = await this.getImage(data.CategoriaID);
    //REMOVENDO PRODUTOS DA LISTA PRODUTOS
    if (this.ListaPromocoesProdutos.length > 0) {
      for (let index = 0; index < this.ListaPromocoesProdutos.length; index++) {
        const element = this.ListaPromocoesProdutos[index];
        if (element != null) {
          const p = this.Produtos.find((x) => x.ProdutoID == element.ProdutoID);
          if (p != null) {
            this.ListaPromocoesProdutos[index].Descricao = p.Descricao;
            const x = this.Produtos.indexOf(p);
            this.Produtos.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

    //REMOVENDO UNIDADES DA LISTA UNIDADES
    if (this.ListaPromocoesUnidades.length > 0) {
      for (let index = 0; index < this.ListaPromocoesUnidades.length; index++) {
        const element = this.ListaPromocoesUnidades[index];
        if (element != null) {
          const p = this.Unidades.find((x) => x.UnidadeID == element.UnidadeID);
          if (p != null) {
            this.ListaPromocoesUnidades[index].Descricao = p.Descricao;
            const x = this.Unidades.indexOf(p);
            this.Unidades.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO UNIDADES DA LISTA UNIDADES

    //REMOVENDO CIDADES DA LISTA CIDADES
    if (this.ListaPromocoesCidades.length > 0) {
      for (let index = 0; index < this.ListaPromocoesCidades.length; index++) {
        const element = this.ListaPromocoesCidades[index];
        if (element != null) {
          const p = this.Cidades.find((x) => x.CidadeID == element.CidadeID);
          if (p != null) {
            this.ListaPromocoesCidades[index].Descricao = p.Descricao;
            this.ListaPromocoesCidades[index].Estado = p.Estado;
            const x = this.Cidades.indexOf(p);
            this.Cidades.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO CIDADES DA LISTA CIDADES

    //REMOVENDO SUPERVISOR DA LISTA SUPERVISOR
    if (this.ListaPromocoesSupervisores.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesSupervisores.length;
        index++
      ) {
        const element = this.ListaPromocoesSupervisores[index];
        if (element != null) {
          const p = this.Supervisores.find(
            (x) => x.SupervisorID == element.SupervisorID
          );
          if (p != null) {
            this.ListaPromocoesSupervisores[index].Nome = p.Nome;
            const x = this.Supervisores.indexOf(p);
            this.Supervisores.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO SUPERVISOR DA LISTA SUPERVISOR

    //REMOVENDO CARTEIRAS DA LISTA CARTEIRAS
    if (this.ListaPromocoesCarteirasVendas.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesCarteirasVendas.length;
        index++
      ) {
        const element = this.ListaPromocoesCarteirasVendas[index];
        if (element != null) {
          const p = this.Carteiras.find(
            (x) => x.CarteiraVendaID == element.CarteiraVendaID
          );
          if (p != null) {
            this.ListaPromocoesCarteirasVendas[index].Descricao = p.Descricao;
            const x = this.Carteiras.indexOf(p);
            this.Carteiras.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO CARTEIRAS DA LISTA CARTEIRAS

    //REMOVENDO SEGMENTOS DA LISTA SEGMENTOS
    if (this.ListaPromocoesSegmentos.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesSegmentos.length;
        index++
      ) {
        const element = this.ListaPromocoesSegmentos[index];
        if (element != null) {
          const p = this.Segmentos.find(
            (x) => x.SegmentoID == element.SegmentoID
          );
          if (p != null) {
            this.ListaPromocoesSegmentos[index].Nome = p.Nome;
            const x = this.Segmentos.indexOf(p);
            this.Segmentos.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO SEGMENTOS DA LISTA SEGMENTOS

    //REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE
    if (this.ListaPromocoesPapeisClientes.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesPapeisClientes.length;
        index++
      ) {
        const element = this.ListaPromocoesPapeisClientes[index];
        if (element != null) {
          const p = this.PapeisClientes.find(
            (x) => x.PapelClienteID == element.PapelClienteID
          );
          if (p != null) {
            this.ListaPromocoesPapeisClientes[index].Descricao = p.Descricao;
            const x = this.PapeisClientes.indexOf(p);
            this.PapeisClientes.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE

    //#endregion

    this.dataDialog = true;
  }

  editDuplicar(data: ParceiroPromocaoModel) {
    this.selecionadoDesconto = false;
    this.loadingSave = false;

    this.indexDesconto = -1;
    this.dataDesconto = {};

    data.PromocaoID = undefined;
    this.Guide = UUID.UUID();
    this.data = { ...data };

    if (data.DataInicio) {
      let DI: any = data.DataInicio;
      this.data.DataInicio = new Date(this.convert(new Date(DI.toDate())));
    } else {
      this.data.DataInicio = null;
    }

    if (data.DataFinal) {
      let DF: any = data.DataFinal;
      this.data.DataFinal = new Date(this.convert(new Date(DF.toDate())));
    } else {
      this.data.DataFinal = null;
    }

    //#region  LISTAS

    this.promocoesDescontos = [];
    this.ListaPromocoesProdutos = [];
    this.ListaPromocoesUnidades = [];
    this.ListaPromocoesCidades = [];
    this.ListaPromocoesSupervisores = [];
    this.ListaPromocoesCarteirasVendas = [];
    this.ListaPromocoesSegmentos = [];
    this.ListaPromocoesPapeisClientes = [];

    this.promocoesDescontos = [...data.PromocoesDescontos];
    this.ListaPromocoesProdutos = [...data.PromocoesProdutos];
    this.ListaPromocoesUnidades = [...data.PromocoesUnidades];
    this.ListaPromocoesCidades = [...data.PromocoesCidades];
    this.ListaPromocoesSupervisores = [...data.PromocoesSupervisores];
    this.ListaPromocoesCarteirasVendas = [...data.PromocoesCarteirasVendas];
    this.ListaPromocoesSegmentos = [...data.PromocoesSegmentos];
    this.ListaPromocoesPapeisClientes = [...data.PromocoesPapeisClientes];

    data.isEdit = true;
    // data.urlImage = await this.getImage(data.CategoriaID);
    //REMOVENDO PRODUTOS DA LISTA PRODUTOS
    if (this.ListaPromocoesProdutos.length > 0) {
      for (let index = 0; index < this.ListaPromocoesProdutos.length; index++) {
        const element = this.ListaPromocoesProdutos[index];
        if (element != null) {
          const p = this.Produtos.find((x) => x.ProdutoID == element.ProdutoID);
          if (p != null) {
            this.ListaPromocoesProdutos[index].Descricao = p.Descricao;
            const x = this.Produtos.indexOf(p);
            this.Produtos.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

    //REMOVENDO UNIDADES DA LISTA UNIDADES
    if (this.ListaPromocoesUnidades.length > 0) {
      for (let index = 0; index < this.ListaPromocoesUnidades.length; index++) {
        const element = this.ListaPromocoesUnidades[index];
        if (element != null) {
          const p = this.Unidades.find((x) => x.UnidadeID == element.UnidadeID);
          if (p != null) {
            this.ListaPromocoesUnidades[index].Descricao = p.Descricao;
            const x = this.Unidades.indexOf(p);
            this.Unidades.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO UNIDADES DA LISTA UNIDADES

    //REMOVENDO CIDADES DA LISTA CIDADES
    if (this.ListaPromocoesCidades.length > 0) {
      for (let index = 0; index < this.ListaPromocoesCidades.length; index++) {
        const element = this.ListaPromocoesCidades[index];
        if (element != null) {
          const p = this.Cidades.find((x) => x.CidadeID == element.CidadeID);
          if (p != null) {
            this.ListaPromocoesCidades[index].Descricao = p.Descricao;
            this.ListaPromocoesCidades[index].Estado = p.Estado;
            const x = this.Cidades.indexOf(p);
            this.Cidades.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO CIDADES DA LISTA CIDADES

    //REMOVENDO SUPERVISOR DA LISTA SUPERVISOR
    if (this.ListaPromocoesSupervisores.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesSupervisores.length;
        index++
      ) {
        const element = this.ListaPromocoesSupervisores[index];
        if (element != null) {
          const p = this.Supervisores.find(
            (x) => x.SupervisorID == element.SupervisorID
          );
          if (p != null) {
            this.ListaPromocoesSupervisores[index].Nome = p.Nome;
            const x = this.Supervisores.indexOf(p);
            this.Supervisores.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO SUPERVISOR DA LISTA SUPERVISOR

    //REMOVENDO CARTEIRAS DA LISTA CARTEIRAS
    if (this.ListaPromocoesCarteirasVendas.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesCarteirasVendas.length;
        index++
      ) {
        const element = this.ListaPromocoesCarteirasVendas[index];
        if (element != null) {
          const p = this.Carteiras.find(
            (x) => x.CarteiraVendaID == element.CarteiraVendaID
          );
          if (p != null) {
            this.ListaPromocoesCarteirasVendas[index].Descricao = p.Descricao;
            const x = this.Carteiras.indexOf(p);
            this.Carteiras.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO CARTEIRAS DA LISTA CARTEIRAS

    //REMOVENDO SEGMENTOS DA LISTA SEGMENTOS
    if (this.ListaPromocoesSegmentos.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesSegmentos.length;
        index++
      ) {
        const element = this.ListaPromocoesSegmentos[index];
        if (element != null) {
          const p = this.Segmentos.find(
            (x) => x.SegmentoID == element.SegmentoID
          );
          if (p != null) {
            this.ListaPromocoesSegmentos[index].Nome = p.Nome;
            const x = this.Segmentos.indexOf(p);
            this.Segmentos.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO SEGMENTOS DA LISTA SEGMENTOS

    //REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE
    if (this.ListaPromocoesPapeisClientes.length > 0) {
      for (
        let index = 0;
        index < this.ListaPromocoesPapeisClientes.length;
        index++
      ) {
        const element = this.ListaPromocoesPapeisClientes[index];
        if (element != null) {
          const p = this.PapeisClientes.find(
            (x) => x.PapelClienteID == element.PapelClienteID
          );
          if (p != null) {
            this.ListaPromocoesPapeisClientes[index].Descricao = p.Descricao;
            const x = this.PapeisClientes.indexOf(p);
            this.PapeisClientes.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE

    //#endregion

    this.dataDialog = true;
  }

  deleteData(data: ParceiroPromocaoModel) {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja excluir ' + data.Descricao + '?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.service.deleteParceiroPromocao(data.PromocaoID);
        this.data = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Produto excluído com sucesso!',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
    this.data = {};
    this.dataDesconto = {};
    this.promocoesDescontos = [];
  }

  loadingSave: boolean = false;
  async saveData() {
    this.ListaPromocoesProdutos[0] = {
      ...this.ListaPromocoesProdutos[0],
      QuantidadeMaxima:
        this.Maximo || this.ListaPromocoesProdutos[0].QuantidadeMaxima,
      QuantidadeMinima:
        this.Minimo || this.ListaPromocoesProdutos[0].QuantidadeMinima,
    };
    try {
      if (
        this.ListaPromocoesProdutos.length == 0 ||
        this.ListaPromocoesProdutos == null
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Não é possível incluir/alterar sem produto!',
        });
        return;
      }

      this.submitted = true;
      this.loadingSave = true;

      let data = {};

      let arrayPromocoesProdutos: any[];
      let arrayPromocoesUnidades: any[];
      let arrayPromocoesCidades: any[];
      let arrayPromocoesSupervisores: any[];
      let arrayPromocoesCarteiras: any[];
      let arrayPromocoesSegmentos: any[];
      let arrayPromocoesPapeisClientes: any[];
      let arrayPromocoesDesconto: any[];

      arrayPromocoesProdutos = [];
      arrayPromocoesUnidades = [];
      arrayPromocoesCidades = [];
      arrayPromocoesSupervisores = [];
      arrayPromocoesCarteiras = [];
      arrayPromocoesSegmentos = [];
      arrayPromocoesPapeisClientes = [];
      arrayPromocoesDesconto = [];

      if (this.data.PromocaoID != undefined) {
        console.log('editando');

        //#region  LISTA ALTERAÇÃO

        //REMOVENDO PRODUTOS DA LISTA PRODUTOS

        if (this.ListaPromocoesProdutos.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesProdutos.length;
            index++
          ) {
            const element = this.ListaPromocoesProdutos[index];
            if (element != null) {
              let dt: PromocaoProdutosModel = {
                PromocaoID: this.data.PromocaoID,
                ProdutoID: this.FormataString(element.ProdutoID),
                PromocaoProdutoID:
                  this.ListaPromocoesProdutos[index].PromocaoProdutoID !=
                  undefined
                    ? this.ListaPromocoesProdutos[index].PromocaoProdutoID
                    : UUID.UUID(),
                QuantidadeMinima:
                  element.QuantidadeMinima != undefined
                    ? element.QuantidadeMinima
                    : 0,
                QuantidadeMaxima:
                  element.QuantidadeMaxima != undefined
                    ? element.QuantidadeMaxima
                    : 0,
              };

              arrayPromocoesProdutos.push(dt);
            }
          }
        }
        //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

        //REMOVENDO UNIDADES DA LISTA UNIDADES
        if (this.ListaPromocoesUnidades.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesUnidades.length;
            index++
          ) {
            const element = this.ListaPromocoesUnidades[index];

            if (element != null) {
              let dt: PromocaoUnidadeModel = {
                PromocaoID: this.data.PromocaoID,
                PromocaoUnidadeID:
                  this.ListaPromocoesUnidades[index].PromocaoUnidadeID !=
                  undefined
                    ? this.ListaPromocoesUnidades[index].PromocaoUnidadeID
                    : UUID.UUID(),
                UnidadeID: this.FormataString(element.UnidadeID),
              };

              arrayPromocoesUnidades.push(dt);
            }
          }
        }
        //FIM REMOVENDO UNIDADES DA LISTA UNIDADES

        //REMOVENDO CIDADES DA LISTA CIDADES
        if (this.ListaPromocoesCidades.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesCidades.length;
            index++
          ) {
            const element = this.ListaPromocoesCidades[index];
            if (element != null) {
              let dt: PromocaoCidadesModel = {
                PromocaoID: this.data.PromocaoID,
                CidadeID: this.FormataString(element.CidadeID),
                PromocaoCidadeID:
                  this.ListaPromocoesCidades[index].PromocaoCidadeID !=
                  undefined
                    ? this.ListaPromocoesCidades[index].PromocaoCidadeID
                    : UUID.UUID(),
              };

              arrayPromocoesCidades.push(dt);
            }
          }
        }
        //FIM REMOVENDO CIDADES DA LISTA CIDADES

        //REMOVENDO SUPERVISOR DA LISTA SUPERVISOR
        if (this.ListaPromocoesSupervisores.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesSupervisores.length;
            index++
          ) {
            const element = this.ListaPromocoesSupervisores[index];
            if (element != null) {
              let dt: PromocaoSupervisorModel = {
                PromocaoID: this.data.PromocaoID,
                SupervisorID: this.FormataString(element.SupervisorID),
                PromocaoSupervisorID:
                  this.ListaPromocoesSupervisores[index].PromocaoSupervisorID !=
                  undefined
                    ? this.ListaPromocoesSupervisores[index]
                        .PromocaoSupervisorID
                    : UUID.UUID(),
              };

              arrayPromocoesSupervisores.push(dt);
            }
          }
        }
        //FIM REMOVENDO SUPERVISOR DA LISTA SUPERVISOR

        //REMOVENDO CARTEIRAS DA LISTA CARTEIRAS
        if (this.ListaPromocoesCarteirasVendas.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesCarteirasVendas.length;
            index++
          ) {
            const element = this.ListaPromocoesCarteirasVendas[index];
            if (element != null) {
              let dt: PromocaoCarteiraVendasModel = {
                PromocaoID: this.data.PromocaoID,
                CarteiraVendaID: this.FormataString(element.CarteiraVendaID),
                PromocaoCarteiraVendaID:
                  this.ListaPromocoesCarteirasVendas[index]
                    .PromocaoCarteiraVendaID != undefined
                    ? this.ListaPromocoesCarteirasVendas[index]
                        .PromocaoCarteiraVendaID
                    : UUID.UUID(),
              };

              arrayPromocoesCarteiras.push(dt);
            }
          }
        }
        //FIM REMOVENDO CARTEIRAS DA LISTA CARTEIRAS

        //REMOVENDO SEGMENTOS DA LISTA SEGMENTOS
        if (this.ListaPromocoesSegmentos.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesSegmentos.length;
            index++
          ) {
            const element = this.ListaPromocoesSegmentos[index];
            if (element != null) {
              let dt: PromocaoSegmentoModel = {
                PromocaoID: this.data.PromocaoID,
                SegmentoID: this.FormataString(element.SegmentoID),
                PromocaoSegmentoID:
                  this.ListaPromocoesSegmentos[index].PromocaoSegmentoID !=
                  undefined
                    ? this.ListaPromocoesSegmentos[index].PromocaoSegmentoID
                    : UUID.UUID(),
              };

              arrayPromocoesSegmentos.push(dt);
            }
          }
        }
        //FIM REMOVENDO SEGMENTOS DA LISTA SEGMENTOS

        //REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE
        if (this.ListaPromocoesPapeisClientes.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesPapeisClientes.length;
            index++
          ) {
            const element = this.ListaPromocoesPapeisClientes[index];
            if (element != null) {
              let dt: PromocaoPapeisClienteModel = {
                PromocaoID: this.data.PromocaoID,
                PapelClienteID: this.FormataString(element.PapelClienteID),
                PromocaoPapelClienteID:
                  this.ListaPromocoesPapeisClientes[index]
                    .PromocaoPapelClienteID != undefined
                    ? this.ListaPromocoesPapeisClientes[index]
                        .PromocaoPapelClienteID
                    : UUID.UUID(),
              };

              arrayPromocoesPapeisClientes.push(dt);
            }
          }
        }
        //FIM REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE

        //REMOVENDO DESCONTO

        if (this.promocoesDescontos.length > 0) {
          for (let index = 0; index < this.promocoesDescontos.length; index++) {
            const element = this.promocoesDescontos[index];
            if (element != null) {
              let dt: PromocaoDescontoModel = {
                PromocaoID: this.data.PromocaoID,
                PromocaoDescontoID:
                  this.promocoesDescontos[index].PromocaoDescontoID != undefined
                    ? this.promocoesDescontos[index].PromocaoDescontoID
                    : UUID.UUID(),
                Percentual:
                  element.Percentual != undefined ? element.Percentual : 0,
                Quantidade:
                  element.Quantidade != undefined ? element.Quantidade : 0,
              };

              arrayPromocoesDesconto.push(dt);
            }
          }
        }
        //FIM REMOVENDO DESCONTO

        //#endregion

        data['PromocaoID'] = this.data.PromocaoID;
        data['DataFinal'] = this.data.DataFinal;
        data['DataInicio'] = this.data.DataInicio;
        data['Descricao'] = this.FormataString(this.data.Descricao);
        data['DescricaoAplicativo'] = this.FormataString(
          this.data.DescricaoAplicativo
        );
        data['Banner'] = this.FormataString(this.data.Banner);
        data['PromocaoMktPlace'] = this.FormataNumber(
          this.data.PromocaoMktPlace
        );
        data['Ativo'] = this.FormataBolean(this.data.Ativo);
        data['Destacar'] = this.FormataBolean(this.data.Destacar);
        data['DestacarNoProduto'] = this.FormataBolean(
          this.data.DestacarNoProduto
        );

        data['PromocoesCarteirasVendas'] = arrayPromocoesCarteiras;
        data['PromocoesCidades'] = arrayPromocoesCidades;
        data['PromocoesDescontos'] = arrayPromocoesDesconto;
        data['PromocoesProdutos'] = arrayPromocoesProdutos;
        data['PromocoesSegmentos'] = arrayPromocoesSegmentos;
        data['PromocoesSupervisores'] = arrayPromocoesSupervisores;
        data['PromocoesUnidades'] = arrayPromocoesUnidades;
        data['PromocoesPapeisClientes'] = arrayPromocoesPapeisClientes;

        this.service.updateParceiroPromocao(this.data.PromocaoID, data);

        let result = await this.service.PostPromocaoInserir(data);
        if (result == true) {
          this.data.isEdit = false;
          this.dataDialog = false;
          this.loadingSave = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Registro alterado com sucesso!',
            life: 3000,
          });
        } else {
          this.loadingSave = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Não foi possível alterar registro! \n' + result,
          });
        }
      } else {
        //#region  LISTA INCLUSAO
        console.log('Novo');

        //REMOVENDO PRODUTOS DA LISTA PRODUTOS

        if (this.ListaPromocoesProdutos.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesProdutos.length;
            index++
          ) {
            const element = this.ListaPromocoesProdutos[index];
            if (element != null) {
              let dt: PromocaoProdutosModel = {
                PromocaoID: this.Guide,
                ProdutoID: this.FormataString(element.ProdutoID),
                PromocaoProdutoID: UUID.UUID(),
                QuantidadeMinima:
                  element.QuantidadeMinima != undefined
                    ? element.QuantidadeMinima
                    : 0,
                QuantidadeMaxima:
                  element.QuantidadeMaxima != undefined
                    ? element.QuantidadeMaxima
                    : 0,
              };

              arrayPromocoesProdutos.push(dt);
            }
          }
        }
        //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

        //REMOVENDO UNIDADES DA LISTA UNIDADES
        if (this.ListaPromocoesUnidades.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesUnidades.length;
            index++
          ) {
            const element = this.ListaPromocoesUnidades[index];

            if (element != null) {
              let dt: PromocaoUnidadeModel = {
                PromocaoID: this.Guide,
                PromocaoUnidadeID: UUID.UUID(),
                UnidadeID: this.FormataString(element.UnidadeID),
              };

              arrayPromocoesUnidades.push(dt);
            }
          }
        }
        //FIM REMOVENDO UNIDADES DA LISTA UNIDADES

        //REMOVENDO CIDADES DA LISTA CIDADES
        if (this.ListaPromocoesCidades.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesCidades.length;
            index++
          ) {
            const element = this.ListaPromocoesCidades[index];
            if (element != null) {
              let dt: PromocaoCidadesModel = {
                PromocaoID: this.Guide,
                CidadeID: this.FormataString(element.CidadeID),
                PromocaoCidadeID: UUID.UUID(),
              };

              arrayPromocoesCidades.push(dt);
            }
          }
        }
        //FIM REMOVENDO CIDADES DA LISTA CIDADES

        //REMOVENDO SUPERVISOR DA LISTA SUPERVISOR
        if (this.ListaPromocoesSupervisores.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesSupervisores.length;
            index++
          ) {
            const element = this.ListaPromocoesSupervisores[index];
            if (element != null) {
              let dt: PromocaoSupervisorModel = {
                PromocaoID: this.Guide,
                SupervisorID: this.FormataString(element.SupervisorID),
                PromocaoSupervisorID: UUID.UUID(),
              };

              arrayPromocoesSupervisores.push(dt);
            }
          }
        }
        //FIM REMOVENDO SUPERVISOR DA LISTA SUPERVISOR

        //REMOVENDO CARTEIRAS DA LISTA CARTEIRAS
        if (this.ListaPromocoesCarteirasVendas.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesCarteirasVendas.length;
            index++
          ) {
            const element = this.ListaPromocoesCarteirasVendas[index];
            if (element != null) {
              let dt: PromocaoCarteiraVendasModel = {
                PromocaoID: this.Guide,
                CarteiraVendaID: this.FormataString(element.CarteiraVendaID),
                PromocaoCarteiraVendaID: UUID.UUID(),
              };

              arrayPromocoesCarteiras.push(dt);
            }
          }
        }
        //FIM REMOVENDO CARTEIRAS DA LISTA CARTEIRAS

        //REMOVENDO SEGMENTOS DA LISTA SEGMENTOS
        if (this.ListaPromocoesSegmentos.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesSegmentos.length;
            index++
          ) {
            const element = this.ListaPromocoesSegmentos[index];
            if (element != null) {
              let dt: PromocaoSegmentoModel = {
                PromocaoID: this.Guide,
                SegmentoID: this.FormataString(element.SegmentoID),
                PromocaoSegmentoID: UUID.UUID(),
              };

              arrayPromocoesSegmentos.push(dt);
            }
          }
        }
        //FIM REMOVENDO SEGMENTOS DA LISTA SEGMENTOS

        //REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE
        if (this.ListaPromocoesPapeisClientes.length > 0) {
          for (
            let index = 0;
            index < this.ListaPromocoesPapeisClientes.length;
            index++
          ) {
            const element = this.ListaPromocoesPapeisClientes[index];
            if (element != null) {
              let dt: PromocaoPapeisClienteModel = {
                PromocaoID: this.Guide,
                PapelClienteID: this.FormataString(element.PapelClienteID),
                PromocaoPapelClienteID: UUID.UUID(),
              };

              arrayPromocoesPapeisClientes.push(dt);
            }
          }
        }
        //FIM REMOVENDO PAPEIS CLIENTE DA LISTA PAPEIS CLIENTE

        //REMOVENDO DESCONTO

        if (this.promocoesDescontos.length > 0) {
          for (let index = 0; index < this.promocoesDescontos.length; index++) {
            const element = this.promocoesDescontos[index];
            if (element != null) {
              let dt: PromocaoDescontoModel = {
                PromocaoID: this.Guide,
                PromocaoDescontoID: UUID.UUID(),
                Percentual:
                  element.Percentual != undefined ? element.Percentual : 0,
                Quantidade:
                  element.Quantidade != undefined ? element.Quantidade : 0,
              };

              arrayPromocoesDesconto.push(dt);
            }
          }
        }
        //FIM REMOVENDO DESCONTO
        //#endregion

        data['PromocaoID'] = this.Guide;
        //2021-08-20 00:00:00
        data['DataFinal'] = this.data.DataFinal;
        data['DataInicio'] = this.data.DataInicio;
        data['Descricao'] = this.FormataString(this.data.Descricao);
        data['DescricaoAplicativo'] = this.FormataString(
          this.data.DescricaoAplicativo
        );
        data['Banner'] = this.FormataString(this.data.Banner);
        data['PromocaoMktPlace'] = this.FormataNumber(
          this.data.PromocaoMktPlace
        );
        data['Ativo'] = this.FormataBolean(this.data.Ativo);
        data['Destacar'] = this.FormataBolean(this.data.Destacar);
        data['DestacarNoProduto'] = this.FormataBolean(
          this.data.DestacarNoProduto
        );

        data['PromocoesCarteirasVendas'] = arrayPromocoesCarteiras;
        data['PromocoesCidades'] = arrayPromocoesCidades;
        data['PromocoesDescontos'] = arrayPromocoesDesconto;
        data['PromocoesProdutos'] = arrayPromocoesProdutos;
        data['PromocoesSegmentos'] = arrayPromocoesSegmentos;
        data['PromocoesSupervisores'] = arrayPromocoesSupervisores;
        data['PromocoesUnidades'] = arrayPromocoesUnidades;
        data['PromocoesPapeisClientes'] = arrayPromocoesPapeisClientes;

        this.service.createParceiroPromocao(data, this.Guide);

        let result = await this.service.PostPromocaoInserir(data);

        if (result == true) {
          this.dataDialog = false;
          this.loadingSave = false;

          this.data.Banner = '';
          this.data.DataFinal = new Date();
          this.data.DataInicio = new Date();
          this.data.Descricao = '';
          this.data.DescricaoAplicativo = '';

          this.ListaPromocoesProdutos = [];
          this.ListaPromocoesUnidades = [];
          this.ListaPromocoesCidades = [];
          this.ListaPromocoesSupervisores = [];
          this.ListaPromocoesCarteirasVendas = [];
          this.ListaPromocoesSegmentos = [];
          this.ListaPromocoesPapeisClientes = [];
          this.promocoesDescontos = [];

          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Registro criado com sucesso!',
            life: 3000,
          });
          this.data = {};
          this.dataDesconto = {};
        } else {
          this.loadingSave = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Não foi possível criar o registro! \n' + result,
          });
        }
      }
    } catch (error) {
      console.log(error);

      this.loadingSave = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não foi possível fazer a requisição! \n' + error,
      });
    }
  }

  //ADICIONAR E REMOVER DESCONTO
  selecionadoDesconto: boolean = false;
  indexDesconto: number = -1;

  AdicionarDesconto() {
    if (this.selecionadoDesconto == true && this.indexDesconto > -1) {
      this.promocoesDescontos[this.indexDesconto] = this.dataDesconto;
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Registro alterado!',
        life: 3000,
      });

      this.selecionadoDesconto = false;
      this.indexDesconto = -1;
    } else {
      //this.product.id = this.createId();
      this.promocoesDescontos.push(this.dataDesconto);
      this.selecionadoDesconto = false;
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Registro criado!',
        life: 3000,
      });
    }

    //this.promocoesDescontos = [...this.promocoesDescontos];
    this.dataDesconto = {};
  }

  findIndexById(data: PromocaoDescontoModel): number {
    return this.promocoesDescontos.indexOf(data);
  }

  EditarDesconto(product: PromocaoDescontoModel) {
    this.selecionadoDesconto = true;
    this.indexDesconto = this.findIndexById(product);
    this.dataDesconto = { ...product };
  }

  RemoverDesconto(product: PromocaoDescontoModel) {
    this.confirmationService.confirm({
      message: 'Deseja remover esse item ?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const p = this.promocoesDescontos.find((x) => x == product);
        if (p != null) {
          const x = this.promocoesDescontos.indexOf(p);
          this.promocoesDescontos.splice(x, 1);
        }

        this.dataDesconto = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Deletado com sucesso!',
          life: 3000,
        });
      },
    });
  }
  //FIM ADICIONAR E REMOVER DESCONTO

  // PRODUTOS PICKLIST
  onMoveToTargetProdutos(event) {
    if (event.items[0].Ativo == 'N') {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Produto inativo.',
      });
      const x = this.ListaPromocoesProdutos.indexOf(event.items[0]);
      this.ListaPromocoesProdutos.splice(x, 1);
      this.Produtos.push(event.items[0]);
      return;
    }

    if (this.Minimo > this.Maximo) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'A quantidade Mínima não pode ser maior que a Máxima.',
      });
      const x = this.ListaPromocoesProdutos.indexOf(event.items[0]);
      this.ListaPromocoesProdutos.splice(x, 1);
      this.Produtos.push(event.items[0]);
      return;
    }
    event.items[0].QuantidadeMinima = this.Minimo;
    event.items[0].QuantidadeMaxima = this.Maximo;

    this.Minimo = null;
    this.Maximo = null;
  }

  onMoveAllToTargetProdutos(event) {
    console.log('TARGET ALL');
    console.log(event);
  }

  onMoveToSourceProdutos(event) {
    console.log('SOURCE');
    console.log(event);
  }

  onMoveAllToSourceProdutos(event) {
    console.log('SOURCE ALL');
    console.log(event);
  }
  /// FIMPRODUTOS PICKLIST

  atualizarPromocoes() {
    this.loading = true;

    this.service.atualizarPromocoes().subscribe(
      (data: any) => {
        if (data.sucesso == true) {
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Lista atualizada!',
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Erro ao atualizar!\n' + data.mensagem,
          });
        }
        this.loading = false;
      },
      (err) => {
        console.log({ err });

        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Erro ao atualizar!\n' + err.error.mensagem,
        });
        this.loading = false;
      }
    );
  }

  FiltrarCampos() {
    this.datas = this.datas.filter((v) => {
      // if (v.DataInicio >= new Date(this.PeriodoDataInicial) && v.DataFinal <= new Date(this.PeriodoDataFinal)){
      //   return true;
      // } else {
      //   return false;
      // }
      if (!this.FormataString(v.Descricao).indexOf(this.FiltroDescricao)) {
        return true;
      } else {
        return false;
      }
    });
  }
}
