<p-toast></p-toast>
<p-card>
    <div class="card">
        <br />
        <button pButton pRipple label="Novo" icon="pi pi-plus" class="p-button-sm p-button-success p-mr-2"
            (click)="openNew()" [style]="{'margin-bottom':'15px'}"></button>

        <!-- <p-toolbar styleClass="p-mb-4" [style]="{'background-color':'#427976'}">
        <ng-template pTemplate="left">
           
        </ng-template>
        <ng-template pTemplate="right">
        </ng-template>
    </p-toolbar> -->

        <p-table #dt [value]="usuarios" [rows]="50" [paginator]="true" [globalFilterFields]="['login','uid','usuario']"
            [(selection)]="selectedUsuarios" [rowHover]="true" dataKey="cod_usuario"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registro(s)"
            [showCurrentPageReport]="true">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h3 class="p-m-0">Gerenciamento de Usuários</h3>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Pesquisar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="uid">Uid<p-sortIcon field="uid"></p-sortIcon></th>
                    <th pSortableColumn="login">E-mail <p-sortIcon field="login"></p-sortIcon></th>
                    <th pSortableColumn="usuario">Usuário <p-sortIcon field="usuario"></p-sortIcon></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-usuario>
                <tr>
                    <td>{{usuario.uid}}</td>
                    <td>{{usuario.login}}</td>
                    <td>{{usuario.usuario}}</td>
                    <td style="text-align: right;">
                        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2"
                            pTooltip="Editar" (click)="editData(usuario)"></button>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            pTooltip="Deletar" (click)="deleteData(usuario)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    No total, existem {{usuarios ? usuarios.length : 0 }} usuarios.
                </div>
            </ng-template>
        </p-table>
    </div>
</p-card>

<!-- ******************************************CADASTRO********************************** -->
<p-dialog [(visible)]="usuarioDialog" [style]="{width: '450px'}" header="Detalhe Usuário" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="usuario">Usuário</label>
            <input type="usuario" pInputText id="usuario" [(ngModel)]="usuario.usuario" required autofocus />
            <small class="p-error" *ngIf="submitted && !usuario.usuario">Usuário é obrigatório.</small>
        </div>
        <div class="p-field">
            <label for="login">Email</label>
            <input type="email" pInputText id="login" [(ngModel)]="usuario.login" required />
            <small class="p-error" *ngIf="submitted && !usuario.login">Email é obrigatório.</small>
        </div>
        <div class="p-field">
            <label for="senha">Senha</label>
            <input type="password" pInputText id="senha" [(ngModel)]="usuario.senha" required />

        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <br />
        <button pButton pRipple type="button" label="GRAVAR" [disabled]="!usuario.login || !usuario.senha"
            style="width: 100px;float: left;" class="p-button-raised p-button-success" (click)="saveData()">
        </button>

        <!--p-progressSpinner [style]="{'width':'20px','height':'20px','float':'left','margin-top':'5px'}"
            *ngIf="loadingSave" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner-->

        <button pButton pRipple type="button" label="RETORNAR" style="width: 100px;"
            class="p-button-raised p-button-danger" (click)="hideDialog()">
        </button>

        <p-progressBar mode="indeterminate"
            [style]="{'height': '6px', 'margin-top': '5px', 'color': '#67d66f', 'background': 'white'}"
            *ngIf="loadingSave"></p-progressBar>
    </ng-template>
    <!-- <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-sm p-button-danger" (click)="hideDialog()"></button>
        <button pButton pRipple label="Gravar" icon="pi pi-check" class="p-button-sm p-button-success" [disabled]="!usuario.login || !usuario.senha"  (click)="saveData()"></button>
    </ng-template> -->
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>