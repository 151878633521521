import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { UUID } from 'angular2-uuid';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import { Observable } from 'rxjs';
import { FirebaseService } from '../app.login.firebase.service';
import { ProdutoModel } from '../produto/produto.model';
import { ParceiroCupomService } from './cupom.service';
import { UnidadeModel } from 'src/app/models/unidade.model';
import { CidadeModel } from 'src/app/models/cidade.model';
import { SupervisorModel } from 'src/app/models/supervisor.model';
import { CarteiraModel } from 'src/app/models/carteira.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { PapeisClienteModel } from 'src/app/models/papeiscliente.model';
import { ICupom } from './cupom.model';

@Component({
  selector: 'app-cupom',
  templateUrl: './cupom.component.html',
  styleUrls: ['./cupom.component.scss'],
})
export class CupomComponent implements OnInit {
  show = false;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  Produtos: ProdutoModel[] = JSON.parse(localStorage.getItem('Produtos'));
  Categorias: any[] = [];
  Unidades: UnidadeModel[];
  Cidades: CidadeModel[];
  Supervisores: SupervisorModel[];
  Carteiras: CarteiraModel[];
  Segmentos: SegmentoModel[];
  PapeisClientes: PapeisClienteModel[];

  PeriodoDataInicial: Date = new Date(2000, 2, 1);
  PeriodoDataFinal: Date = new Date();
  FiltroDescricao: string;

  dataDialog: boolean;

  datas: any;
  lista: any[];
  listaTipo = ['Produto'];
  listaTipoDesconto = ['Porcentagem', 'Valor'];
  listaTipoLimite = ['Nenhum', 'Caixa', 'Valor'];
  loading: boolean = true;
  data: ICupom;
  selectedDatas: any[];
  submitted: boolean;
  Guide: string;

  ListaCupomProdutos: any[];

  private basePath = 'cupom/';

  profileUrl: any;

  constructor(
    private service: ParceiroCupomService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private config: PrimeNGConfig
  ) {
    this.ListaCupomProdutos = [];

    this.config.setTranslation({
      accept: 'Aceitar',
      reject: 'Cancelar',
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      //translations
    });

    this.pesquisar();
  }

  pesquisar() {
    try {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.service.readParceiroCupom().subscribe((data) => {
            this.datas = data.map((e) => {
              const dataValues = e.payload.doc.data();

              return {
                id: e.payload.doc.id,
                isEdit: false,
                DataFinal: dataValues['DataFinal'],
                DataInicio: dataValues['DataInicio'],
                Descricao: dataValues['Descricao'],
                CupomID: dataValues['CupomID'],
                CupomProdutos: dataValues['CupomProdutos'],
                Tipo: dataValues['Tipo'],
                Desconto: dataValues['Desconto'],
                LimiteCupom: dataValues['LimiteCupom'],
                CodigoCupom: dataValues['CodigoCupom'],
                NomeCupom: dataValues['NomeCupom'],
                TipoLimite: dataValues['TipoLimite'],
                QuantidadeLimite: dataValues['QuantidadeLimite'],
                TipoDesconto: dataValues['TipoDesconto'],
                Status: dataValues['Status'],
                APP: dataValues['APP'],
                WEB: dataValues['WEB'],
                PrimeiraCompra: dataValues['PrimeiraCompra'],
                CupomVisivel: dataValues['CupomVisivel'],
              };
            });
          });
          //CATEGORIAS
          this.service.readCategoria().subscribe((data) => {
            this.Categorias = data.map((e) => {
              const dataValues = e.payload.doc.data();

              return {
                id: e.payload.doc.id,
                CategoriaID: dataValues['CategoriaID'],
                Descricao: dataValues['Descricao'],
                Status: dataValues['Status'],
              };
            });
          });
          // FIM CATEGORIAS
        } else {
          this.datas = new Observable<any[]>();
        }
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  carregarListas() {
    this.ListaCupomProdutos = [];

    //#region LISTA
    this.Produtos = JSON.parse(localStorage.getItem('Produtos')).filter(
      (data) => data.Ativo.toLowerCase() === 's'
    );
    this.Unidades = [];
    this.Cidades = [];
    this.Supervisores = [];
    this.Carteiras = [];
    this.Segmentos = [];
    this.PapeisClientes = [];
    //#endregion
  }

  ngOnDestroy() {}

  async ngOnInit() {
    this.data = {};
  }

  openNew() {
    this.data = {};
    this.Guide = UUID.UUID();
    this.ListaCupomProdutos = [];

    this.carregarListas();
    this.data = { TipoLimite: 'Nenhum', LimiteCupom: 0 };

    this.submitted = false;
    this.dataDialog = true;
  }

  fb: any;

  FormataStringData(data) {
    var dia = data.split('/')[0];
    var mes = data.split('/')[1];
    var ano = data.split('/')[2];

    return (
      ano +
      '-' +
      ('0' + mes).slice(-2) +
      '-' +
      ('0' + dia).slice(-2) +
      ' 00:00:00'
    );
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  FormataString(data) {
    if (
      data == null ||
      data == undefined ||
      data == 'null' ||
      data == 'undefined'
    ) {
      return '';
    }

    return data;
  }

  FormataNumber(data) {
    if (data == null || data == undefined) {
      return 0;
    }
    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  FormataBolean(data) {
    if (data == null || data == undefined) {
      return false;
    }
    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  convert(str) {
    var date = new Date(str);
    var mnth = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);

    return [date.getFullYear(), mnth, day].join('/');
  }

  formatarDataExtenso(data) {
    // Meses possíveis
    var meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];
    // Dias possíveis
    var diasSemana = [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ];
    // Partes da data informada
    var dia = data.getDate();
    var mes = data.getMonth();
    var ano = data.getYear();

    var hora = new Date().getHours();
    var minu = new Date().getMinutes();
    var segu = new Date().getSeconds();
    // Resultado
    var extenso = '';
    //DataFinal
    //5 de agosto de 2021 21:00:00 UTC-3
    //DataInicio
    //1 de agosto de 2021 21:00:00 UTC-3

    // Para o navegador Netscape o ano começa em 1900
    if (navigator.appName == 'Netscape') {
      ano = ano + 1900;
    }

    //return extenso = diasSemana[dias] + ', ' + dia + ' de ' + meses[mes] + ' de ' + ano;

    return (extenso =
      dia +
      ' de ' +
      meses[mes] +
      ' de ' +
      ano +
      ' ' +
      ('0' + hora).slice(-2) +
      ':' +
      ('0' + minu).slice(-2) +
      ':' +
      ('0' + segu).slice(-2) +
      ' UTC-3');
  }

  editData(data: any) {
    this.loadingSave = false;

    this.data = { ...data };

    if (data.DataInicio) {
      let DI: any = data.DataInicio;
      this.data.DataInicio = new Date(this.convert(new Date(DI.toDate())));
    } else {
      this.data.DataInicio = null;
    }

    if (data.DataFinal) {
      let DF: any = data.DataFinal;
      this.data.DataFinal = new Date(this.convert(new Date(DF.toDate())));
    } else {
      this.data.DataFinal = null;
    }

    //#region  LISTAS

    this.ListaCupomProdutos = [];

    this.ListaCupomProdutos = [...data.CupomProdutos];

    data.isEdit = true;
    //REMOVENDO PRODUTOS DA LISTA PRODUTOS
    if (this.ListaCupomProdutos.length > 0) {
      for (let index = 0; index < this.ListaCupomProdutos.length; index++) {
        const element = this.ListaCupomProdutos[index];
        if (element != null) {
          const p = this.Produtos.find((x) => x.ProdutoID == element.ProdutoID);
          if (p != null) {
            this.ListaCupomProdutos[index].Descricao = p.Descricao;
            const x = this.Produtos.indexOf(p);
            this.Produtos.splice(x, 1);
          }
        }
      }
    }
    //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

    //#endregion

    this.dataDialog = true;
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
    this.data = {};
  }

  deleteData({ CupomID, CodigoCupom }: ICupom) {
    this.confirmationService.confirm({
      message:
        'Tem certeza de que deseja excluir o cupom "' + CodigoCupom + '"?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.service.deleteCupom(CupomID);
        this.data = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Cupom excluído com sucesso!',
          life: 3000,
        });
      },
    });
  }

  loadingSave: boolean = false;
  async saveData() {
    try {
      if (
        this.data.Tipo === 'Produtos' &&
        (this.ListaCupomProdutos.length == 0 || this.ListaCupomProdutos == null)
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail: 'Não é possível incluir/alterar sem produto ou categoria!',
        });
        return;
      }

      if (this.data.DataFinal < this.data.DataInicio) {
        this.messageService.add({
          severity: 'error',
          summary: 'Informação',
          detail:
            'Não é possível incluir/alterar cupom, pois a Data final é inferior a Data Inicial!',
        });
        return;
      }

      this.submitted = true;
      this.loadingSave = true;

      let data = {};

      let arrayCupomProdutos: any[] = [];
      let arrayCupomCategorias: any[] = [];
      let arrayCupomUnidades: any[] = [];
      let arrayCupomCidades: any[] = [];
      let arrayCupomSupervisores: any[] = [];
      let arrayCupomCarteiras: any[] = [];
      let arrayCupomSegmentos: any[] = [];
      let arrayCupomPapeisClientes: any[] = [];

      if (this.data.CupomID != undefined) {
        //#region  LISTA ALTERAÇÃO

        //REMOVENDO PRODUTOS DA LISTA PRODUTOS

        if (this.ListaCupomProdutos.length > 0) {
          for (let index = 0; index < this.ListaCupomProdutos.length; index++) {
            const element = this.ListaCupomProdutos[index];
            if (element != null) {
              let dt: any = {
                CupomID: this.data.CupomID,
                ProdutoID: this.FormataString(element.ProdutoID),
                CupomProdutoID:
                  this.ListaCupomProdutos[index].CupomProdutoID != undefined
                    ? this.ListaCupomProdutos[index].CupomProdutoID
                    : UUID.UUID(),
              };

              arrayCupomProdutos.push(dt);
            }
          }
        }
        //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

        //#endregion

        data['CupomID'] = this.data.CupomID;
        data['DataFinal'] = this.data.DataFinal;
        data['DataInicio'] = this.data.DataInicio;
        data['Descricao'] = this.FormataString(this.data.Descricao);
        data['CodigoCupom'] = this.data.CodigoCupom.trim();
        data['NomeCupom'] = this.data.NomeCupom.trim();
        data['TipoLimite'] = this.data.TipoLimite;
        data['QuantidadeLimite'] =
          this.data.TipoLimite !== 'Nenhum' ? this.data.QuantidadeLimite : 0;
        data['Desconto'] = this.data.Desconto;
        data['LimiteCupom'] = this.data.LimiteCupom;
        data['TipoDesconto'] = this.data.TipoDesconto;
        data['Status'] = !!this.data.Status;
        data['APP'] = !!this.data.APP;
        data['WEB'] = !!this.data.WEB;
        data['PrimeiraCompra'] = !!this.data.PrimeiraCompra;
        data['CupomVisivel'] = !!this.data.CupomVisivel;
        data['Tipo'] = this.FormataString(this.data.Tipo);
        data['CupomProdutos'] = arrayCupomProdutos;

        this.service.updateParceiroCupom(this.data.CupomID, data);

        this.data.isEdit = false;
        this.dataDialog = false;
        this.loadingSave = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Registro alterado com sucesso!',
          life: 3000,
        });
      } else {
        if (
          this.datas.find(
            (item) =>
              item.CodigoCupom.toLowerCase().trim() ===
              this.data.CodigoCupom.toLowerCase().trim()
          )
        ) {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail:
              'Não é possível incluir/alterar cupom, pois já existe um cupom com o código informado!',
          });
          return;
        }

        //#region  LISTA INCLUSAO

        //REMOVENDO PRODUTOS DA LISTA PRODUTOS

        if (this.ListaCupomProdutos.length > 0) {
          for (let index = 0; index < this.ListaCupomProdutos.length; index++) {
            const element = this.ListaCupomProdutos[index];
            if (element != null) {
              let dt: any = {
                CupomID: this.Guide,
                ProdutoID: this.FormataString(element.ProdutoID),
                CupomProdutoID: UUID.UUID(),
              };

              arrayCupomProdutos.push(dt);
            }
          }
        }
        //FIM REMOVENDO PRODUTOS DA LISTA PRODUTOS

        //#endregion

        data['CupomID'] = this.Guide;
        //2021-08-20 00:00:00
        data['DataFinal'] = this.data.DataFinal;
        data['DataInicio'] = this.data.DataInicio;
        data['Descricao'] = this.FormataString(this.data.Descricao);
        data['CodigoCupom'] = this.data.CodigoCupom.trim();
        data['NomeCupom'] = this.data.NomeCupom.trim();
        data['TipoLimite'] = this.data.TipoLimite;
        data['QuantidadeLimite'] =
          this.data.TipoLimite !== 'Nenhum' ? this.data.QuantidadeLimite : 0;
        data['Desconto'] = this.data.Desconto;
        data['LimiteCupom'] = this.data.LimiteCupom;
        data['TipoDesconto'] = this.data.TipoDesconto;
        data['Status'] = !!this.data.Status;
        data['APP'] = !!this.data.APP;
        data['WEB'] = !!this.data.WEB;
        data['PrimeiraCompra'] = !!this.data.PrimeiraCompra;
        data['CupomVisivel'] = !!this.data.CupomVisivel;
        data['Tipo'] = this.FormataString(this.data.Tipo);
        data['CupomProdutos'] = arrayCupomProdutos;

        this.service.createParceiroCupom(data, this.Guide);

        this.data.isEdit = false;
        this.dataDialog = false;
        this.loadingSave = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Registro alterado com sucesso!',
          life: 3000,
        });
      }
    } catch (error) {
      console.log(error);

      this.loadingSave = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não foi possível fazer a requisição! \n' + error,
      });
    }
  }

  // PRODUTOS PICKLIST
  onMoveToTargetProdutos(event) {
    if (event.items[0].Ativo == 'N') {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Produto inativo.',
      });
      const x = this.ListaCupomProdutos.indexOf(event.items[0]);
      this.ListaCupomProdutos.splice(x, 1);
      this.Produtos.push(event.items[0]);

      return;
    }

    return;
  }

  onMoveAllToTargetProdutos(event) {
    console.log('TARGET ALL');
    console.log(event);
  }

  onMoveToSourceProdutos(event) {
    console.log('SOURCE');
    console.log(event);
  }

  onMoveAllToSourceProdutos(event) {
    console.log('SOURCE ALL');
    console.log(event);
  }
  /// FIM PRODUTOS PICKLIST

  // CATEGORIAS PICKLIST
  onMoveToTargetCategorias(event) {
    console.log('TARGET ONE');
    console.log(event);
    return;
  }

  onMoveAllToTargetCategorias(event) {
    console.log('TARGET ALL');
    console.log(event);
  }

  onMoveToSourceCategorias(event) {
    console.log('SOURCE');
    console.log(event);
  }

  onMoveAllToSourceCategorias(event) {
    console.log('SOURCE ALL');
    console.log(event);
  }
  /// FIM CATEGORIAS PICKLIST

  FiltrarCampos() {
    this.datas = this.datas.filter((v) => {
      if (!this.FormataString(v.Descricao).indexOf(this.FiltroDescricao)) {
        return true;
      } else {
        return false;
      }
    });
  }
}
