<p-toast></p-toast>
<p-card>
    <div class="card">
        <!-- ***************************************NOVO CADASTRO*************************************** -->
        <br>
        <div style="text-align: right;">
            <button (click)="atualizarCategorias()" pButton pRipple type="button" label="Atualizar Categorias"
                class="p-button-sm"></button>
        </div>
        <br>
        <!-- ***************************************LISTA*************************************** -->
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>
        <p-table #dt [value]="datas" [rows]="50" [paginator]="true" [globalFilterFields]="['Descricao']"
            [(selection)]="selectedDatas" [rowHover]="true" dataKey="CategoriaID"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registro(s)"
            [showCurrentPageReport]="true">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h5 class="p-m-0">Gerenciamento de Categorias</h5>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                            placeholder="Pesquisar..." />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem"></th>
                    <th pSortableColumn="CategoriaID">Código <p-sortIcon field="CategoriaID"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Descricao">Descrição <p-sortIcon field="Descricao"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Sequencia">Sequência <p-sortIcon field="Sequencia"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-expanded="expanded">
                <tr>
                    <td>
                        <button type="button" pButton pRipple [pRowToggler]="data"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td>{{data.CategoriaID}}</td>
                    <td>{{data.Descricao}}</td>
                    <td>{{data.Sequencia}}</td>
                    <td style="text-align: right;">
                        <button pButton pRipple icon="pi pi-pencil" pTooltip="Editar"
                            class="p-button-sm p-button-rounded p-button-warning p-mr-2"
                            (click)="editData(data)"></button>
                        <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"></button> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-data>
                <tr>
                    <td colspan="5" style="background-color: rgb(247, 247, 247);">
                        <div class="p-p-3">
                            <p-table [value]="data.SubCategorias" dataKey="CategoriaID">
                                <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="CategoriaID">Código <p-sortIcon field="CategoriaID"></p-sortIcon>
                    </th>
                    <th pSortableColumn="Descricao">Descrição <p-sortIcon field="Descricao"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sub>
                <tr>
                    <td>{{sub.CategoriaID}}</td>
                    <td>{{sub.Descricao}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">Ainda não há Sub Categoria para esta Categoria.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
            No total, existem {{datas ? datas.length : 0 }} categoria(s).
        </div>
    </ng-template>
    </p-table>
    </div>
</p-card>

<!-- *************************************** CADASTRO *************************************** -->
<p-dialog [(visible)]="dataDialog" [style]="{'width':'95%','max-height':'100%', 'max-width':'1320px' }"
    header="Detalhe Categoria" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-card [style]="{'width': '100%', 'margin-top': '20px', 'margin-bottom': '30px'}">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center">

                    <div *ngIf="data.urlImage; else elseBlock">
                        <a (click)="showImageDialog()">
                            <img class="imagem-cad" [src]="data.urlImage" style="width: 20%;" />
                        </a>

                    </div>
                    <ng-template #elseBlock>
                        <div>
                            <img class="imagem-cad" src="assets/image.png" style="width: 150px;" />
                        </div>
                    </ng-template>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center; text-align: -webkit-center">
                    <label class="upload-arquivo" for="arquivo">Incluir/Alterar</label>
                    <input type="file" name="arquivo" id="arquivo" (change)="uploadCategoria($event)" pInputText
                        class="p-button-sm">
                </div>
            </div>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-lg-1">
                    <label for="CategoriaID">Código</label>
                    <input type="text" pInputText id="CategoriaID" [(ngModel)]="data.CategoriaID" required
                        [disabled]="true" />
                </div>
                <div class="p-col-12 p-md-12 p-lg-8">
                    <label for="Descricao">Descrição</label>
                    <input type="text" pInputText id="Descricao" [(ngModel)]="data.Descricao" required autofocus />
                    <small class="p-error" *ngIf="submitted && !data.Descricao">Descrição é obrigatório.</small>
                </div>
                <div class="p-col-12 p-md-12 p-lg-2">
                    <label>Sequência</label>
                    <p-dropdown [options]="sequencias" [(ngModel)]="data.Sequencia" placeholder="Sequência"
                        optionLabel="nome" optionValue="nome" [showClear]="true"></p-dropdown>
                </div>
                <div class="p-col-12 p-md-12 p-lg-1">
                    <label>Status</label><br />
                    <p-checkbox [(ngModel)]="data.Status" binary="true" inputId="binary">
                    </p-checkbox>
                </div>
            </div>
        </p-card>

        <p-card [style]="{'width': '100%'}">
            <div class="p-grid p-fluid" style="background-color: rgb(247, 247, 247);">
                <p-table *ngIf="data.SubCategorias; else SemSubCategoria" [value]="data.SubCategorias"
                    styleClass="p-datatable-striped" selectionMode="single">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Imagem</th>
                            <th>Ação</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-sub let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="sub">
                            <td>{{sub.CategoriaID}}</td>
                            <td>{{sub.Descricao}}</td>
                            <td>
                                <div *ngIf="sub.urlImage; else SubCategoriaImagem">
                                    <img class="imagemsub-cad" [src]="sub.urlImage" style="width: 20%;" />
                                </div>
                                <ng-template #SubCategoriaImagem>
                                    <div>
                                        <img src="assets/image.png" style="width: 50px;" />
                                    </div>
                                </ng-template>
                            </td>
                            <td>
                                <div>
                                    <button pButton pRipple icon="pi pi-pencil" pTooltip="Editar"
                                        class="p-button-sm p-button-rounded p-button-warning p-mr-2"
                                        (click)="editarSubCategoria(sub, rowIndex)"></button>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="4">Não há registros.</td>
                        </tr>
                    </ng-template>
                </p-table>

                <!-- <ng-template #SemSubCategoria>
                        <div style="text-align: center;background-color: white;width: 100%;padding: 20px;">
                            <p style="font-family: Verdana, Geneva, Tahoma, sans-serif;font-weight: bold;">SEM SUB
                                CATEGORIA
                            </p>
                            <img src="assets/subfolder.png" width="100" style="margin-top: 10px;" />
                        </div>
                    </ng-template> -->
            </div>
        </p-card>
    </ng-template>
    <ng-template pTemplate="footer">
        <br />
        <button pButton pRipple type="button" label="GRAVAR" [disabled]="loadingSave && !data.Descricao"
            style="width: 100px;float: left;" class="p-button-raised p-button-success" (click)="saveData()">
        </button>

        <!--p-progressSpinner [style]="{'width':'20px','height':'20px','float':'left','margin-top':'5px'}"
            *ngIf="loadingSave" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner-->

        <button pButton pRipple type="button" label="RETORNAR" style="width: 100px;"
            class="p-button-raised p-button-danger" (click)="hideDialog()">
        </button>

        <p-progressBar mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"
            *ngIf="loadingSave"></p-progressBar>
    </ng-template>
</p-dialog>

<!-- ***************************************SUB CATEGORIA ***************************************-->
<p-dialog [(visible)]="dataDialogSubCategoria" [style]="{'width':'60%','height':'80%' }" header="Detalhe Sub Categoria"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <p-card [style]="{'width': '100%', 'margin-top': '20px', 'margin-bottom': '30px'}">

            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center">
                    <div *ngIf="dataSubCategoria.urlImage; else SubCategoriaImagemEdit">
                        <a (click)="showSubImageDialog()">
                            <img class="imagem-cad" [src]="dataSubCategoria.urlImage" style="width: 20%;" />
                        </a>
                    </div>
                    <ng-template #SubCategoriaImagemEdit>
                        <div>
                            <img src="assets/image.png" style="width: 50px;" />
                        </div>
                    </ng-template>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center; text-align: -webkit-center">
                    <label class="upload-arquivo" for="arquivo-sub">Incluir/Alterar</label>
                    <input type="file" name="arquivo-sub" id="arquivo-sub" (change)="uploadSubCategoria($event)">
                </div>
                <div class="p-col-12 p-md-12 p-lg-11">
                    <label>Descrição</label>
                    <input type="text" pInputText [(ngModel)]="dataSubCategoria.Descricao" required />
                </div>
                <div class="p-col-12 p-md-12 p-lg-1">
                    <label>Status</label><br />
                    <p-checkbox [(ngModel)]="dataSubCategoria.Status" binary="true" inputId="binary">
                    </p-checkbox>
                </div>
            </div>
        </p-card>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Salvar" icon="pi pi-check" class="p-button-sm p-button-success"
            (click)="saveDataSubCategoria(dataSubCategoria.Descricao, dataSubCategoria.Status)"
            [disabled]="!dataSubCategoria.Descricao"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>


<p-dialog header="Imagem" [(visible)]="displayImage" [baseZIndex]="10000">
    <img [src]="data.urlImage" />
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayImage=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog header="Imagem" [(visible)]="displaySubImage" [baseZIndex]="10000">
    <img [src]="dataSubCategoria.urlImage" />
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displaySubImage=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>