<div class="p-grid">
    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>USUÁRIO</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-user"
                        [routerLink]="['cadastro/usuario']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>CATEGORIA</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-sitemap"
                        [routerLink]="['cadastro/categoria']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>PRODUTO</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-tag"
                        [routerLink]="['cadastro/produto']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>PARCEIRO</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-users"
                        [routerLink]="['cadastro/parceiro']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>

    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>PROMOÇÃO</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-tags"
                        [routerLink]="['cadastro/promocao']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>CUPOM</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-tags"
                        [routerLink]="['cadastro/cupom']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>NOTIFICAÇÃO</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-bell"
                        [routerLink]="['cadastro/notificacao']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
    <div class="p-col-12 p-md-6">
        <div class="card widget-overview-box widget-overview-box-1">
            <span class="overview-title">
                <h6>CONFIGURAÇÕES DO APP</h6>
            </span>
            <div class="p-d-flex p-jc-between">
                <div class="overview-detail p-d-flex p-jc-between">
                    <button type="button" pButton label="Acessar" icon="pi pi-fw pi-cog"
                        [routerLink]="['app/configuracao']"></button>
                </div>
            </div>
            <img src="assets/layout/images/dashboard/value.svg">
        </div>
    </div>
</div>