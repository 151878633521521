<p-toast></p-toast>
<p-card>
  <div class="card">
    <br />
    <div class="p-d-flex p-ai-center p-jc-between">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-sm p-button-success p-mr-2"
        (click)="openNew()"
      ></button>
      <div style="text-align: right">
        <button
          (click)="atualizarProdutos()"
          pButton
          pRipple
          type="button"
          label="Atualizar Produtos"
          class="p-button-sm p-button-raised"
        ></button>
      </div>
    </div>

    <br />
    <!-- ***************************************LISTA*************************************** -->

    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px' }"
      *ngIf="loading"
    ></p-progressBar>
    <p-table
      #dt
      [value]="ProdutosFiltred()"
      [globalFilterFields]="['ProdutoID', 'Descricao']"
      [(selection)]="selectedDatas"
      [rowHover]="true"
      [rows]="50"
      [paginator]="true"
      dataKey="ProdutoID"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registro(s)"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <h5 class="p-m-0">Gerenciamento de Produtos</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Pesquisar..."
            />
          </span>
        </div>
        <div id="containerBotoesAtivos">
          <div>
            <p *ngIf="ativosInativos == ''">
              Mostrando os Ativos e os Inativos!
            </p>
            <p *ngIf="ativosInativos == 'S'">Mostrando somente os Ativos!</p>
            <p *ngIf="ativosInativos == 'N'">Mostrando somente os Inativos!</p>
          </div>
          <div>
            <button
              class="botoesAtivos"
              (click)="mudarAtivosInativos('')"
              [disabled]="ativosInativos == ''"
              [ngStyle]="{
                'background-color': ativosInativos == '' ? 'gray' : '#0BD18A'
              }"
            >
              Ambos
            </button>
            <button
              class="botoesAtivos"
              (click)="mudarAtivosInativos('S')"
              [disabled]="ativosInativos == 'S'"
              [ngStyle]="{
                'background-color': ativosInativos == 'S' ? 'gray' : '#0BD18A'
              }"
            >
              Ativos
            </button>
            <button
              class="botoesAtivos"
              (click)="mudarAtivosInativos('N')"
              [disabled]="ativosInativos == 'N'"
              [ngStyle]="{
                'background-color': ativosInativos == 'N' ? 'gray' : '#0BD18A'
              }"
            >
              Inativos
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width: 130px" />
          <col style="width: 500px" />
          <col />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="ProdutoID">
            Código <p-sortIcon field="ProdutoID"></p-sortIcon>
          </th>
          <th pSortableColumn="Descricao">
            Descrição <p-sortIcon field="Descricao"></p-sortIcon>
          </th>
          <th pSortableColumn="Descricao">
            Categoria <p-sortIcon field="Categoria"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>{{ data.ProdutoID }}</td>
          <td>{{ data.Descricao }}</td>
          <td>{{ data.Categoria }}</td>
          <td style="text-align: right">
            <button
              pButton
              pRipple
              icon="pi pi-trash"
              pTooltip="Deletar"
              class="p-button-sm p-button-rounded p-button-warning p-mr-2"
              (click)="deleteData(data)"
            ></button>
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              pTooltip="Editar"
              class="p-button-sm p-button-rounded p-button-warning p-mr-2"
              (click)="editData(data)"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
          No total, existem
          {{ ProdutosFiltred() ? ProdutosFiltred().length : 0 }} produto(s).
        </div>
      </ng-template>
    </p-table>
  </div>
</p-card>

<!-- ***********************************************CADASTRO ***********************************************-->
<p-dialog
  [(visible)]="dataDialog"
  [style]="{ width: '95%', 'max-height': '100%', 'max-width': '1320px' }"
  header="Detalhe Produto"
  [modal]="true"
  styleClass="p-fluid"
  (onHide)="hideDialog()"
>
  <ng-template pTemplate="content">
    <p-card
      [style]="{ width: '100%', 'margin-top': '20px', 'margin-bottom': '30px' }"
    >
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center">
          <div *ngIf="data.urlImage; else elseBlock">
            <a (click)="showImageDialog()">
              <img
                class="imagem-cad"
                [src]="data.urlImage"
                style="width: 20%"
              />
            </a>
          </div>
          <ng-template #elseBlock>
            <div>
              <img src="assets/image.png" style="width: 150px" />
            </div>
          </ng-template>
        </div>

        <div
          class="p-col-12 p-md-12 p-lg-12"
          style="text-align: center; text-align: -webkit-center"
        >
          <label class="upload-arquivo" for="arquivo"
            >Incluir/Alterar Imagem</label
          >
          <input
            type="file"
            name="arquivo"
            id="arquivo"
            (change)="uploadProduto($event)"
            pInputText
          />
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-2">
          <label for="ProdutoID">Código</label>
          <input
            type="text"
            pInputText
            id="ProdutoID"
            [(ngModel)]="data.ProdutoID"
            required
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-6">
          <label for="Descricao">Descrição</label>
          <input
            type="text"
            pInputText
            id="Descricao"
            [(ngModel)]="data.Descricao"
            required
            autofocus
          />
          <small class="p-error" *ngIf="submitted && !data.Descricao"
            >Descrição é obrigatório.</small
          >
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label for="CodigoAlternativo">Código Alternativo</label>
          <input
            type="text"
            pInputText
            id="CodigoAlternativo"
            [(ngModel)]="data.CodigoAlternativo"
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label for="CodigoAlternativo">Status</label>
          <p-dropdown
            [options]="listaSN"
            [(ngModel)]="data.Ativo"
            placeholder="Status"
            optionLabel="valor"
            optionValue="nome"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-6">
          <label for="Categoria">Categoria</label>
          <p-dropdown
            [options]="categorias"
            [filter]="true"
            [(ngModel)]="data.Categoria"
            placeholder="Categoria"
            optionLabel="Descricao"
            optionValue="Descricao"
            [showClear]="true"
            (ngModelChange)="onChangeCategoria($event)"
          ></p-dropdown>
        </div>
        <div class="p-col-12 p-md-12 p-lg-6">
          <label for="SubCategoria">Sub-Categoria</label>
          <p-dropdown
            [options]="subcategorias"
            [filter]="true"
            [(ngModel)]="data.SubCategoria"
            placeholder="Sub-Categoria"
            optionLabel="Descricao"
            optionValue="Descricao"
            [showClear]="true"
            (ngModelChange)="onChangeSubCategoria($event)"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 p-lg-1">
          <label>Preço</label>
          <p-inputNumber
            class="p-inputtext-md"
            [(ngModel)]="data.Preco"
            [disabled]="true"
            [minFractionDigits]="2"
          ></p-inputNumber>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Unidade Venda</label>
          <input type="text" pInputText [(ngModel)]="data.UnidadeVenda" />
        </div>
        <div class="p-col-12 p-md-12 p-lg-1">
          <label>Qtde</label>
          <p-inputNumber
            class="p-inputtext-md"
            [(ngModel)]="data.QuantidadeVenda"
            [minFractionDigits]="2"
          >
          </p-inputNumber>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Unidade Meta</label>
          <input type="text" pInputText [(ngModel)]="data.UnidadeObjetivo" />
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Qtde</label>
          <p-inputNumber
            class="p-inputtext-md"
            [(ngModel)]="data.QuantidadeObjetivo"
            [minFractionDigits]="2"
          >
          </p-inputNumber>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Unidade Troca</label>
          <input type="text" pInputText [(ngModel)]="data.UnidadeTroca" />
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Qtde</label>
          <p-inputNumber
            class="p-inputtext-md"
            [(ngModel)]="data.QuantidadeTroca"
            [minFractionDigits]="2"
          >
          </p-inputNumber>
        </div>
      </div>
      <br />
      <!-- *************************Produtos Combo ********************************-->
      <p-panel
        header="Adicionar Produtos Combos"
        *ngIf="mostrarProdutosCombos(data)"
      >
        <div class="p-grid p-fluid">
          <div class="p-col-12 p-md-12 p-lg-2">
            <label>Código</label>
            <p-dropdown
              [options]="Produtos"
              [filter]="true"
              [(ngModel)]="produtoCombo.ProdutoID"
              placeholder="Código"
              optionLabel="ProdutoID"
              optionValue="ProdutoID"
              appendTo="body"
              dropdownAppendTo="scrollBody"
              [showClear]="true"
              (ngModelChange)="onChangeProdutoCombo($event, 'ProdutoID')"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-12 p-lg-3">
            <label>Descrição</label>
            <p-dropdown
              [options]="Produtos"
              [filter]="true"
              [(ngModel)]="produtoCombo.Descricao"
              placeholder="Descrição"
              optionLabel="Descricao"
              optionValue="Descricao"
              appendTo="body"
              dropdownAppendTo="scrollBody"
              [showClear]="true"
              (ngModelChange)="onChangeProdutoCombo($event, 'Descricao')"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-6 p-lg-1">
            <label>Preço</label>
            <p-inputNumber
              class="p-inputtext-md"
              [(ngModel)]="produtoCombo.Preco"
              [minFractionDigits]="2"
              [disabled]="true"
            ></p-inputNumber>
          </div>
          <div class="p-col-12 p-md-6 p-lg-1">
            <label>Desc.</label>
            <p-inputNumber
              class="p-inputtext-md"
              [(ngModel)]="produtoCombo.Desconto"
              [minFractionDigits]="2"
              (ngModelChange)="calcularDesconto($event)"
            ></p-inputNumber>
          </div>
          <div class="p-col-12 p-md-6 p-lg-1">
            <label>Valor Unit.</label>
            <p-inputNumber
              class="p-inputtext-md"
              [(ngModel)]="produtoCombo.ValorUnitario"
              [minFractionDigits]="2"
            ></p-inputNumber>
          </div>
          <div class="p-col-12 p-md-12 p-lg-1">
            <label>Qtd</label>
            <p-inputNumber
              class="p-inputtext-md"
              [(ngModel)]="produtoCombo.Quantidade"
              [minFractionDigits]="2"
              (ngModelChange)="calcularQuantidade($event)"
            >
            </p-inputNumber>
          </div>
          <div class="p-col-12 p-md-6 p-lg-1">
            <label>Total</label>
            <p-inputNumber
              class="p-inputtext-md"
              [(ngModel)]="produtoCombo.ValorTotal"
              [minFractionDigits]="2"
              [disabled]="true"
            >
            </p-inputNumber>
          </div>
          <div class="p-col-12 p-md-12 p-lg-1" style="text-align: center">
            <label>Permite Alt.</label>
            <p-dropdown
              [options]="listaFT"
              [(ngModel)]="produtoCombo.fixo"
              (ngModelChange)="permiteAlterar($event)"
              optionLabel="valor"
              optionValue="id"
            ></p-dropdown>
          </div>
          <div class="p-col-12 p-md-12 p-lg-2" style="align-self: flex-end">
            <button
              pButton
              pRipple
              label="Adicionar/Alterar"
              icon="pi pi-plus"
              class="p-button-sm p-button-success p-mr-2"
              (click)="AdicionarProdutoCombo()"
              [disabled]="!produtoCombo.Descricao"
            ></button>
          </div>
        </div>
        <p-table
          [value]="listaProdutoCombo"
          *ngIf="listaProdutoCombo.length > 0; else SemProdutosCombos"
          selectionMode="single"
          [(selection)]="selectedProdutoCombo"
          dataKey="ProdutoComboID"
          styleClass="p-datatable-striped"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>ID Produto</th>
              <th>Produto</th>
              <th>Valor Unitário</th>
              <th>Quantidade</th>
              <th>Valor Total</th>
              <th>Permite Alterar</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-prod>
            <tr [pSelectableRow]="data">
              <td>{{ prod.ProdutoID }}</td>
              <td>{{ prod.Descricao }}</td>
              <td>{{ prod.ValorUnitario | number : '1.2-2' }}</td>
              <td>{{ prod.Quantidade | number : '1.2-2' }}</td>
              <td>{{ prod.ValorTotal | number : '1.2-2' }}</td>
              <td>
                {{
                  prod.Fixo == false || prod.Fixo == undefined ? 'Não' : 'Sim'
                }}
              </td>
              <td>
                <button
                  pButton
                  pRipple
                  icon="pi pi-pencil"
                  pTooltip="Editar"
                  class="p-button-sm p-button-rounded p-button-success p-mr-2"
                  (click)="EditarProdutoCombo(prod)"
                ></button>
                <button
                  pButton
                  pRipple
                  icon="pi pi-trash"
                  pTooltip="Excluir"
                  class="p-button-sm p-button-rounded p-button-danger"
                  (click)="RemoverProdutoCombo(prod)"
                ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">Não há registros.</td>
            </tr>
          </ng-template>
        </p-table>
        <ng-template #SemProdutosCombos>
          <div
            style="
              text-align: center;
              background-color: white;
              width: 100%;
              padding: 20px;
            "
          >
            <p
              style="
                font-family: Verdana, Geneva, Tahoma, sans-serif;
                font-weight: bold;
              "
            >
              Não há registros
            </p>
          </div>
        </ng-template>
      </p-panel>
    </p-card>
  </ng-template>

  <ng-template pTemplate="footer">
    <br />
    <button
      pButton
      pRipple
      type="button"
      label="GRAVAR"
      [disabled]="!data.Descricao || !data.Categoria || !data.SubCategoria"
      style="width: 100px; float: left"
      class="p-button-raised p-button-success"
      (click)="saveData()"
    ></button>

    <!--p-progressSpinner [style]="{'width':'20px','height':'20px','float':'left','margin-top':'5px'}"
            *ngIf="loadingSave" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner-->

    <button
      pButton
      pRipple
      type="button"
      label="RETORNAR"
      style="width: 100px"
      class="p-button-raised p-button-danger"
      (click)="hideDialog()"
    ></button>

    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px', 'margin-top': '5px' }"
      *ngIf="loadingSave"
    ></p-progressBar>
  </ng-template>

  <!-- <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-sm p-button-danger"
            (click)="hideDialog()"></button>
        <button pButton pRipple label="Gravar" icon="pi pi-check" class="p-button-sm p-button-success"
            [disabled]="!data.Descricao || !data.Categoria || !data.SubCategoria" (click)="saveData()"></button>
    </ng-template> -->
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<p-dialog header="Imagem" [(visible)]="displayImage" [baseZIndex]="10000">
  <img [src]="data.urlImage" />
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayImage = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
