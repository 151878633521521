import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  QueryDocumentSnapshot,
} from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { EmailValidator } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { finalize, debounceTime } from 'rxjs/operators';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { FirebaseService } from '../app.login.firebase.service';
import { ProdutoModel } from '../produto/produto.model';
import { ParceiroModel } from './parceiro.model';
import { ParceiroNegocioEnderecosModel } from './parceiro.negocioenderecos.model';
import { ParceiroPedidosAnterioresModel } from './parceiro.pedidosanteriores.model';
import { ParceiroPrecosProdutosModel } from './parceiro.precosprodutos.model';
import { ParceiroProdutosQuantidadesCompradasModel } from './parceiro.produtosqtdcompradas.model';
import { ParceiroPromocoesPrecosModel } from './parceiro.promocoesprecos.model';
import { ParceiroService } from './parceiro.service';

@Component({
  selector: 'app-parceiro',
  templateUrl: './parceiro.component.html',
  styleUrls: ['./parceiro.component.scss'],
})
export class ParceiroComponent implements OnInit {
  show = false;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  Produtos: ProdutoModel[] = JSON.parse(localStorage.getItem('Produtos'));
  Segmentos: SegmentoModel[] = JSON.parse(localStorage.getItem('Segmentos'));

  dataDialog: boolean;
  dataModify: boolean;

  //New email
  newEmail: EmailValidator;
  newPassword: string;
  newSubmitted: boolean;

  optionsSearch = ['RazaoSocial', 'CpfCnpj'];
  selectedOption = 'RazaoSocial';
  search = '';

  datas: Observable<any[]> | any = [];
  datasParceiroNegociosComplementares: any;
  lista: ParceiroModel[];
  loading: boolean = true;
  data: ParceiroModel;
  selectedDatas: ParceiroModel;
  teste: any;
  submitted: boolean;

  lastPagePulled: number = 1;
  lastVisible: QueryDocumentSnapshot<ParceiroModel> = null;
  searchTerm = new Subject<string>();

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  selectedEnderecos: ParceiroNegocioEnderecosModel;
  selectedPedidosAnteriores: ParceiroPedidosAnterioresModel;
  selectedPrecosProdutos: ParceiroPrecosProdutosModel;
  selectedProdutosQuantidadesCompradas: ParceiroProdutosQuantidadesCompradasModel;
  selectedPromocoesPrecos: ParceiroPromocoesPrecosModel;
  private basePath = 'parceiros/';

  profileUrl: any;

  constructor(
    private service: ParceiroService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  ngOnDestroy() {}

  async ngOnInit() {
    this.searchTerm.pipe(debounceTime(1000)).subscribe((value) => {
      this.datas = [];
      this.lastPagePulled = 1;
      this.lastVisible = null;
      this.search = value;
      this.getParceiro();
    });
    this.data = {};
    this.getParceiro();
  }

  onOptionChange() {
    if (this.search) {
      this.datas = [];
      this.lastPagePulled = 1;
      this.lastVisible = null;
      this.getParceiro();
    }
  }

  onPageChange(event: any) {
    const pageSize = event.rows;
    const pageIndex = event.first / pageSize + 1;

    if (pageIndex > this.lastPagePulled) {
      this.lastPagePulled = pageIndex;
      this.getParceiro();
    }
  }

  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    this.searchTerm.next(input.value);
  }

  uploadedFiles: File = null;
  fb: any;

  private getParceiro() {
    try {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.loading = true;
          this.service
            .readParceiro(
              51,
              this.selectedOption,
              this.search.toUpperCase(),
              this.lastVisible
            )
            .subscribe((data) => {
              this.datas = [
                ...this.datas,
                ...data.docs.map((e) => {
                  const dataValue = e.data();

                  const newDataValue = {
                    id: e.id,
                    isEdit: false,
                    AreaDescricao: dataValue['AreaDescricao'],
                    AreaID: dataValue['AreaID'],
                    Ativo: dataValue['Ativo'],
                    CarteiraVendaDescricao: dataValue['CarteiraVendaDescricao'],
                    CarteiraVendaID: dataValue['CarteiraVendaID'],
                    CodigoAlternativo: dataValue['CodigoAlternativo'],
                    CpfCnpj: dataValue['CpfCnpj'],
                    Email: dataValue['Email'],
                    FirebaseID: dataValue['FirebaseID'],
                    Homologado: dataValue['Homologado'],
                    LimiteCreditoTotal: dataValue['LimiteCreditoTotal'],
                    LimiteCreditoUsado: dataValue['LimiteCreditoUsado'],
                    NomeFantasia: dataValue['NomeFantasia'],
                    ParceiroNegocioID: dataValue['ParceiroNegocioID'],
                    PedidoBloqueado: dataValue['PedidoBloqueado'],
                    PedidoEmUnidade: dataValue['PedidoEmUnidade'],
                    RazaoSocial: dataValue['RazaoSocial'],
                    RgIe: dataValue['RgIe'],
                    SegmentoDescricao: dataValue['SegmentoDescricao'],
                    SegmentoID: dataValue['SegmentoID'],
                    Telefone: dataValue['Telefone'],
                    Celular: dataValue['Celular'],
                    urlImage: dataValue['urlImage'],

                    CondicoesFormasPagamentos:
                      dataValue['CondicoesFormasPagamentos'],
                    ParceiroNegocioEnderecos:
                      dataValue['ParceiroNegocioEnderecos'],
                    // Comodatos: dataValue['Comodatos'],
                    // ContasReceber: dataValue['ContasReceber'],
                    // PedidosAnteriores: dataValue['PedidosAnteriores'],
                    // PrecosProdutos: dataValue['PrecosProdutos'],
                    // ProdutosQuantidadesCompradas: dataValue['ProdutosQuantidadesCompradas'],
                    // PromocoesPrecos: dataValue['PromocoesPrecos'],
                  };

                  return newDataValue;
                }),
              ];

              // Armazena o último documento para paginação futura
              this.lastVisible = data.docs[data.docs.length - 1];

              this.loading = false;
            });
        } else {
          this.datas = new Observable<any[]>();
        }
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  uploadParceiro(event) {
    this.uploadedFiles = <File>event.target.files[0];

    try {
      if (this.uploadedFiles != null) {
        const filePath = `${this.basePath}/${this.data.ParceiroNegocioID}.png`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, this.uploadedFiles);

        uploadTask
          .snapshotChanges()
          .pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe((downloadURL) => {
                this.fb = downloadURL;
                this.data.urlImage = downloadURL;

                if (this.data.id && this.data.urlImage == undefined) {
                  this.data.urlImage = '';
                  // this.service.updateParceiro(this.data.id, this.data);
                }

                this.messageService.add({
                  severity: 'success',
                  summary: 'Informação',
                  detail: 'Foto alterada com sucesso!',
                });
                //this.saveFileData(this.fb);
              });
            })
          )
          .subscribe();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro em alterar foto!',
      });
      alert(error);
    }
  }

  // private saveFileData(fileUpload: FileUpload): void {
  //   //this.db.list(this.basePath).push(fileUpload);
  // }

  async getImage(codigo: string): Promise<string> {
    let resultado: Promise<string>;
    try {
      await this.storage
        .ref(this.basePath + codigo + '.png')
        .getDownloadURL()
        .toPromise()
        .then((response: any) => {
          resultado = response;
          return resultado;
        })
        .catch((error) => error);

      return resultado;
    } catch (error) {}
  }

  openNew() {
    this.data = {};
    this.submitted = false;
    this.dataDialog = true;
  }

  async editData(data: ParceiroModel) {
    data.isEdit = true;
    this.loadingSave = false;

    // data.urlImage = await this.getImage(data.CategoriaID);
    let ProdutosQuantidadesComp: ParceiroProdutosQuantidadesCompradasModel[];
    ProdutosQuantidadesComp = [];

    this.datasParceiroNegociosComplementares = await this.listaComplementares(
      data
    );

    if (data.ProdutosQuantidadesCompradas != null) {
      for (
        let index = 0;
        index < data.ProdutosQuantidadesCompradas.length;
        index++
      ) {
        const elem = data.ProdutosQuantidadesCompradas[index];
        let descricao = this.Produtos.find(
          (x) => x.ProdutoID == elem.ProdutoID
        );
        const prodQuant: ParceiroProdutosQuantidadesCompradasModel = {
          ProdutoID: elem.ProdutoID,
          Descricao: descricao == undefined ? '' : descricao.Descricao,
          QuantidadeAtual: elem.QuantidadeAtual,
          QuantidadeMesPassado: elem.QuantidadeMesPassado,
        };

        ProdutosQuantidadesComp.push(prodQuant);
      }
    }
    data.ProdutosQuantidadesCompradas = ProdutosQuantidadesComp;
    this.data = { ...data };
    this.dataDialog = true;
  }

  listaComplementares(data: ParceiroModel): Promise<[]> {
    let listaComplementares: any;

    if (data.FirebaseID != undefined && data.FirebaseID != null) {
      this.firestore
        .collection<ParceiroModel>('ParceirosNegociosComplementos', (ref) =>
          ref.where('FirebaseID', '==', data.FirebaseID)
        )
        .snapshotChanges()
        .subscribe((data) => {
          if (data[0] != null) {
            this.data.PedidosAnteriores =
              data[0].payload.doc.data()['PedidosAnteriores'];
            this.data.ProdutosQuantidadesCompradas =
              data[0].payload.doc.data()['ProdutosQuantidadesCompradas'];
          }
        });
    }

    return listaComplementares;
  }

  deleteData(data: ParceiroModel) {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja excluir ' + data.NomeFantasia + '?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: async () => {
        try {
          let result = await this.service.PostDeletarParceiro(
            data.ParceiroNegocioID
          );
          console.log(result);
          if (result == false) {
            this.messageService.add({
              severity: 'error',
              summary: 'Informação',
              detail: 'Não foi possível excluir o registro!\n' + result,
            });
          } else {
            this.service.deleteParceiro(data.id);
            this.data = {};
            this.messageService.add({
              severity: 'success',
              summary: 'Informação',
              detail: 'Usuário excluído com sucesso!',
              life: 3000,
            });
          }
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Não foi possível fazer a exclusão! \n' + error,
          });
        }
      },
    });
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
  }

  loadingSave: boolean = false;
  async saveData() {
    try {
      this.submitted = true;
      this.loadingSave = true;

      let data = {};

      if (this.data.FirebaseID != undefined) {
        data['AreaDescricao'] = this.FormataString(this.data.AreaDescricao);
        data['AreaID'] = this.FormataNumber(this.data.AreaID);
        data['Ativo'] = this.FormataString(this.data.Ativo);
        data['CarteiraVendaDescricao'] = this.FormataString(
          this.data.CarteiraVendaDescricao
        );
        data['CarteiraVendaID'] = this.FormataNumber(this.data.CarteiraVendaID);
        data['CodigoAlternativo'] = this.FormataString(
          this.data.CodigoAlternativo
        );
        data['CpfCnpj'] = this.FormataString(this.data.CpfCnpj);
        data['Email'] = this.FormataString(this.data.Email);
        data['Homologado'] = this.FormataString(this.data.Homologado);
        data['LimiteCreditoTotal'] = this.FormataNumber(
          this.data.LimiteCreditoTotal
        );
        data['LimiteCreditoUsado'] = this.FormataNumber(
          this.data.LimiteCreditoUsado
        );
        data['NomeFantasia'] = this.FormataString(this.data.NomeFantasia);
        data['ParceiroNegocioID'] = this.FormataString(
          this.data.ParceiroNegocioID
        );
        data['PedidoBloqueado'] = this.FormataString(this.data.PedidoBloqueado);
        data['PedidoEmUnidade'] = this.FormataString(this.data.PedidoEmUnidade);
        data['RazaoSocial'] = this.FormataString(this.data.RazaoSocial);
        data['RgIe'] = this.FormataString(this.data.RgIe);
        data['SegmentoDescricao'] = this.FormataString(
          this.data.SegmentoDescricao
        );
        data['SegmentoID'] = this.FormataNumber(this.data.SegmentoID);
        data['Telefone'] = this.FormataString(this.data.Telefone);
        data['Celular'] = this.FormataString(this.data.Celular);

        // data['Comodatos'] = this.data.Comodatos;
        data['CondicoesFormasPagamentos'] = this.data.CondicoesFormasPagamentos;
        // data['ContasReceber'] = this.data.ContasReceber;
        data['ParceiroNegocioEnderecos'] = this.data.ParceiroNegocioEnderecos;
        // data['PedidosAnteriores'] = this.data.PedidosAnteriores;
        // data['PrecosProdutos'] = this.data.PrecosProdutos;
        // data['ProdutosQuantidadesCompradas'] = this.data.ProdutosQuantidadesCompradas;
        // data['PromocoesPrecos'] = this.data.PromocoesPrecos;

        data['urlImage'] = this.FormataString(this.data.urlImage);

        this.service.updateParceiro(this.data.FirebaseID, data);
        let result = await this.service.PostParceiroAlterar(this.data);

        console.log(result);
        if (result == true) {
          this.data.isEdit = false;
          this.dataDialog = false;
          this.loadingSave = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Registro alterado com sucesso!',
            life: 3000,
          });
        } else {
          this.loadingSave = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Não foi possível atualizar o registro!\n' + result,
          });
        }
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não foi possível fazer a requisição! \n' + error,
      });
    }
  }

  FormataString(data) {
    if (data == null || data == undefined) {
      return '';
    }
    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  FormataNumber(data) {
    if (data == null || data == undefined) {
      return 0;
    }
    return data;
    // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
  }

  onChangeSegmento(event) {
    if (event != null || event != undefined) {
      this.data.SegmentoDescricao = '';
      this.data.SegmentoID = 0;
      this.data.SegmentoID = this.Segmentos.find(
        (x) => x.Nome == event
      ).SegmentoID;
      this.data.SegmentoDescricao = this.Segmentos.find(
        (x) => x.Nome == event
      ).Nome;
    }
  }

  async atualizarParceiros() {
    if (this.selectedDatas != null) {
      this.service.atualizaParceiro(this.selectedDatas.FirebaseID).subscribe(
        (data) => {
          this.loading = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Lista atualizada!',
            life: 3000,
          });
        },
        (err) => {
          console.log(err);
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail: 'Erro ao atualizar!\n' + err,
          });
          this.loading = false;
        }
      );
    } else {
      this.messageService.add({
        severity: 'success',
        summary: 'Informação',
        detail: 'Nenhum registro selecionado!',
        life: 3000,
      });
    }
  }

  displayImage: boolean;

  showImageDialog() {
    this.displayImage = true;
  }

  parceiroModigy(data: ParceiroModel) {
    data.isEdit = true;
    this.data = { ...data };
    this.dataModify = true;
  }

  cancelDialog() {
    this.dataModify = false;
    this.submitted = false;
    this.newEmail = undefined;
    this.newPassword = undefined;
  }

  async modifyPass(data: ParceiroModel, email: string, password: string) {
    this.submitted = true;

    try {
      if (email == undefined || password == undefined) {
        this.newPassword = undefined;
        this.newEmail = undefined;
        this.submitted = false;
        return;
      }

      if (data.FirebaseID != null || data.FirebaseID != undefined) {
        this.service.postAtualizaDadosParceiro(
          email,
          data.FirebaseID,
          password
        );

        this.newPassword = undefined;
        this.newEmail = undefined;
        this.submitted = false;
        this.dataModify = false;

        this.cancelDialog();

        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Parceiro alterado com sucesso!',
          life: 3000,
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não foi possível fazer a requisição! \n' + error,
      });
    }
  }
}
