<p-toast></p-toast>
<p-card>
  <div class="card">
    <br />
    <div style="text-align: right">
      <button
        (click)="atualizarParceiros()"
        pButton
        pRipple
        type="button"
        label="Atualizar Parceiros"
        class="p-button-sm p-button-raised"
      ></button>
    </div>
    <br />
    <!-- ****************************************LISTA **********************************************-->
    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px' }"
      *ngIf="loading"
    ></p-progressBar>
    <p-table
      #dt
      [value]="datas"
      [rows]="50"
      [paginator]="true"
      [globalFilterFields]="[
        'RazaoSocial',
        'CpfCnpj',
        'SegmentoDescricao',
        'CarteiraVendaDescricao'
      ]"
      [(selection)]="selectedDatas"
      [rowHover]="true"
      selectionMode="single"
      dataKey="FirebaseID"
      (onPage)="onPageChange($event)"
      currentPageReportTemplate="Mostrando {first} a {last} registro(s)"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <h5 class="p-m-0">Gerenciamento de Parceiros</h5>
          <div>
            <p-dropdown
              [options]="optionsSearch"
              [(ngModel)]="selectedOption"
              placeholder="Selecione um filtro"
              (onChange)="onOptionChange($event)"
            >
            </p-dropdown>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="
                  onInputChange($event);
                  dt.filterGlobal($event.target.value, 'contains')
                "
                placeholder="Pesquisar..."
              />
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width: 110px" />
          <col style="width: 300px" />
          <col style="width: 130px" />
          <col style="width: 300px" />
          <col />
          <col />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="CodigoAlternativo">
            Código<p-sortIcon field="CodigoAlternativo"></p-sortIcon>
          </th>
          <th pSortableColumn="RazaoSocial">
            Razão/Nome<p-sortIcon field="RazaoSocial"></p-sortIcon>
          </th>
          <th pSortableColumn="CpfCnpj">
            CNPJ<p-sortIcon field="CpfCnpj"></p-sortIcon>
          </th>
          <th pSortableColumn="SegmentoDescricao">
            Segmento<p-sortIcon field="SegmentoDescricao"></p-sortIcon>
          </th>

          <th pSortableColumn="CarteiraVendaDescricao">
            Carteira<p-sortIcon field="CarteiraVendaDescricao"> </p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr [pSelectableRow]="data">
          <td>{{ data.CodigoAlternativo }}</td>
          <td>{{ data.RazaoSocial }}</td>
          <td>{{ data.CpfCnpj }}</td>
          <td>{{ data.SegmentoDescricao }}</td>
          <td>{{ data.CarteiraVendaDescricao }}</td>
          <td style="text-align: right">
            <button
              class="p-ml-2"
              icon="pi pi-pencil"
              pButton
              pRipple
              type="button"
              pTooltip="Alterar dados"
              (click)="parceiroModigy(data)"
            ></button>
            <button
              class="p-ml-2"
              icon="pi pi-cog"
              pButton
              pRipple
              type="button"
              pTooltip="Editar Parceiro"
              (click)="editData(data)"
            ></button>
            <button
              class="p-ml-2"
              icon="pi pi-trash"
              pButton
              pRipple
              type="button"
              pTooltip="Deletar Parceiro"
              (click)="deleteData(data)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>

<!-- ***************************************** ALTERAR *******************************************-->
<p-dialog
  [(visible)]="dataModify"
  [style]="{ width: '40%' }"
  header="Alterar Dados"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-lg-12">
        <label>UID</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="data.FirebaseID"
          required
          [disabled]="true"
        />
      </div>
    </div>

    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-lg-3">
        <label>Código</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="data.CodigoAlternativo"
          required
          [disabled]="true"
        />
      </div>
      <div class="p-col-12 p-md-12 p-lg-9">
        <label>Razão/Nome</label>
        <input
          type="text"
          pInputText
          [(ngModel)]="data.RazaoSocial"
          required
          autofocus
          [disabled]="true"
        />
        <small class="p-error" *ngIf="submitted && !data.RazaoSocial"
          >Razão Social é obrigatório.</small
        >
      </div>
    </div>

    <p-divider></p-divider>

    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-12 p-lg-12">
        <label>Novo e-mail</label>
        <input type="email" pInputText [(ngModel)]="newEmail" required />
        <small class="p-error" *ngIf="submitted && newEmail"
          >E-mail Fantasia é obrigatório.</small
        >
      </div>
      <div class="p-col-12 p-md-12 p-lg-12">
        <label>Nova senha</label>
        <input type="text" pInputText [(ngModel)]="newPassword" required />
        <small class="p-error" *ngIf="submitted && !newPassword"
          >Senha é obrigatório.</small
        >
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      (click)="cancelDialog()"
      pButton
      pRipple
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-text cancel-button-parceiro"
    ></button>
    <button
      (click)="modifyPass(data, newEmail, newPassword)"
      pButton
      pRipple
      label="Salvar"
      icon="pi pi-check"
      class="p-button-text confirm-button-parceiro"
    ></button>
  </ng-template>
</p-dialog>

<!-- ***************************************************CADASTRO ************************************************-->
<p-dialog
  [(visible)]="dataDialog"
  [style]="{ width: '95%', 'max-height': '100%', 'max-width': '1320px' }"
  header="Detalhe Parceiro"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <p-card
      [style]="{ width: '100%', 'margin-top': '20px', 'margin-bottom': '30px' }"
    >
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center">
          <div *ngIf="data.urlImage; else elseBlock">
            <a (click)="showImageDialog()">
              <img
                class="imagem-cad"
                [src]="data.urlImage"
                style="width: 20%"
              />
            </a>
          </div>
          <ng-template #elseBlock>
            <div>
              <img src="assets/image.png" style="width: 150px" />
            </div>
          </ng-template>
        </div>

        <div
          class="p-col-12 p-md-12 p-lg-12"
          style="text-align: center; text-align: -webkit-center"
        >
          <label class="upload-arquivo" for="arquivo"
            >Incluir/Alterar Imagem</label
          >
          <input
            type="file"
            name="arquivo"
            id="arquivo"
            (change)="uploadParceiro($event)"
            pInputText
          />
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Código</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.CodigoAlternativo"
            required
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-5">
          <label>Razão/Nome</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.RazaoSocial"
            required
            autofocus
            [disabled]="true"
          />
          <small class="p-error" *ngIf="submitted && !data.RazaoSocial"
            >Razão Social é obrigatório.</small
          >
        </div>
        <div class="p-col-12 p-md-12 p-lg-5">
          <label>Nome Fantasia</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.NomeFantasia"
            required
            [disabled]="true"
          />
          <small class="p-error" *ngIf="submitted && !data.NomeFantasia"
            >Nome Fantasia é obrigatório.</small
          >
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>CNPJ/CPF</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.CpfCnpj"
            required
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>IE/RG</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.RgIe"
            required
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Telefone</label>
          <p-inputMask
            mask="(99) 9999-9999"
            [(ngModel)]="data.Telefone"
            placeholder="(99) 9999-9999"
          >
          </p-inputMask>
          <!-- <input type="text" pInputText [(ngModel)]="data.Telefone" required /> -->
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Celular</label>
          <p-inputMask
            mask="(99) 99999-9999"
            [(ngModel)]="data.Celular"
            placeholder="(99) 99999-9999"
          >
          </p-inputMask>
          <!-- <input type="text" pInputText [(ngModel)]="data.Celular" required /> -->
        </div>
        <div class="p-col-12 p-md-12 p-lg-1">
          <label>Seg ID</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.SegmentoID"
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
          <label>Segmento</label>
          <p-dropdown
            [options]="Segmentos"
            [(ngModel)]="data.SegmentoDescricao"
            placeholder="Segmento"
            optionLabel="Nome"
            optionValue="Nome"
            [showClear]="true"
            (ngModelChange)="onChangeSegmento($event)"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-3">
          <label>Limite de Crédito</label>
          <p-inputNumber
            class="p-inputtext-sm"
            [(ngModel)]="data.LimiteCreditoTotal"
            [disabled]="true"
            [minFractionDigits]="2"
          ></p-inputNumber>
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
          <label>Limite de Crédito Utilizado</label>
          <p-inputNumber
            class="p-inputtext-sm"
            [(ngModel)]="data.LimiteCreditoUsado"
            [disabled]="true"
            [minFractionDigits]="2"
          ></p-inputNumber>
        </div>
        <div class="p-col-12 p-md-12 p-lg-3">
          <label>Pedido Bloqueado</label><br />
          <p-checkbox
            [(ngModel)]="data.PedidoBloqueado"
            binary="true"
            inputId="binary"
            [disabled]="true"
          >
          </p-checkbox>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Homologado</label><br />
          <p-checkbox
            [(ngModel)]="data.Homologado"
            binary="true"
            inputId="binary"
            [disabled]="true"
          >
          </p-checkbox>
        </div>
        <div class="p-col-12 p-md-12 p-lg-1">
          <label>Ativo</label><br />
          <p-checkbox
            [(ngModel)]="data.Ativo"
            binary="true"
            inputId="binary"
            [disabled]="true"
          >
          </p-checkbox>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-6">
          <label>E-mail</label>
          <input type="text" pInputText [(ngModel)]="data.Email" required />
        </div>
        <div class="p-col-12 p-md-12 p-lg-1">
          <label>Carteira ID</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.CarteiraVendaID"
            required
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-5">
          <label>Carteira Venda</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.CarteiraVendaDescricao"
            required
            [disabled]="true"
          />
        </div>
      </div>
      <div class="p-grid p-fluid" style="margin: 5px">
        <br />
        <p-accordion [multiple]="true">
          <p-accordionTab header="Endereços">
            <div class="card" style="background-color: rgb(247, 247, 247)">
              <p-table
                [value]="data.ParceiroNegocioEnderecos"
                selectionMode="single"
                [(selection)]="selectedEnderecos"
                dataKey="ParceiroNegocioEnderecoID"
                styleClass="p-datatable-striped"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Endereço De</th>
                    <th>Endereço</th>
                    <th>Bairro</th>
                    <th>Número</th>
                    <th>CEP</th>
                    <th>Cidade</th>
                    <th>Estado</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                  <tr [pSelectableRow]="data">
                    <td>{{ data.EnderecoDe }}</td>
                    <td>{{ data.Endereco }}</td>
                    <td>{{ data.Bairro }}</td>
                    <td>{{ data.Numero }}</td>
                    <td>{{ data.CEP }}</td>
                    <td>{{ data.CidadeNome }}</td>
                    <td>{{ data.Estado }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Pedidos Anteriores">
            <div class="card" style="background-color: rgb(247, 247, 247)">
              <p-table
                [value]="data.PedidosAnteriores"
                selectionMode="single"
                [(selection)]="selectedPedidosAnteriores"
                dataKey="PedidoID"
                styleClass="p-datatable-striped"
              >
                <!-- <ng-template pTemplate="colgroup" let-columns>
                                        <colgroup>
                                            <col>
                                            <col style="width:100px">
                                            <col style="width:200px">
                                            <col style="width:100px">
                                            <col style="width:150px">
                                            <col style="width:100px">
                                            <col style="width:100px">
                                            <col style="width:100px">
                                        </colgroup>
                                    </ng-template> -->
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 3rem"></th>
                    <th>Data</th>
                    <th>Carteira</th>
                    <th>Condição Pagto</th>
                    <th>Status</th>
                    <th style="text-align: right">Total</th>
                    <th style="text-align: right">Bonificado</th>
                    <th style="text-align: right">Troca</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-expanded="expanded">
                  <tr [pSelectableRow]="data">
                    <td>
                      <button
                        type="button"
                        pButton
                        pRipple
                        [pRowToggler]="data"
                        class="p-button-sm p-button-text p-button-rounded p-button-plain"
                        [icon]="
                          expanded
                            ? 'pi pi-chevron-down'
                            : 'pi pi-chevron-right'
                        "
                      ></button>
                    </td>
                    <td>{{ data.Data.toDate() | date : 'dd/MM/yyyy' }}</td>
                    <td>{{ data.CarteiraVendaDescricao }}</td>
                    <td>{{ data.CondicaoFormaPagamento }}</td>
                    <td>{{ data.ListaStatusDescricao }}</td>
                    <td style="text-align: right">
                      {{ data.Total | currency : 'BRL' : true }}
                    </td>
                    <td style="text-align: right">
                      {{ data.TotalBonificado | currency : 'BRL' : true }}
                    </td>
                    <td style="text-align: right">
                      {{ data.TotalTroca | currency : 'BRL' : true }}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-product>
                  <tr>
                    <td colspan="5">
                      <div class="p-p-3">
                        <p-table
                          [value]="product.PedidosAnterioresItens"
                          dataKey="PedidoItemID"
                        >
                          <ng-template pTemplate="header">
                            <tr>
                              <th pSortableColumn="ProdutoID">
                                Produto ID
                                <p-sortIcon field="ProdutoID"> </p-sortIcon>
                              </th>
                              <th pSortableColumn="ProdutoDescricao">
                                Produto Descrição
                                <p-sortIcon
                                  field="ProdutoDescricao"
                                ></p-sortIcon>
                              </th>
                              <th style="text-align: right">Total Venda</th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-order>
                            <tr>
                              <td>{{ order.ProdutoID }}</td>
                              <td>{{ order.ProdutoDescricao }}</td>
                              <td style="text-align: right">
                                {{ order.TotalVenda | currency : 'BRL' : true }}
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="6">
                                Ainda não há Itens para este Pedido.
                              </td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-accordionTab>

          <p-accordionTab header="Produtos - Quantidades Compradas">
            <div class="card" style="background-color: rgb(247, 247, 247)">
              <p-table
                [value]="data.ProdutosQuantidadesCompradas"
                selectionMode="single"
                [(selection)]="selectedProdutosQuantidadesCompradas"
                dataKey="ProdutoID"
                styleClass="p-datatable-striped"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th>Produto ID</th>
                    <th>Nome</th>
                    <th style="text-align: right">Quantidade Atual</th>
                    <th style="text-align: right">Quantidade Mês Anterior</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                  <tr [pSelectableRow]="data">
                    <td>{{ data.ProdutoID }}</td>
                    <td>{{ data.Descricao }}</td>
                    <td style="text-align: right">
                      {{ data.QuantidadeAtual | currency : 'BRL' : true }}
                    </td>
                    <td style="text-align: right">
                      {{ data.QuantidadeMesPassado | currency : 'BRL' : true }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </p-card>
  </ng-template>

  <ng-template pTemplate="footer">
    <br />
    <button
      pButton
      pRipple
      type="button"
      label="GRAVAR"
      [disabled]="!data.SegmentoDescricao || !data.Email || !data.Telefone"
      style="width: 100px; float: left"
      class="p-button-raised p-button-success"
      (click)="saveData()"
    ></button>

    <!--p-progressSpinner [style]="{'width':'20px','height':'20px','float':'left','margin-top':'5px'}"
            *ngIf="loadingSave" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner-->

    <button
      pButton
      pRipple
      type="button"
      label="RETORNAR"
      style="width: 100px"
      class="p-button-raised p-button-danger"
      (click)="hideDialog()"
    ></button>

    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px', 'margin-top': '5px' }"
      *ngIf="loadingSave"
    ></p-progressBar>
  </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<p-dialog header="Imagem" [(visible)]="displayImage" [baseZIndex]="10000">
  <img [src]="data.urlImage" />
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayImage = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
