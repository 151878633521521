<p-toast></p-toast>
<p-card>
  <div class="card">
    <br />
    <div class="p-d-flex p-ai-center p-jc-between">
      <button
        pButton
        pRipple
        label="Novo"
        icon="pi pi-plus"
        class="p-button-sm p-button-success p-mr-2"
        (click)="openNew()"
      ></button>

      <div style="text-align: right">
        <button
          (click)="atualizarPromocoes()"
          pButton
          pRipple
          type="button"
          label="Atualizar Promoções"
          class="p-button-sm p-button-raised"
        ></button>
      </div>
    </div>
    <br />

    <!-- **************************** LISTA **************************** -->
    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px' }"
      *ngIf="loading"
    ></p-progressBar>
    <p-table
      #dt
      [value]="datasFiltred()"
      [rows]="50"
      [paginator]="true"
      [globalFilterFields]="[
        'Descricao',
        'PromocaoID',
        'DataInicio',
        'DataFinal'
      ]"
      [(selection)]="selectedDatas"
      [rowHover]="true"
      dataKey="PromocaoID"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registro(s)"
      [showCurrentPageReport]="true"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <h5 class="p-m-0">Gerenciamento de Parceiros Promoção</h5>
        </div>
        <br />
        <div class="p-fluid p-grid p-formgrid">
          <!-- <div class="p-field p-col-12 p-md-3">
                        <label for="icon">Data Inicial</label>
                        <p-calendar [(ngModel)]="PeriodoDataInicial" dateFormat="dd/mm/yy" [showIcon]="true"
                            inputId="icon"></p-calendar>
                    </div>
                    <div class="p-field p-col-12 p-md-3">
                        <label for="icon">Data Final</label>
                        <p-calendar [(ngModel)]="PeriodoDataFinal" dateFormat="dd/mm/yy" [showIcon]="true"
                            inputId="icon"></p-calendar>
                    </div> -->
          <div class="p-field p-col-12 p-md-4">
            <label for="icon">Descrição</label>
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Pesquisar..."
              />
            </span>
          </div>
          <div id="containerBotoesAtivos">
            <div>
              <p *ngIf="ativosInativos === ''">
                Mostrando os Ativos e os Inativos!
              </p>
              <p *ngIf="ativosInativos === true">
                Mostrando somente os Ativos!
              </p>
              <p *ngIf="ativosInativos === false">
                Mostrando somente os Inativos!
              </p>
            </div>
            <div>
              <button
                class="botoesAtivos"
                (click)="mudarAtivosInativos('')"
                [disabled]="ativosInativos === ''"
                [ngStyle]="{
                  'background-color': ativosInativos === '' ? 'gray' : '#0BD18A'
                }"
              >
                Ambos
              </button>
              <button
                class="botoesAtivos"
                (click)="mudarAtivosInativos(true)"
                [disabled]="ativosInativos === true"
                [ngStyle]="{
                  'background-color':
                    ativosInativos === true ? 'gray' : '#0BD18A'
                }"
              >
                Ativos
              </button>
              <button
                class="botoesAtivos"
                (click)="mudarAtivosInativos(false)"
                [disabled]="ativosInativos === false"
                [ngStyle]="{
                  'background-color':
                    ativosInativos === false ? 'gray' : '#0BD18A'
                }"
              >
                Inativos
              </button>
            </div>
          </div>
          <!-- <div class="p-field p-col-12 p-md-2" style="align-self: end;">
                        <p-button label="Pesquisar" icon="pi pi-search" iconPos="right" (click)="FiltrarCampos()"></p-button>
                    </div> -->
        </div>
      </ng-template>
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col style="width: 50px" />
          <col />
          <col style="width: 130px" />
          <col style="width: 130px" />
          <col style="width: 50px" />
          <!-- <col style="width:50px"> -->
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1rem"></th>
          <th pSortableColumn="Descricao">
            Descrição<p-sortIcon field="Descricao"></p-sortIcon>
          </th>
          <th pSortableColumn="DataInicio">
            Data Início<p-sortIcon field="DataInicio"></p-sortIcon>
          </th>
          <th pSortableColumn="DataFinal">
            Data Final<p-sortIcon field="DataFinal"></p-sortIcon>
          </th>
          <th></th>
          <!-- <th></th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>
            <button
              pButton
              pRipple
              icon="pi pi-clone"
              pTooltip="Duplicar registro"
              class="p-button-sm p-button-rounded p-button-help p-mr-2"
              (click)="editDuplicar(data)"
            ></button>
          </td>
          <td>{{ data.Descricao }}</td>
          <td>
            {{
              data.DataInicio
                ? (data.DataInicio.toDate() | date : 'dd/MM/yyyy')
                : (data.DataInicio | date : 'dd/MM/yyyy')
            }}
          </td>
          <td>
            {{
              data.DataFinal
                ? (data.DataFinal.toDate() | date : 'dd/MM/yyyy')
                : (data.DataFinal | date : 'dd/MM/yyyy')
            }}
          </td>

          <td style="text-align: right">
            <button
              pButton
              pRipple
              icon="pi pi-pencil"
              pTooltip="Editar"
              class="p-button-sm p-button-rounded p-button-warning p-mr-2"
              (click)="editData(data)"
            ></button>
          </td>
          <!-- <td>
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                        (click)="deleteData(data)"></button>
                    </td> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
          No total, existem
          {{ datasFiltred() ? datasFiltred().length : 0 }} registro(s).
        </div>
      </ng-template>
    </p-table>
  </div>
</p-card>

<!-- **************************** CADASTRO ****************************-->
<p-dialog
  [(visible)]="dataDialog"
  [style]="{ width: '95%', 'max-height': '100%', 'max-width': '1320px' }"
  header="Detalhe Promoção"
  [modal]="true"
  styleClass="p-fluid"
  [closeOnEscape]="false"
  (onHide)="hideDialog()"
>
  <ng-template pTemplate="content">
    <p-card
      [style]="{ width: '100%', 'margin-top': '20px', 'margin-bottom': '20px' }"
    >
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center">
          <div *ngIf="data.Banner; else elseBlock">
            <a (click)="showImageDialog()">
              <img class="imagem-cad" [src]="data.Banner" style="width: 20%" />
            </a>
          </div>
          <ng-template #elseBlock>
            <div>
              <img src="assets/image.png" style="width: 150px" />
            </div>
          </ng-template>
        </div>

        <div
          class="p-col-12 p-md-12 p-lg-12"
          style="text-align: center; text-align: -webkit-center"
        >
          <label class="upload-arquivo" for="arquivo"
            >Incluir/Alterar Imagem</label
          >
          <input
            type="file"
            name="arquivo"
            id="arquivo"
            (change)="uploadPromocao($event)"
            pInputText
          />
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Código</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.PromocaoID"
            required
            [disabled]="true"
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-5">
          <label>Descrição</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.Descricao"
            required
            autofocus
          />
        </div>
        <div class="p-col-12 p-md-12 p-lg-5">
          <label>Descrição Parceiro</label>
          <input
            type="text"
            pInputText
            [(ngModel)]="data.DescricaoAplicativo"
            required
          />
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Data Início</label>
          <p-calendar
            [(ngModel)]="data.DataInicio"
            dateFormat="dd/mm/yy"
          ></p-calendar>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Data Fim</label>
          <p-calendar
            [(ngModel)]="data.DataFinal"
            dateFormat="dd/mm/yy"
          ></p-calendar>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Status</label><br />
          <p-checkbox [(ngModel)]="data.Ativo" binary="true" inputId="binary">
          </p-checkbox>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Destacar</label><br />
          <p-checkbox
            [(ngModel)]="data.Destacar"
            binary="true"
            inputId="binary"
          >
          </p-checkbox>
        </div>
        <div class="p-col-12 p-md-12 p-lg-2">
          <label>Destacar no Produto</label><br />
          <p-checkbox
            [(ngModel)]="data.DestacarNoProduto"
            binary="true"
            inputId="binary"
          >
          </p-checkbox>
        </div>
      </div>
      <br />

      <p-accordion [multiple]="true" [style]="{ width: '100%' }">
        <!-- PRODUTOS -->
        <p-accordionTab header="Produtos">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <!-- {{data.PromocoesProdutos | json}} -->
            <p-pickList
              [source]="Produtos"
              [target]="ListaPromocoesProdutos"
              (onMoveToTarget)="onMoveToTargetProdutos($event)"
              (onMoveAllToTarget)="onMoveAllToTargetProdutos($event)"
              (onMoveToSource)="onMoveToSourceProdutos($event)"
              (onMoveAllToSource)="onMoveAllToSourceProdutos($event)"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              [targetStyle]="{ height: '30rem' }"
              filterBy="ProdutoID,Descricao"
              filterMatchMode="contains"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      <span class="produto-inativo">{{
                        product.Ativo === 'N' ? '[INATIVO] - ' : ''
                      }}</span>
                      {{ product.ProdutoID }} - {{ product.Descricao }} -
                      {{ product.QuantidadeMinima | number : '1.2-2' }} -
                      {{ product.QuantidadeMaxima | number : '1.2-2' }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
            <div class="p-grid p-fluid">
              <div class="p-col-12 p-md-12 p-lg-2">
                <label>Mínimo</label>
                <p-inputNumber
                  [(ngModel)]="Minimo"
                  mode="decimal"
                  [useGrouping]="false"
                >
                </p-inputNumber>
              </div>
              <div class="p-col-12 p-md-12 p-lg-2">
                <label>Máximo</label>
                <p-inputNumber
                  [(ngModel)]="Maximo"
                  mode="decimal"
                  [useGrouping]="false"
                >
                </p-inputNumber>
              </div>
            </div>
            <br />
            <!-- Volumes x Percentuais Desconto -->
            <h2>Volumes x Percentuais Desconto</h2>
            <div class="p-grid p-fluid">
              <div class="p-col-12 p-md-12 p-lg-4">
                <label>Cxs / Pcts</label>
                <p-inputNumber
                  [(ngModel)]="dataDesconto.Quantidade"
                  inputId="Quantidade"
                  mode="decimal"
                  [useGrouping]="false"
                >
                </p-inputNumber>
              </div>
              <div class="p-col-12 p-md-12 p-lg-4">
                <label>% Desconto</label>
                <p-inputNumber
                  [(ngModel)]="dataDesconto.Percentual"
                  mode="decimal"
                  [minFractionDigits]="2"
                  [maxFractionDigits]="5"
                >
                </p-inputNumber>
              </div>
              <div class="p-col-12 p-md-12 p-lg-4" style="align-self: flex-end">
                <button
                  pButton
                  pRipple
                  label="Adicionar/Alterar"
                  icon="pi pi-plus"
                  class="p-button-sm p-button-success p-mr-2"
                  (click)="AdicionarDesconto()"
                  [disabled]="dataDesconto.Quantidade < 0"
                ></button>
              </div>
            </div>
            <p-table
              [value]="promocoesDescontos"
              selectionMode="single"
              [(selection)]="selectedPromocaoDescontoModels"
              dataKey="PromocaoDescontoID"
              styleClass="p-datatable-striped"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Cxs / Pcts</th>
                  <th>% Desconto</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-promo>
                <tr [pSelectableRow]="data">
                  <td>{{ promo.Quantidade | number : '1.2-2' }}</td>
                  <td>{{ promo.Percentual | number : '1.2-2' }}</td>
                  <td>
                    <button
                      pButton
                      pRipple
                      icon="pi pi-pencil"
                      pTooltip="Editar"
                      class="p-button-sm p-button-rounded p-button-warning p-mr-2"
                      (click)="EditarDesconto(promo)"
                    ></button>
                    <button
                      pButton
                      pRipple
                      icon="pi pi-trash"
                      pTooltip="Excluir"
                      class="p-button-sm p-button-rounded p-button-danger"
                      (click)="RemoverDesconto(promo)"
                    ></button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-accordionTab>

        <!-- Centro de Distribuição -->

        <p-accordionTab header="Centro de Distribuição">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <p-pickList
              [source]="Unidades"
              [target]="ListaPromocoesUnidades"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              [targetStyle]="{ height: '30rem' }"
              filterBy="UnidadeID,Descricao"
              filterMatchMode="contains"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      {{ product.UnidadeID }} - {{ product.Descricao }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-accordionTab>

        <!-- Cidades -->

        <p-accordionTab header="Cidades">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <p-pickList
              [source]="Cidades"
              [target]="ListaPromocoesCidades"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="true"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              [targetStyle]="{ height: '30rem' }"
              filterBy="Descricao,Estado"
              filterMatchMode="contains"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      {{ product.Descricao }}/{{ product.Estado }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-accordionTab>

        <!-- Supervisores -->

        <p-accordionTab header="Supervisores">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <p-pickList
              [source]="Supervisores"
              [target]="ListaPromocoesSupervisores"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              [targetStyle]="{ height: '30rem' }"
              filterBy="SupervisorID,Nome"
              filterMatchMode="contains"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      {{ product.SupervisorID }} - {{ product.Nome }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-accordionTab>

        <!-- Carteiras -->

        <p-accordionTab header="Carteiras">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <p-pickList
              [source]="Carteiras"
              [target]="ListaPromocoesCarteirasVendas"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              [targetStyle]="{ height: '30rem' }"
              filterBy="CarteiraVendaID,Descricao"
              filterMatchMode="contains"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      {{ product.CarteiraVendaID }} - {{ product.Descricao }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-accordionTab>

        <!-- Segmentos de Clientes -->

        <p-accordionTab header="Segmentos de Clientes">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <p-pickList
              [source]="Segmentos"
              [target]="ListaPromocoesSegmentos"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              filterMatchMode="contains"
              [targetStyle]="{ height: '30rem' }"
              filterBy="SegmentoID,Nome"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      {{ product.SegmentoID }} - {{ product.Nome }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-accordionTab>

        <!-- Papeis de Clientes-->

        <p-accordionTab header="Papeis de Clientes">
          <div
            class="card"
            style="background-color: rgb(247, 247, 247); width: 100%"
          >
            <p-pickList
              [source]="PapeisClientes"
              [target]="ListaPromocoesPapeisClientes"
              sourceHeader="Disponível"
              targetHeader="Selecionado"
              [dragdrop]="false"
              [responsive]="true"
              [sourceStyle]="{ height: '30rem' }"
              filterMatchMode="contains"
              [targetStyle]="{ height: '30rem' }"
              filterBy="PapelClienteID,Descricao"
              sourceFilterPlaceholder="Pesquisar"
              targetFilterPlaceholder="Pesquisar"
            >
              <ng-template let-product pTemplate="item">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="p-mb-2">
                      {{ product.PapelClienteID }} - {{ product.Descricao }}
                    </h5>
                  </div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-accordionTab>
      </p-accordion>
    </p-card>
  </ng-template>

  <ng-template pTemplate="footer">
    <br />
    <button
      pButton
      pRipple
      type="button"
      label="GRAVAR"
      [disabled]="
        !data.Descricao ||
        !data.DescricaoAplicativo ||
        !data.DataInicio ||
        !data.DataFinal
      "
      style="width: 100px; float: left"
      class="p-button-raised p-button-success"
      (click)="saveData()"
    ></button>

    <!--p-progressSpinner [style]="{'width':'20px','height':'20px','float':'left','margin-top':'5px'}"
            *ngIf="loadingSave" styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner-->

    <button
      pButton
      pRipple
      type="button"
      label="RETORNAR"
      style="width: 100px"
      class="p-button-raised p-button-danger"
      (click)="hideDialog()"
    ></button>

    <p-progressBar
      mode="indeterminate"
      [style]="{ height: '6px', 'margin-top': '5px' }"
      *ngIf="loadingSave"
    ></p-progressBar>
  </ng-template>

  <!-- <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-sm p-button-danger"
            (click)="hideDialog()"></button>
        <button pButton pRipple label="Gravar" icon="pi pi-check" class="p-button-sm p-button-success"
            [disabled]="!data.Descricao || !data.DescricaoAplicativo || !data.DataInicio || !data.DataFinal"
            (click)="saveData()"></button>
    </ng-template> -->
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<p-dialog header="Imagem" [(visible)]="displayImage" [baseZIndex]="10000">
  <img [src]="data.Banner" />
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="displayImage = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
