import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { ProdutoModel } from './produto.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import {
  GetProdutos,
  PostProdutoAlterar,
  PostProdutoComboAlterar,
} from 'src/app/services/api.service';

@Injectable()
export class ProdutoService {
  currentUser: string;
  TOKEN: string;

  private basePath = '/produtos';

  constructor(
    private http: HttpClient,
    private router: Router,
    public firestore: AngularFirestore,
    public fireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.TOKEN = JSON.parse(localStorage.getItem('tokenUser'));
  }

  private updateToken = () => {
    this.TOKEN = JSON.parse(localStorage.getItem('tokenUser'));
  };

  updateLocalstorage({
    token,
    currentUser,
  }: {
    token: string;
    currentUser: string;
  }) {
    this.currentUser = JSON.parse(currentUser);
    this.TOKEN = token;

    localStorage.setItem('tokenUser', JSON.stringify(token));
    localStorage.setItem('currentUser', currentUser);
  }
  /* Cria um novo registro na coleção especificada usando o método add */
  createProduto(record) {
    return this.firestore.collection('Produtos').add(record);
  }
  readProduto() {
    return this.firestore
      .collection<ProdutoModel>('Produtos')
      .snapshotChanges();
  }
  readSegmento() {
    return this.firestore
      .collection<SegmentoModel>('Segmentos')
      .snapshotChanges();
  }
  /*Atualiza o registro pegando o ID e chamando o método de atualização */
  updateProduto(recordID, record) {
    this.firestore.doc('Produtos/' + recordID).update(record);
  }

  async PostProdutoAlterar(dados: ProdutoModel): Promise<any> {
    let resultado: any = 'erro';
    let response: any;

    //Exemplo:
    // {
    //   "ProdutoID": "PA000032",
    //   "Descricao": "Nova Descricao",
    //   "SubCategoriaID" : "10"
    // }

    try {
      let data: any = {
        ProdutoID: dados.ProdutoID,
        Descricao: dados.Descricao,
        SubCategoriaID: Number(dados.SubCategoriaID),
      };
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          response = await this.http
            .post(`${PostProdutoAlterar}/${this.TOKEN}`, JSON.stringify(data))
            .toPromise();

          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      resultado = 'erro' + error;
    }
  }

  async PostProdutoComboAlterar(dados: ProdutoModel): Promise<any> {
    let resultado: any = 'erro';
    let response: any;
    try {
      let data: any = {
        ProdutoID: dados.ProdutoID,
        Descricao: dados.Descricao,
        Ativo: dados.Ativo,
        Preco: dados.Preco,
        SubCategoriaID: Number(dados.SubCategoriaID),
        ProdutosCombos: dados.ProdutosCombos,
      };
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          response = await this.http
            .post(
              `${PostProdutoComboAlterar}/${this.TOKEN}`,
              JSON.stringify(data)
            )
            .toPromise();

          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      resultado = 'erro' + error;
    }
  }

  async PostProdutoComboInserir(dados: ProdutoModel): Promise<any> {
    let resultado: any = 'erro';
    let response: any;
    //Exemplo:
    // {
    //     "ProdutoID": "CB000001",
    //     "Descricao":"Descricao do Combo",
    //     "Status":true,
    //     "Preco":100.00,
    //     "SubCategoriaID":10,
    //     "ProdutosCombos":[
    //         {"ProdutoComboID":"CB000001",
    //         "ProdutoID":"PA000007",
    //         "Quantidade": 100,
    //         "ValorUnitario": 25.70,
    //         "ValorTotal":2570.00
    //     },...
    // ]}
    try {
      let data: any = {
        Ativo: dados.Ativo,
        Descricao: dados.Descricao,
        Preco: dados.Preco,
        SubCategoriaID: Number(dados.SubCategoriaID),
        ProdutosCombos: dados.ProdutosCombos,
      };
      this.updateToken();
      if (this.TOKEN != null) {
        if (this.TOKEN != null || this.TOKEN != undefined) {
          response = await this.http
            .post(
              `${PostProdutoComboAlterar}/${this.TOKEN}`,
              JSON.stringify(data)
            )
            .toPromise();

          if (response.sucesso == true) {
            resultado = response.sucesso;
          }
        }
      }
      return resultado;
    } catch (error) {
      resultado = 'erro' + error;
    }
  }

  /*Chama o método de exclusão  ao registrar o ID*/
  deleteProduto(record_id) {
    this.firestore.doc('Produtos/' + record_id).delete();
  }

  deleteAllProduto() {
    // this.firestore.doc('Produtos').delete();
  }

  getFiles(): AngularFireList<FileUpload> {
    console.log(this.db.list(this.basePath));
    return this.db.list(this.basePath);
  }

  atualizaProduto() {
    this.updateToken();

    return this.http.get(`${GetProdutos}/${this.TOKEN}`);
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
