<p-toast></p-toast>
<p-card>
    <div class="card">
        <!-- **************************** LISTA **************************** -->
        <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="loading"></p-progressBar>

        <p-table #dt [value]="datas" [(selection)]="data" [rowHover]="true" dataKey="id">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-ai-center p-jc-between">
                    <h5 class="p-m-0">Gerenciamento de Configurações do APP</h5>
                </div>
                <br>
                <div class="p-fluid p-grid p-formgrid">
                    <div class="p-field p-col-12 p-md-4">
                        <label for="icon">Descrição</label>
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                    <col style="width:400px">
                    <col style="width:400px">
                    <col>
                    <col style="width:130px">
                    <col style="width:130px">
                    <col style="width:100px">
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Cor Atual do APP</th>
                    <th>Cor para os Botões</th>
                    <th>Manutenção</th>
                    <th>Usando Cor Selecionada</th>
                    <th>Whatsapp</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data>
                <tr>
                    <td>
                        <div style="display: flex; gap: 8px;align-items: center;">
                            <div [style.width.px]="24" [style.height.px]="24"
                                style="border-radius: 100px;border: 1px solid black;"
                                [style.background]="data.CorDoApp.hex">
                            </div>
                            {{"Hex: "+data.CorDoApp.hex +" / RGB: "+data.CorDoApp.rgb}}
                        </div>
                    </td>
                    <td>
                        <div style="display: flex; gap: 8px;align-items: center;">
                            <div [style.width.px]="24" [style.height.px]="24"
                                style="border-radius: 100px;border: 1px solid black;"
                                [style.background]="data.CorDoBotaoDoApp.hex">
                            </div>
                            {{"Hex: "+data.CorDoBotaoDoApp.hex +" / RGB: "+data.CorDoBotaoDoApp.rgb}}
                        </div>
                    </td>
                    <td>{{data.manutencao?"Sim":"Não"}}</td>
                    <td>{{data.UsarCorSelecionada?"Sim":"Não"}}</td>
                    <td>{{data.whatsapp}}</td>

                    <td style="text-align: right;">
                        <button pButton pRipple icon="pi pi-pencil" pTooltip="Editar"
                            class="p-button-sm p-button-rounded p-button-warning p-mr-2"
                            (click)="editData(data)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>

<!-- **************************** CADASTRO ****************************-->
<p-dialog [(visible)]="dataDialog" [style]="{'width':'95%','max-height':'100%', 'max-width':'1320px' }"
    header="Detalhe Configurações do APP" [modal]="true" styleClass="p-fluid" [closeOnEscape]="false"
    (onHide)="hideDialog()">
    <ng-template pTemplate="content">
        <p-card [style]="{'width': '100%', 'margin-top': '20px', 'margin-bottom': '20px'}">
            <div [style]="{'width': '100%', 'max-width':'600px'}">
                <div class="p-col-12 p-md-12 p-lg-10">
                    <div>
                        <div style="display: flex; gap: 8px; ">
                            <label for="corInput">Escolha uma cor para o APP:</label>
                            <button (click)="escolherCorPadrao(data)"
                                style="border: 1px #00A13A solid; cursor: pointer;">Usar
                                Cor Padrão do
                                APP</button>
                        </div>
                        <br />
                        <input type="color" [(ngModel)]="data.CorDoApp.hex" style="height: 50px;" required>
                    </div>
                    <div>
                        <label for="corInput">Escolha uma cor para os Botões do APP:</label><br />
                        <input type="color" [(ngModel)]="data.CorDoBotaoDoApp.hex" style="height: 50px;" required>
                    </div>
                    <div>
                        <label>Whatsapp</label>
                        <input type="text" pInputText [(ngModel)]="data.whatsapp" required />
                    </div>
                </div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-4">
                        <label>Manutenção</label><br />
                        <p-checkbox [(ngModel)]="data.manutencao" binary="true" inputId="binary">
                        </p-checkbox>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-4">
                        <label>Usar Cor Selecionada</label><br />
                        <p-checkbox [(ngModel)]="data.UsarCorSelecionada" binary="true" inputId="binary">
                        </p-checkbox>
                    </div>
                </div>

            </div>
        </p-card>
    </ng-template>

    <ng-template pTemplate="footer">
        <br />
        <button pButton pRipple type="button" label="GRAVAR"
            [disabled]="!data.CorDoApp.hex || !data.CorDoApp.rgb || !data.CorDoBotaoDoApp.hex || !data.CorDoBotaoDoApp.rgb || !data.whatsapp"
            style="width: 100px;float: left;" class="p-button-raised p-button-success" (click)="saveData()">
        </button>


        <button pButton pRipple type="button" label="RETORNAR" style="width: 100px;"
            class="p-button-raised p-button-danger" (click)="hideDialog()">
        </button>

        <p-progressBar mode="indeterminate" [style]="{'height': '6px', 'margin-top': '5px'}"
            *ngIf="loadingSave"></p-progressBar>
    </ng-template>

</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>