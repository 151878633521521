import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { CarteiraModel } from 'src/app/models/carteira.model';
import { CidadeModel } from 'src/app/models/cidade.model';
import { PapeisClienteModel } from 'src/app/models/papeiscliente.model';
import { SegmentoModel } from 'src/app/models/segmento.model';
import { SupervisorModel } from 'src/app/models/supervisor.model';
import { UnidadeModel } from 'src/app/models/unidade.model';
import { FirebaseService } from '../app.login.firebase.service';
import { ParceiroModel } from '../parceiro/parceiro.model';
import { PromocaoCarteiraVendasModel } from '../promocao/promocao.carteiravendas.model';
import { PromocaoCidadesModel } from '../promocao/promocao.cidades.model';
import { ParceiroPromocaoModel } from '../promocao/promocao.model';
import { PromocaoPapeisClienteModel } from '../promocao/promocao.papeiscliente.model';
import { PromocaoSegmentoModel } from '../promocao/promocao.segmento.model';
import { ParceiroPromocaoService } from '../promocao/promocao.service';
import { PromocaoSupervisorModel } from '../promocao/promocao.supervisor.model';
import { PromocaoUnidadeModel } from '../promocao/promocao.unidade.model';
import { NotificacaoDadosModel } from './notificacao.dados.model';
import { NotificacaoModel } from './notificacao.model';
import { NotificacaoService } from './notificacao.service';
import { TipoNotificacaoModel } from './tipo.notificacao.model';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss'],
})
export class NotificacaoComponent implements OnInit {
  show = false;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  dataDialog: boolean;
  dataDialogSubCategoria: boolean;

  tipoNotificacao: TipoNotificacaoModel[];
  Promocoes: ParceiroPromocaoModel[];
  datas: any;
  lista: NotificacaoModel[];
  loading: boolean = true;
  data: NotificacaoModel;
  selectedDatas: NotificacaoModel[];
  submitted: boolean;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadState: Observable<string>;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;

  private basePath = 'notificacao/';

  profileUrl: any;

  Parceiros: ParceiroModel[];
  Unidades: UnidadeModel[];
  Cidades: CidadeModel[];
  Supervisores: SupervisorModel[];
  Carteiras: CarteiraModel[];
  Segmentos: SegmentoModel[];
  PapeisClientes: PapeisClienteModel[];

  // valores selecionados na telinha de criar nova notificação
  ListaParceirosNegocios: ParceiroModel[];
  ListaUnidades: UnidadeModel[];
  ListaCidades: CidadeModel[];
  ListaSupervisores: SupervisorModel[];
  ListaCarteirasVendas: CarteiraModel[];
  ListaSegmentos: SegmentoModel[];
  ListaPapeisClientes: PapeisClienteModel[];
  // valores selecionados na telinha de criar nova notificação

  pageNumber: number = 1;
  pageSize: number = 100;
  paginatedParceiros: ParceiroModel[];

  constructor(
    private service: NotificacaoService,
    private servicePromocao: ParceiroPromocaoService,
    private messageService: MessageService,
    public firebaseService: FirebaseService,
    private confirmationService: ConfirmationService,
    public auth: AngularFireAuth,
    public firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) {
    this.carregarListas();
    this.carregandoTodasInfos();
  }

  ngOnDestroy() {}

  async ngOnInit() {}

  uploadedFiles: File = null;
  fb: any;

  carregandoTodasInfos() {
    this.ListaParceirosNegocios = [];
    this.ListaUnidades = [];
    this.ListaCidades = [];
    this.ListaSupervisores = [];
    this.ListaCarteirasVendas = [];
    this.ListaSegmentos = [];
    this.ListaPapeisClientes = [];

    try {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          this.service.read_Notificacao().subscribe((data) => {
            this.datas = data.map((e) => {
              const dataValue = e.payload.doc.data();

              return {
                id: e.payload.doc.id,
                isEdit: false,
                NotificacaoID: e.payload.doc.id,
                Mensagem: dataValue['Mensagem'],
                Titulo: dataValue['Titulo'],
                Icone: dataValue['Icone'],
                DataHora: dataValue['DataHora'],
                DataHoraEnvio: dataValue['DataHoraEnvio'],
                FirebaseID: dataValue['FirebaseID'],
                Dados: dataValue['Dados'],
              };
            });
          });

          //BUSCANDO TIPO NOTIFICACAO
          try {
            this.service.read_TipoNotificacao().subscribe(
              (data) => {
                this.tipoNotificacao = data.map((e) => {
                  const { Nome } = e.payload.doc.data();
                  return { id: e.payload.doc.id, Nome };
                });
              },
              (err) => console.error(err)
            );
          } catch (e) {
            alert(e);
          }
          //BUSCANDO TIPO NOTIFICACAO

          //BUSCANDO PROMOCAO
          try {
            this.servicePromocao.readParceiroPromocao().subscribe((data) => {
              this.Promocoes = data.map((e) => {
                const dataValue = e.payload.doc.data();
                return {
                  id: e.payload.doc.id,
                  isEdit: false,
                  Descricao: dataValue['Descricao'],
                  PromocaoID: dataValue['PromocaoID'],
                };
              });
            });
          } catch (e) {
            alert(e);
          }
          //BUSCANDO PROMOCAO
        } else {
          this.datas = new Observable<any[]>();
        }
        this.loading = false;
      });
    } catch (error) {
      this.loading = false;
      alert(error);
    }
  }

  carregarListas() {
    this.ListaParceirosNegocios = [];
    this.ListaUnidades = [];
    this.ListaCidades = [];
    this.ListaSupervisores = [];
    this.ListaCarteirasVendas = [];
    this.ListaSegmentos = [];
    this.ListaPapeisClientes = [];

    this.Parceiros = [];
    this.Unidades = [];
    this.Cidades = [];
    this.Supervisores = [];
    this.Carteiras = [];
    this.Segmentos = [];
    this.PapeisClientes = [];

    //PARCEIRO
    this.service.readParceiroNegocios().subscribe((data) => {
      this.Parceiros = data.map((e) => {
        const { RazaoSocial, ParceiroNegocioID } = e.payload.doc.data();
        return { id: e.payload.doc.id, ParceiroNegocioID, RazaoSocial };
      });
      this.paginatedParceiros = this.Parceiros.slice(
        this.pageNumber * this.pageSize - 100,
        this.pageNumber * this.pageSize
      );
    });
    // FIM PARCEIRO

    //UNIDADES
    this.service.readUnidadesPromocao().subscribe((data) => {
      this.Unidades = data.map((e) => {
        const { UnidadeID, Descricao } = e.payload.doc.data();
        return { id: e.payload.doc.id, UnidadeID, Descricao };
      });
    });
    // FIM UNIDADES

    //CIDADES
    this.service.readCidadesPromocao().subscribe((data) => {
      this.Cidades = data.map((e) => {
        const { CidadeID, Descricao, Estado } = e.payload.doc.data();
        return { id: e.payload.doc.id, CidadeID, Descricao, Estado };
      });
    });
    // FIM CIDADES

    //SUPERVISORES
    this.service.readSupervisorPromocao().subscribe((data) => {
      this.Supervisores = data.map((e) => {
        const { SupervisorID, Nome } = e.payload.doc.data();

        return { id: e.payload.doc.id, SupervisorID, Nome };
      });
    });
    // FIM SUPERVISORES

    //CARTEIRA
    this.service.readCarteiraPromocao().subscribe((data) => {
      this.Carteiras = data.map((e) => {
        const dataValues = e.payload.doc.data();
        return { id: e.payload.doc.id, ...dataValues };
      });
    });
    // FIM CARTEIRA

    //SEGMENTOS
    this.service.readSegmentosPromocao().subscribe((data) => {
      this.Segmentos = data.map((e) => {
        const { SegmentoID, Nome } = e.payload.doc.data();
        return { id: e.payload.doc.id, SegmentoID, Nome };
      });
    });
    // FIM SEGMENTOS

    //PAPEIS CLIENTES
    this.service.readPapeisClientesPromocao().subscribe((data) => {
      this.PapeisClientes = data.map((e) => {
        const { PapelClienteID, Descricao, Ativo } = e.payload.doc.data();
        return { id: e.payload.doc.id, PapelClienteID, Descricao, Ativo };
      });
    });
    // FIM PAPEIS CLIENTES
  }

  uploadFoto(event) {
    this.uploadedFiles = <File>event.target.files[0];

    try {
      if (this.uploadedFiles != null) {
        const filePath = `${this.basePath}/${this.data.NotificacaoID}.png`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, this.uploadedFiles);

        uploadTask
          .snapshotChanges()
          .pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe((downloadURL) => {
                this.fb = downloadURL;
                this.data.Icone = downloadURL;
                this.messageService.add({
                  severity: 'success',
                  summary: 'Informação',
                  detail: 'Foto alterada com sucesso!',
                });
                //this.saveFileData(this.fb);
              });
            })
          )
          .subscribe();
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Erro em alterar foto!',
      });
      alert(error);
    }
  }

  async getImage(codigo: string): Promise<string> {
    let resultado: Promise<string>;
    try {
      await this.storage
        .ref(this.basePath + codigo + '.png')
        .getDownloadURL()
        .toPromise()
        .then((response: any) => {
          resultado = response;
          return resultado;
        })
        .catch((error) => error);

      return resultado;
    } catch (error) {}
  }

  prevPage() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.paginatedParceiros = this.Parceiros.slice(
        this.pageNumber * this.pageSize - 100,
        this.pageNumber * this.pageSize
      );
    }
  }

  nextPage() {
    if (this.pageNumber < Math.ceil(this.Parceiros.length / this.pageSize)) {
      this.pageNumber++;
      this.paginatedParceiros = this.Parceiros.slice(
        this.pageNumber * this.pageSize - 100,
        this.pageNumber * this.pageSize
      );
    }
  }

  openNew() {
    this.pageNumber = 1;
    this.pageSize = 100;

    this.data = {};
    // this.carregarListas();
    this.submitted = false;
    this.dataDialog = true;
  }

  async editData(data: NotificacaoModel) {
    data.isEdit = true;
    data.Icone = await this.getImage(data.NotificacaoID);
    this.data = { ...data };
    this.dataDialog = true;
  }

  deleteData(data: NotificacaoModel) {
    this.confirmationService.confirm({
      message: 'Tem certeza de que deseja excluir ' + data.Titulo + '?',
      header: 'Informação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
      accept: () => {
        this.service.delete_Notificacao(data.NotificacaoID);
        this.data = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Informação',
          detail: 'Registro excluído com sucesso!',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.dataDialog = false;
    this.submitted = false;
    this.data = {};
    // this.carregarListas();
  }

  FormataString(data) {
    if (
      data == null ||
      data == undefined ||
      data == 'null' ||
      data == 'undefined'
    ) {
      return '';
    }

    return data;
  }

  loadingSave: boolean = false;
  async saveData() {
    try {
      this.submitted = true;
      this.loadingSave = true;

      let arrayUnidades: number[];
      arrayUnidades = [];
      let arrayCidades: number[];
      arrayCidades = [];
      let arraySupervisores: string[];
      arraySupervisores = [];
      let arrayCarteiras: number[];
      arrayCarteiras = [];
      let arraySegmentos: string[];
      arraySegmentos = [];
      let arrayPapeisClientes: string[];
      arrayPapeisClientes = [];
      let arrayParceiroNegocios: string[];
      arrayParceiroNegocios = [];

      let data = {};

      if (this.data.NotificacaoID != undefined) {
        data['NotificacaoID'] = this.data.NotificacaoID;
        data['Mensagem'] = this.data.Mensagem;
        data['Icone'] = this.data.Icone;
        data['Titulo'] = this.data.Titulo;

        this.service.update_Notificacao(this.data.NotificacaoID, data);
        this.data.isEdit = false;
        this.dataDialog = false;
      } else {
        data['Mensagem'] = this.FormataString(this.data.Mensagem);
        data['Icone'] = this.FormataString(this.data.Icone);
        data['Titulo'] = this.FormataString(this.data.Titulo);

        //#region  LISTA ALTERAÇÃO

        //UNIDADES
        if (this.ListaUnidades.length > 0) {
          for (let index = 0; index < this.ListaUnidades.length; index++) {
            const element = this.ListaUnidades[index];
            if (element != null) {
              // let dt: UnidadeModel = {
              //   UnidadeID: element.UnidadeID
              // }
              arrayUnidades.push(element.UnidadeID);
            }
          }
        }
        //FIM LISTA UNIDADES

        //LISTA CIDADES
        if (this.ListaCidades.length > 0) {
          for (let index = 0; index < this.ListaCidades.length; index++) {
            const element = this.ListaCidades[index];
            if (element != null) {
              // let dt: CidadeModel = {
              //   CidadeID: element.CidadeID,
              // }
              arrayCidades.push(element.CidadeID);
            }
          }
        }
        //FIM LISTA CIDADES

        //LISTA SUPERVISOR
        if (this.ListaSupervisores.length > 0) {
          for (let index = 0; index < this.ListaSupervisores.length; index++) {
            const element = this.ListaSupervisores[index];
            if (element != null) {
              // let dt: SupervisorModel = {
              //   SupervisorID: element.SupervisorID,
              // }
              arraySupervisores.push(String(element.SupervisorID));
            }
          }
        }
        //FIM LISTA SUPERVISOR

        //LISTA CARTEIRAS
        if (this.ListaCarteirasVendas.length > 0) {
          for (
            let index = 0;
            index < this.ListaCarteirasVendas.length;
            index++
          ) {
            const element = this.ListaCarteirasVendas[index];
            if (element != null) {
              // let dt: CarteiraModel = {
              //   CarteiraVendaID: ,
              // }
              arrayCarteiras.push(element.CarteiraVendaID);
            }
          }
        }
        //FIM  LISTA CARTEIRAS

        //LISTA SEGMENTOS
        if (this.ListaSegmentos.length > 0) {
          for (let index = 0; index < this.ListaSegmentos.length; index++) {
            const element = this.ListaSegmentos[index];
            if (element != null) {
              // let dt: SegmentoModel = {
              //   SegmentoID: ,
              // }
              arraySegmentos.push(String(element.SegmentoID));
            }
          }
        }
        //FIM LISTA SEGMENTOS

        //LISTA PAPEIS CLIENTE
        if (this.ListaPapeisClientes.length > 0) {
          for (
            let index = 0;
            index < this.ListaPapeisClientes.length;
            index++
          ) {
            const element = this.ListaPapeisClientes[index];
            if (element != null) {
              // let dt: PapeisClienteModel = {
              //   PapelClienteID: element.PapelClienteID,
              // }
              arrayPapeisClientes.push(String(element.PapelClienteID));
            }
          }
        }
        //FIM LISTA PAPEIS CLIENTE

        //LISTA PARCEIRO
        if (this.ListaParceirosNegocios.length > 0) {
          for (
            let index = 0;
            index < this.ListaParceirosNegocios.length;
            index++
          ) {
            const element = this.ListaParceirosNegocios[index];
            if (element != null) {
              arrayParceiroNegocios.push(element.ParceiroNegocioID);
            }
          }
        }
        //FIM LISTA PARCEIRO
        //#endregion

        let noti: NotificacaoModel;
        noti = {};
        noti.Titulo = this.data.Titulo;
        noti.Icone = this.data.Icone;
        noti.Mensagem = this.data.Mensagem;
        if (this.FormataString(this.data.Tipo) != '') {
          if (this.FormataString(this.data.Tipo) == 'PROMOCAO') {
            noti.Dados = this.data.Tipo + '|' + this.data.Promocao;
          } else {
            noti.Dados = this.data.Tipo;
          }
        } else {
          noti.Dados = '';
        }

        noti.ParceirosNegociosString = arrayParceiroNegocios;
        noti.CidadesString = arrayCidades;
        noti.CarteirasVendasString = arrayCarteiras;
        noti.PapeisClientesString = arrayPapeisClientes;
        noti.SegmentosString = arraySegmentos;
        noti.SupervisoresString = arraySupervisores;
        noti.UnidadesString = arrayUnidades;

        let result = await this.service.PostNotificacaoEnviar(noti);

        if (result == true) {
          this.data.Mensagem = '';
          this.data.Icone = '';
          this.data.Titulo = '';
          this.data.Promocao = '';
          this.data.PromocaoID = '';
          this.data.Tipo = '';

          this.dataDialog = false;
          this.loadingSave = false;
          this.data = {};
          // this.carregarListas();
          this.messageService.add({
            severity: 'success',
            summary: 'Informação',
            detail: 'Notificação enviada com sucesso!',
            life: 3000,
          });
        } else {
          this.loadingSave = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Informação',
            detail:
              'Não foi possível enviar a notificação! Verifique os filtros selecionados.\n' +
              result,
          });
        }
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Informação',
        detail: 'Não foi possível enviar a notificação!\n' + error,
      });
    }
  }

  disabledPromocao: boolean = true;
  onChangeTipoNotificacao(event) {
    try {
      if (event != null || event != undefined) {
        if (event == 'PROMOCAO') {
          this.disabledPromocao = false;
        } else {
          this.data.Promocao = '';
          this.data.PromocaoID = '';
          this.disabledPromocao = true;
        }
      } else {
        this.data.Promocao = '';
        this.data.PromocaoID = '';
        this.disabledPromocao = true;
      }
    } catch (error) {}
  }

  onChangePromocao(event) {
    try {
    } catch (error) {}
  }
}
